import { parse, stringify } from 'query-string';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import Loader from '../../../common/components/Loader';
import ReadMore from '../../../common/components/ReadMore';
import {
  getParentCenterStudiesDataAction, getParentCenterStudiesDataMoreAction, getStudiesDataAction, getStudiesDataMoreAction,
} from '../InvestigatorStudies/logic';

export default function ShowStudiesData({
  clickedData, filterOn, dataset, dateFilter,
}) {
  const dispatch = useDispatch();
  const studiesData = useSelector((store) => store.studiesData);
  const getParentCenterStudiesData = useSelector((store) => store.getParentCenterStudiesData)
  const [studiesList, setStudiesList] = useState([]);
  const [from, setFrom] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (filterOn === 'phase') {
      dispatch(getStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        filters: JSON.stringify({
          phase: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'indication') {
      dispatch(getStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        filters: JSON.stringify({
          indication: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'sponsor') {
      dispatch(getStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        filters: JSON.stringify({
          sponsor: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'age_range') {
      dispatch(getStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        filters: JSON.stringify({
          [clickedData.category]: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'status') {
      dispatch(getStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        filters: JSON.stringify({
          status: [clickedData.key],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'parentPhase') {
      dispatch(getParentCenterStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          phase: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'parentIndication') {
      dispatch(getParentCenterStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          indication: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'parentAgeRange') {
      dispatch(getParentCenterStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          age_range: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'parentSponsor') {
      dispatch(getParentCenterStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          sponsor: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'parentStudiesTA') {
      dispatch(getParentCenterStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          therapeutic_area: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'parentStatus') {
      dispatch(getParentCenterStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          status: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'ParentLast2Years') {
      const timeNow = new Date();
      dispatch(getParentCenterStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          status: [clickedData.category],
          date: [Math.floor(timeNow.setFullYear(timeNow.getFullYear() - 2) / 1000), Math.floor(Date.now() / 1000)],
        }),
      }))
    }
    if (filterOn === 'ParentLast5Years') {
      const timeNow = new Date();
      dispatch(getParentCenterStudiesDataAction({
        id: parse(location.search).id,
        dataset,
        from: 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          status: [clickedData.category],
          date: [Math.floor(timeNow.setFullYear(timeNow.getFullYear() - 5) / 1000), Math.floor(Date.now() / 1000)],
        }),
      }))
    }
  }, [])

  useEffect(() => {
    if (studiesData.flag) {
      setStudiesList(studiesData.data.data);
      setTotal(studiesData.data.total);
    }
    if (getParentCenterStudiesData.flag) {
      setStudiesList(getParentCenterStudiesData.data.data);
      setTotal(getParentCenterStudiesData.data.total);
    }
  }, [JSON.stringify(studiesData), JSON.stringify(getParentCenterStudiesData)])

  const renderStudiesData = () => {
    return (
      <div>
        <Loader loading={studiesData.loading || getParentCenterStudiesData.loading} error={studiesData.error || getParentCenterStudiesData.error}>
          {studiesList.map((studyItm, index) => (
            <div className="study-card-wrap" key={index}>
              <div className="card">
                <div className="card-header card-header-notitle card-header-investigator">
                  <div className="card-meta">
                    <div className="card-meta-footer">
                      <div className="source">
                        <a
                          target="_blank"
                          href={`/search/ctdetails?${stringify({ ...parse(location.search), id: studyItm.iid, cid: studyItm.clinical_ids })}`}
                          rel="noopener noreferrer"
                          className="card-ct-link"
                          aria-label={studyItm.title}
                        >
                          <div className="source-img" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-title">{studyItm.title}</div>
                <div className="card-content">
                  <div className="card-content-data">
                    <div className="title">Status:</div>
                    <div className="desc">{studyItm.trial_status}</div>
                  </div>
                  <div className="card-content-data">
                    <div className="title">Study start date:</div>
                    <div className="desc">{studyItm.date}</div>
                  </div>
                  <div className="card-content-data">
                    <div className="title">Phase:</div>
                    <div className="desc">{studyItm.phase}</div>
                  </div>
                  <div className="card-content-data">
                    <div className="title">Trial ID:</div>
                    <div className="desc">{studyItm.trial_id}</div>
                  </div>
                  <div className="card-content-data">
                    <div className="title">Clinical ID:</div>
                    <div className="desc">{studyItm.clinical_ids.join(', ')}</div>
                  </div>
                  <div className="card-content-data">
                    <div className="title">LandSCAPE ID:</div>
                    <div className="desc">{studyItm.landscape_id}</div>
                  </div>
                </div>
                <div className="card-content">
                  <div className="card-content-data">
                    <div className="title">Intervention:</div>
                    <div className="desc">{studyItm.interventions && studyItm.interventions.length ? studyItm.interventions.join('; ') : '-'}</div>
                  </div>
                  <div className="card-content-data">
                    <div className="title">Indication:</div>
                    <div className="desc">{studyItm.indications && studyItm.indications.length ? studyItm.indications.join('; ') : '-'}</div>
                  </div>
                </div>
                <div className="card-content-data">
                  <div className="title">Sources:</div>
                  <div className="desc">{studyItm.sources ? studyItm.sources.join('; ') : '-'}</div>
                </div>
                <div className="card-content">
                  <div className="card-content-data">
                    <div className="title">Centers:</div>
                    <div className="desc">
                      <ReadMore type="text" limit={100}>
                        {studyItm.related_centers && studyItm.related_centers.length ? studyItm.related_centers.join('; ') : '-'}
                      </ReadMore>
                    </div>
                  </div>
                  <div className="card-content-data">
                    <div className="title">Sponsor:</div>
                    <div className="desc">{studyItm.sponsors && studyItm.sponsors.length ? studyItm.sponsors.join('; ') : '-'}</div>
                  </div>
                </div>

              </div>
            </div>
          ))}
        </Loader>
      </div>
    )
  }

  const loadMoreRows = () => {
    if (filterOn === 'phase') {
      dispatch(getStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        filters: JSON.stringify({
          phase: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'indication') {
      dispatch(getStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        filters: JSON.stringify({
          indication: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'sponsor') {
      dispatch(getStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        filters: JSON.stringify({
          sponsor: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'age_range') {
      dispatch(getStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        filters: JSON.stringify({
          [clickedData.category]: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'status') {
      dispatch(getStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        filters: JSON.stringify({
          status: [clickedData.key],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'parentPhase') {
      dispatch(getParentCenterStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          phase: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'parentIndication') {
      dispatch(getParentCenterStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          indication: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'parentAgeRange') {
      dispatch(getParentCenterStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          [clickedData.category]: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'parentSponsor') {
      dispatch(getParentCenterStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          sponsor: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'parentStudiesTA') {
      dispatch(getParentCenterStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          therapeutic_area: [clickedData.category],
          date: dateFilter,
        }),
      }))
    }
    if (filterOn === 'ParentLast2Years') {
      const timeNow = new Date();
      dispatch(getParentCenterStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          status: [clickedData.category],
          date: [Math.floor(timeNow.setFullYear(timeNow.getFullYear() - 2) / 1000), Math.floor(Date.now() / 1000)],
        }),
      }))
    }
    if (filterOn === 'ParentLast5Years') {
      const timeNow = new Date();
      dispatch(getParentCenterStudiesDataMoreAction({
        id: parse(location.search).id,
        dataset,
        from: from + 1,
        size: 10,
        study_for: 'Parent_view',
        filters: JSON.stringify({
          status: [clickedData.category],
          date: [Math.floor(timeNow.setFullYear(timeNow.getFullYear() - 5) / 1000), Math.floor(Date.now() / 1000)],
        }),
      }))
    }
    setFrom((f) => f + 1);
  }

  return (
    <>
      <div>
        {(studiesData.flag || getParentCenterStudiesData.flag) && studiesList.length
          ? (
            <div className="show-doc-outof show-doc-outof-w-relative" style={{ marginBottom: '10px' }}>
              <div className="show-doc-data">
                Showing<span className="show-count">{studiesList.length}</span> results out of
                <span className="show-count-number">{studiesData.data.total || getParentCenterStudiesData.data.total}</span>studies
              </div>
            </div>
          )
          : null}
      </div>
      <LazyLoadDiv
        className="card-list scrollbar"
        id="industry_list"
        total={total}
        currentTotal={(studiesList || []).length}
        loadMoreRows={() => loadMoreRows()}
        height="calc(100vh - 380px)"
      >
        <div>
          {renderStudiesData()}
        </div>
      </LazyLoadDiv>
    </>
  )
}
