import {
  Button, Checkbox, Dropdown, Menu, Modal, Popover, Rate, Select, Tooltip,
} from 'antd';
import Axios from 'axios';
import { parse, stringify } from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { getCookie, getFormattedDate, isSponsor } from '../../../utils';
import PieChart from '../../../common/components/PieChart';
import {
  getPreferredSiteResetAction,
  getSavedProjectAction,
  getSavedProjectCountsAction,
  getSavedProjectMoreAction,
  updatePiForAllAction,
  updatePiStatusAction,
  updatePiStatusResetAction,
  createNewVersionAction,
  createNewVersionResetAction,
} from './logic';
import SortBy from '../RunAnalysis/SortBy';

import Comment from '../Comment';
import Loader from '../../../common/components/Loader';
import ColumnFilters from '../RunAnalysis/columnFilters';
// import RunAnalysisMap from '../../../common/components/RunAnalysisMap';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import ReadMore from '../../../common/components/ReadMore';
import ProjectFilters from '../../components/ProjectFilters';
import StudyModal from '../StudyModal';
import ScoreModal from '../ScoreModal';
import OrgChangeModal from '../OrgChangeModal';
import UploadSponsorsList from '../UploadSponsorsList';
import SearchBar from '../SearchBar';
import { createCommentResetAction } from '../Comment/logic';
import ProjectSummaryView from '../../components/ProjectSummaryView';
import MapViewSavedProjectResults from '../MapVIewSavedProjectResults';
import CountryQuestionModal from '../CountryQuestionModal';

// const emerging_data = {
//   therapeutic_area: 'oncology',
//   flag: 'Medium',
//   score: '22.22',
// };

// const emerging_kol = [
//   {
//     therapeutic_area: 'oncology',
//     flag: 'Medium',
//     score: '22.22',
//   },
//   {
//     therapeutic_area: 'oncology',
//     flag: 'Medium',
//     score: '22.22',
//   },
// ];

const SavedProjectResults = ({ ...props }) => {
  const dispatch = useDispatch();
  const SELECT_ALL_LIMIT = 1000;
  const adminType = getCookie('permissions');
  const originatingParty = [
    {
      label: 'landSCAPE',
      value: 'landSCAPE',
    },
    {
      label: 'CFL',
      value: 'CFL',
    },
    {
      label: 'LCOR',
      value: 'LCOR',
    },
    {
      label: 'sCRO',
      value: 'sCRO',
    },
    {
      label: 'Sponsor',
      value: 'Sponsor',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ];
  const { Option } = Select;
  const projectID = parse(location.search).project_id;
  const history = useHistory();
  const projectkeys = {
    'Feasibility Status': true,
    'Feasibility Status Date': false,
    'LCOR Status': true,
    'Sponsor Status': true,
    'LCOR Status Date': false,
    'Analysis ID': false,
    Priority: true,
    Origin: true,
    'Last Trial Start Date': true,
    'LCOR Comment': true,
    'Originating Party': true,
    Version: true,
    'Date Added': false,
    'Alliance Member Name': true,
    'Alliance Contact': true,
    'Parexel Contact': true,
    'CDA Expiry': false,
    'Query ID': false,
    '# Industry Studies': true,
    'Competing Studies': true,
    '# PXL Studies': false,
    'Tier (Search Specific)': true,
    'Profile Tier(General)': false,
    Specialities: false,
    'VAT Invoice Present': false,
    'Enrolled Subjects': false,
    Quality: false,
    Reliability: false,
    'Reliability Score': false,
    'KOL Score': false,
    'Start Up Months': true,
    'Start Up Index': false,
    'Recruitment Factor': true,
    Center: false,
    City: false,
    State: false,
    Country: false,
    Networks: false,
    'Alliance Type': true,
  };
  const lcorActions = [
    {
      value: 'Action Not Taken',
      label: 'Action Not Taken',
    },
    {
      value: 'Highly Recommended',
      label: 'Highly Recommended',
    },
    {
      value: 'Recommended',
      label: 'Recommended',
    },
    {
      value: 'Maybe with concern',
      label: 'Maybe with concern',
    },
    {
      value: 'Backup Recommended',
      label: 'Backup Recommended',
    }, {
      value: 'Not Recommended',
      label: 'Not Recommended',
    },
  ];

  const lcorActionsLimited = [
    {
      value: 'Recommended by LCOR',
      label: 'Recommended by LCOR',
    },
    {
      value: 'Action Not Taken',
      label: 'Action Not Taken',
    },
  ]

  const cflActions = [
    {
      value: 'Action Not Taken',
      label: 'Action Not Taken',
    },
    {
      value: 'LCOR Review',
      label: 'LCOR Review',
    },
    {
      value: 'Backup',
      label: 'Backup',
    },
    {
      value: 'Sponsor Review',
      label: 'Sponsor Review',
    },
    {
      value: 'Sponsor Approved',
      label: 'Sponsor Approved',
    },
    {
      value: 'Sponsor Rejected',
      label: 'Sponsor Rejected',
    },
    {
      value: 'Trigger Survey',
      label: 'Trigger Survey',
    },
    {
      value: 'Do not use',
      label: 'Do not use',
    },
  ];

  const sponsorActions = [
    {
      value: 'Action Not Taken',
      label: 'Action Not Taken',
    },
    {
      value: 'Sponsor Approved',
      label: 'Sponsor Approved',
    },
    {
      value: 'Sponsor Rejected',
      label: 'Sponsor Rejected',
    },
    {
      value: 'Recommended by Sponsor',
      label: 'Recommended by Sponsor',
    },
  ];

  const savedProjectResultsCountsData = useSelector((state) => state.savedProjectResultsCountsData);
  const savedProjectResultsData = useSelector((state) => state.savedProjectResultsData);
  const createNewVersionResponse = useSelector((state) => state.createNewVersionResponse);
  const updatePiStatusResponse = useSelector((state) => state.updatePiStatusResponse);
  const createCommentResponse = useSelector((state) => state.createCommentResponse);
  const changePreferredSiteRespose = useSelector((store) => store.changePreferredSiteRespose);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  const [pages, setPages] = useState([]);
  const [mapFullScreen, setMapFullScreen] = useState(false);
  const [countsData, setCountsData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [sizeVal, setSizeVal] = useState(10000);
  const [appliedFilters, setAppliedFilters] = useState({
    alliance: 'All',
    is_added: 'All',
    is_added_by_sponsor: 'All',
    is_commented: 'All',
    quality_rating: undefined,
    tier: undefined,
    status: [],
    lcor_status: [],
  })
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0)
  const [sortObj, setSortObj] = useState({ industry_studies: 'desc' });
  const [hierarchy, setHierarchy] = useState('country,primary_organizations,pis');
  const [showAlertNotification, setShowAlertNotification] = useState(
    parse(props.location.search).is_previous_results,
  );
  const [searchPIInput, setSearchPIInput] = useState('');
  const [searchCenterInput, setSearchCenterInput] = useState('');
  const [isFilterPopoverVisible, setIsFilterPopoverVisible] = useState(false);
  const [isSelectCommentTypeModalVisible, setIsSelectCommentTypeModalVisible] = useState(false);
  const [showRunAnalysisMap, setShowRunAnalysisMap] = useState(false);
  const [savedProjectResults, setSavedProjectResults] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [exportVal, setExportVal] = useState('');
  const [isSortByPopoverVisible, setIsSortByPopoverVisible] = useState(false);
  const [fromVal, setFromVal] = useState(1);
  const [displayingSearchPIResults, setDisplayingSearchPIResults] = useState(false);
  const [displayingSearchCenterResults, setDisplayingSearchCenterResults] = useState(false);
  const [displayColumns, setDisplayColumns] = useState({
    ...projectkeys,
  });
  const [visible, setVisible] = useState(false);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [IsComparisionClicked, setComparisionClicked] = useState(false);
  const [IsSiteComparisionClicked, setSiteComparisionClicked] = useState(false);
  const [compareList, setCompareList] = useState(
    parse(props.location.search).compareList
      ? parse(props.location.search).compareList.split(',')
      : [],
  );
  const [checkCounter, setCheckCounter] = useState(0);
  const [disableComparision, setDisableComparision] = useState(false);
  const [disableSiteComparision, setDisableSiteComparision] = useState(false);
  const [onIndustryClicked, setOnIndustryClicked] = useState(false);
  const [industryStudiesSource, setIndustryStudiesSource] = useState('');
  const [onItmClicked, setOnItmClicked] = useState(false);
  const [clickedPId, setPId] = useState('');
  const [onPxlClicked, setOnPxlClicked] = useState(false);
  const [clickedPxId, setPxId] = useState('');
  const [onTierClicked, setOnTierClicked] = useState(false);
  const [onProfleTierClicked, setOnProfileTierClicked] = useState(false);
  const [clickedProfileTierId, setClickedProfleTierId] = useState('');
  const [clickedTierId, setTierId] = useState('');
  const [onReliabilityClicked, setOnReliabilityClicked] = useState(false);
  const [clickedReliabilityId, setReliabilityId] = useState('');
  const [piForComment, setPiForComment] = useState({});
  const [clickedIId, setIId] = useState('');
  const [selectedAnalysisId, setSelectedAnalysisId] = useState('');
  const [showPisOfParent, setShowPisOfParent] = useState('');
  const [showCountryHierarchy, setShowCountryHierarchy] = useState('');
  const [isCheckedboxEnabled, setIsCheckedboxEnabled] = useState(false);
  const [checkedPiList, setCheckedPiList] = useState([]);
  const [iidPiidArray, setIidPiidArray] = useState([]);
  const [piIdforMail, setPiIdForMail] = useState([]);
  const [actionSelectedForStatus, setActionSelectedForStatus] = useState('');
  const [priorityForBulk, setPriorityForBulk] = useState(null);
  const [versionForBulk, setVersionForBulk] = useState(null);
  const [originatingPartyForBulk, setOriginatingPartyForBulk] = useState(null);
  const [cflStatusSelected, setCflStatusSelected] = useState([]);
  const [lcorStatusSelected, setLcorStatusSelected] = useState([]);
  const [sponsorStatusSelected, setSponsorStatusSelected] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [lcorStatusList, setLcorStatusList] = useState([]);
  const [sponsorStatusList, setSponsorStatusList] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [displayAddBtnsOverlay, setDisplayAddBtnsOverlay] = useState(false);
  const [displaySponsorsModal, setDisplaySponsorsModal] = useState(false);
  const [isCommentNeeded, setIsCommentNeeded] = useState({ isNeeded: false, isProvied: false });
  const [valueToCheck, setValueToCheck] = useState(false);
  const [searchPi, setSearchPi] = useState(false);
  const [isPrioritySingle, setIsPrioritySingle] = useState(false);
  const [countryClose, setCountryClose] = useState(false);
  const [summaryModalOpen, setSummaryModalOpen] = useState(false);
  const [versionModalOpen, setVersionModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [questionModal, setQuestionModal] = useState(false);
  const [viewQuestionScreen, setViewQuestionScreen] = useState(true);

  const [orgChangeModalOpen, setOrgChangeModalOpen] = useState({
    isOpen: false,
    siteId: '',
    piId: '',
    isAlliance: null,
  });
  const [piHasComplianceIssue, setPiHasComplianceIssue] = useState({
    isOpen: false,
    id: '',
    piId: '',
    status: '',
  });
  const [saveWarningModal, setSaveWarningModal] = useState({ isOpen: false, type: '' });
  const [cflStatusArray, setCflStatusArray] = useState([]);
  const [whatIsChanged, setWhatIsChanged] = useState({
    status: 0,
    priority: 0,
    party: 0,
    version: 0,
  });
  const [priorityList, setPriorityList] = useState([]);
  const [versionList, setVersionList] = useState([]);
  const [currentVersion, setCurrentVersion] = useState('');

  const [cflObjArray, setCflObjArray] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState();
  const [selectAllLimitModal, setSelectAllLimitModal] = useState(false);
  const hierarchyOptions = [
    {
      title: 'Country',
      value: 'country',
    },
    {
      title: 'Parent institute',
      value: 'parent_institutes',
    },
    {
      title: 'Primary Center',
      value: 'primary_organizations',
    },
    {
      title: 'Principal investigator',
      value: 'pis',
    },
  ];
  const parsedSearch = parse(props.location.search);

  const [openKOLModal, setKOLModal] = useState(false);
  const [kolData, setKolData] = useState({})
  const [tabName, setTabName] = useState('');
  const [pieChartData, setPieChartData] = useState({});

  if (compareList) {
    delete parsedSearch.compareList;
  }

  useEffect(() => {
    setSavedProjectResults([]);
    dispatch(
      getSavedProjectAction({
        project_id: parse(location.search).project_id,
        from: 1,
        size: sizeVal,
        filters: JSON.stringify(appliedFilters),
        hierarchy,
        order_by: JSON.stringify(sortObj),
        query: searchPIInput,
        is_previous_results: parse(props.location.search).is_previous_results,
        center_query: searchCenterInput,
      }),
    );
    dispatch(
      getSavedProjectCountsAction({
        project_id: projectID,
        is_previous_results: parse(props.location.search).is_previous_results,
      }),
    );
    setIsScrollEnabled(false);
  }, []);

  useEffect(() => {
    if (updatePiStatusResponse.flag) {
      setSavedProjectResults([]);
      setIsCheckedboxEnabled(false);
      setCheckedPiList([]);
      setIidPiidArray([]);
      setPiIdForMail([]);
      setCflObjArray([]);
      setCflStatusArray([]);
      setSelectedStatusList([]);
      setPriorityForBulk(undefined);
      setVersionForBulk(null);
      setOriginatingPartyForBulk(null);
      setActionSelectedForStatus('');
      setIsSelectAllChecked(false);
      setIsCommentNeeded({
        isNeeded: false,
        isProvied: false,
      });
      if (saveWarningModal.isOpen) {
        // setIsSelectAllChecked(true)
        setSaveWarningModal({ isOpen: false });
      }
      dispatch(
        getSavedProjectAction({
          project_id: parse(location.search).project_id,
          from: 1,
          size: sizeVal,
          filters: JSON.stringify(appliedFilters),
          hierarchy,
          order_by: JSON.stringify(sortObj),
          query: searchPIInput,
          is_previous_results: parse(props.location.search).is_previous_results,
          center_query: searchCenterInput,
        }),
      );
      dispatch(
        getSavedProjectCountsAction({
          project_id: projectID,
          is_previous_results: parse(props.location.search).is_previous_results,
        }),
      );
      dispatch(updatePiStatusResetAction({}));
    }
    // }
  }, [JSON.stringify(updatePiStatusResponse)]);

  useEffect(() => {
    if (createNewVersionResponse.flag) {
      dispatch(
        getSavedProjectAction({
          project_id: parse(location.search).project_id,
          from: 1,
          size: sizeVal,
          filters: JSON.stringify(appliedFilters),
          hierarchy,
          order_by: JSON.stringify(sortObj),
          query: searchPIInput,
          is_previous_results: parse(props.location.search).is_previous_results,
          center_query: searchCenterInput,
        }),
      );
      dispatch(
        getSavedProjectCountsAction({
          project_id: projectID,
          is_previous_results: parse(props.location.search).is_previous_results,
        }),
      );
      dispatch(createNewVersionResetAction({}));
    }
  }, [createNewVersionResponse]);

  useEffect(() => {
    if (changePreferredSiteRespose.flag) {
      setOrgChangeModalOpen({
        isOpen: false,
        siteId: '',
        piId: '',
      });
      dispatch(
        getSavedProjectAction({
          project_id: parse(location.search).project_id,
          from: 1,
          size: sizeVal,
          filters: JSON.stringify(appliedFilters),
          hierarchy,
          order_by: JSON.stringify(sortObj),
          query: searchPIInput,
          is_previous_results: parse(props.location.search).is_previous_results,
          center_query: searchCenterInput,
        }),
      );
      dispatch(getPreferredSiteResetAction());
    }
  }, [JSON.stringify(changePreferredSiteRespose)]);
  useEffect(() => {
    if (createCommentResponse.flag) {
      // setSelectedStatusList([])
      setPiIdForMail([]);
      dispatch(createCommentResetAction({}));
    }
  }, [JSON.stringify(createCommentResponse)]);

  useEffect(() => {
    if (savedProjectResultsData.flag) {
      setCurrentPageNumber(0);
      getData(savedProjectResultsData?.data?.data[0]?.result_data);
      setSavedProjectResults(savedProjectResultsData?.data?.data[0]?.result_data);
      setStatusList(savedProjectResultsData?.data?.data[0]?.statuses);
      setLcorStatusList(savedProjectResultsData?.data?.data[0]?.lcorStatuses);
      setSponsorStatusList(savedProjectResultsData?.data?.data[0]?.sponsorStatuses);
      setResultsTotal(savedProjectResultsData.data.total);
      setPriorityList(savedProjectResultsData?.data?.data[0]?.priority);
      setVersionList(savedProjectResultsData?.data?.data[0]?.versions);
      setCurrentVersion(savedProjectResultsData?.data?.data[0]?.current_active_version);
    }
  }, [JSON.stringify(savedProjectResultsData)]);

  useEffect(() => {
    if (savedProjectResultsCountsData.flag && savedProjectResultsCountsData.data.data) {
      setCountsData(savedProjectResultsCountsData.data.data.result_data);
    }
  }, [JSON.stringify(savedProjectResultsCountsData)]);

  const handleBackButton = () => {
    if (cflStatusArray.length !== 0) {
      setSaveWarningModal({ isOpen: true, type: 'backBtn' });
    } else {
      history.goBack();
    }
  };

  const handleDiscardBtn = () => {
    setCflStatusArray([]);
    setCflObjArray([]);
    if (saveWarningModal.type === 'backBtn') {
      history.goBack();
    }
    if (saveWarningModal.type === 'selectAll') {
      setIsSelectAllChecked(true);
    }
    setSaveWarningModal({ isOpen: false, type: '' });
  };

  const renderAnalysis = () => {
    if (countsData.length) {
      return countsData.map((analysisItm, index) => (
        <div className="header-card-content" key={index}>
          {analysisItm.query !== 'Manually added'
          && analysisItm.query !== "Added from sponsor's list" ? (
            <div className="card">
              <div className="card-content">
                <div className="card-content-value">
                  <div className="card-content-value-title">Analysis :</div>
                  <div className="card-content-value-desc" title={analysisItm.analysis_name}>
                    {analysisItm.analysis_seq_id ? analysisItm.analysis_seq_id : '-'} /{' '}
                    {analysisItm.analysis_name}
                  </div>
                </div>
                <div className="card-content-value">
                  <div className="card-content-value-title">Query :</div>
                  <div className="card-content-value-desc" title={analysisItm.query}>
                    {analysisItm.query_seq_id ? analysisItm.query_seq_id : '-'} /{' '}
                    {analysisItm.query ? analysisItm.query : '-'}
                  </div>
                </div>
                <div className="card-content-value">
                  <div className="card-content-value-title">Centers:</div>
                  <div className="card-content-value-desc">
                    {analysisItm.centers ? analysisItm.centers : '-'}
                  </div>
                </div>
                <div className="card-content-value">
                  <div className="card-content-value-title">Pls:</div>
                  <div className="card-content-value-desc">
                    {analysisItm.pis ? analysisItm.pis : '-'}
                  </div>
                </div>
              </div>
            </div>
            ) : (
              <div className="card">
                <div className="card-content">
                  <div className="card-content-value">
                    <div className="card-content-value-title">Type</div>
                    <div className="card-content-value-desc">
                      {analysisItm.query ? analysisItm.query : '-'}
                    </div>
                  </div>
                  <div className="card-content-value">
                    <div className="card-content-value-title">Query :</div>
                    <div className="card-content-value-desc"> - </div>
                  </div>
                  <div className="card-content-value">
                    <div className="card-content-value-title">Centers:</div>
                    <div className="card-content-value-desc">
                      {analysisItm.centers ? analysisItm.centers : '-'}
                    </div>
                  </div>
                  <div className="card-content-value">
                    <div className="card-content-value-title">Pls:</div>
                    <div className="card-content-value-desc">
                      {analysisItm.pis ? analysisItm.pis : '-'}
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      ));
    }
    return null;
  };

  const handlePriorityForSingle = (innoplexus_id, Priority, party, pi_id) => {
    setIsPrioritySingle(true);
    const updateParams = cflObjArray || [];
    const updateArray = cflStatusArray || [];
    if (cflStatusArray.includes(pi_id)) {
      const index = cflObjArray.findIndex((item) => item.pi_id === pi_id);
      updateParams[index].priority = Priority;
    } else {
      updateParams.push({
        pi_id,
        innoplexus_id,
        status: whatIsChanged.status ? actionSelectedForStatus : undefined,
        priority: Priority,
      });
      updateArray.push(pi_id);
    }
    setCflObjArray(updateParams);
    setCflStatusArray(updateArray);
    if (isPrioritySingle) {
      setIsPrioritySingle(false);
    }
  };

  const handleOriginForSingle = (innoplexus_id, originVal, pi_id) => {
    setIsPrioritySingle(true);
    const updateParams = cflObjArray || [];
    const updateArray = cflStatusArray || [];
    if (cflStatusArray.includes(pi_id)) {
      const index = cflObjArray.findIndex((item) => item.pi_id === pi_id);
      updateParams[index].party = originVal;
    } else {
      updateParams.push({
        pi_id,
        innoplexus_id,
        status: whatIsChanged.status ? actionSelectedForStatus : undefined,
        priority: undefined,
        party: originVal,
      });
      updateArray.push(pi_id);
    }
    setCflObjArray(updateParams);
    setCflStatusArray(updateArray);
    if (isPrioritySingle) {
      setIsPrioritySingle(false);
    }
  };

  const handleVersionForSingle = (innoplexus_id, originVal, pi_id) => {
    setIsPrioritySingle(true);
    const updateParams = cflObjArray || [];
    const updateArray = cflStatusArray || [];
    if (cflStatusArray.includes(pi_id)) {
      const index = cflObjArray.findIndex((item) => item.pi_id === pi_id);
      updateParams[index].version = originVal;
    } else {
      updateParams.push({
        pi_id,
        innoplexus_id,
        status: whatIsChanged.status ? actionSelectedForStatus : undefined,
        priority: undefined,
        version: originVal,
      });
      updateArray.push(pi_id);
    }
    setCflObjArray(updateParams);
    setCflStatusArray(updateArray);
    if (isPrioritySingle) {
      setIsPrioritySingle(false);
    }
  };
  const exportData = (val) => {
    setExportVal(val);
    setDownloading(true);
    let params;
    if (val === 'complete' || val === 'filtered' || val === 'qualtrics') {
      params = {
        project_id: parse(location.search).project_id,
        // analysis_id: parse(location.search).analysis_id,
        filters: JSON.stringify(appliedFilters),
        query: searchPIInput,
        center_query: searchCenterInput,
        type: val,
      };
    } else if (val === 'sponsor') {
      params = {
        project_id: parse(location.search).project_id,
        filters: JSON.stringify(appliedFilters),
      };
    } else {
      params = {
        project_id: parse(location.search).project_id,
        is_downloading: val === 'qualtrics' || val === 'cat' ? undefined : true,
        filters: JSON.stringify(appliedFilters),
        query: searchPIInput,
        center_query: searchCenterInput,
        is_cat_downloading: val === 'cat' ? true : undefined,
      };
    }
    let filename = '';
    if (val === 'sponsor') {
      const dateObj = new Date();
      filename = `${
        parse(location.search).project_name
      }_Sponsor_Review_${dateObj.getUTCFullYear()}${
        dateObj.getUTCMonth() + 1 < 10
          ? '0'.concat(dateObj.getUTCMonth() + 1)
          : dateObj.getUTCMonth() + 1
      }${dateObj.getUTCDate() < 10 ? '0'.concat(dateObj.getUTCDate()) : dateObj.getUTCDate()}.xlsx`;
    } else {
      filename = `Project_${parse(location.search).project_name}_Exported_Data_${
        val === 'qualtrics' ? 'Qualtrics_' : val === 'cat' ? 'CAT_' : ''
      }${new Date()}.xlsx`;
    }

    Axios({
      url:
        val === 'sponsor'
          ? `${process.env.apiUrl}v0/project/exportSponsorReviewPIs?${stringify(params)}`
          : val === 'cat'
            ? `${process.env.apiUrl}v0/search/getAnalysisResult?${stringify(params)}`
            : `${process.env.apiUrl}v0/export/exportProjectResults?${stringify(params)}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
        setExportVal('');
        return null;
      })
      .catch((error) => {
        setDownloading(false);
        return error;
      });
  };
  const handlePiCheckbox = (
    id,
    status,
    piId,
    originating_party,
    compFlag,
    is_added,
    is_added_by_sponsor,
  ) => {
    if (checkedPiList.includes(id)) {
      if (checkedPiList.length === 1) {
        setIsCheckedboxEnabled(false);
        setActionSelectedForStatus('');
        setIsCommentNeeded({
          isNeeded: false,
          isProvied: false,
        });
      }
      const pos = selectedStatusList.indexOf(status);
      const newList = selectedStatusList;
      newList.splice(pos, 1);
      setSelectedStatusList(newList);
      const temp = checkedPiList.filter((itm) => itm !== id);
      setCheckedPiList(temp);
      const temp2 = iidPiidArray.filter((itm) => itm.iid !== id);
      setIidPiidArray(temp2);
      const tempPiList = piIdforMail.filter((itm) => itm !== piId);
      setPiIdForMail(tempPiList);
      setPiHasComplianceIssue({
        isOpen: false,
        id: '',
        piId: '',
        status: '',
      });
    } else if (compFlag === 'Y') {
      setPiHasComplianceIssue({
        isOpen: true,
        id,
        piId,
        status,
      });
    } else {
      setSelectedStatusList([...selectedStatusList, status]);
      // setIidPiidArray([...iidPiidArray, { iid: id, pi_id: piId, originating_party: originating_party !== 'landSCAPE' ? originating_party : 'landSCAPE' }])
      setIidPiidArray([
        ...iidPiidArray,
        {
          iid: id,
          pi_id: piId,
          originating_party,
          status,
          is_added,
          is_added_by_sponsor,
        },
      ]);
      setCheckedPiList([...checkedPiList, id]);
      setPiIdForMail([...piIdforMail, piId]);
      setIsCheckedboxEnabled(true);
      setPiHasComplianceIssue({
        isOpen: false,
        id: '',
        piId: '',
        status: '',
      });
    }
    return undefined;
  };
  const reOrderHierarchy = (str) => {
    const temp = [];
    str.split(',').map((itm) => {
      temp[hierarchyOptions.findIndex((obj) => obj.value === itm)] = itm;
      return null;
    });
    return temp.filter((el) => el).join();
  };

  const applySort = (srtObj) => {
    setSortObj(srtObj);
    setFromVal(1);
    dispatch(
      getSavedProjectAction({
        project_id: projectID,
        from: 1,
        size: sizeVal,
        filters: JSON.stringify(appliedFilters),
        hierarchy,
        order_by: JSON.stringify(srtObj),
        query: searchPIInput,
        is_previous_results: parse(props.location.search).is_previous_results,
        center_query: searchCenterInput,
      }),
    );
    setIsSortByPopoverVisible(false);
  };

  const applyFilters = (filtersObj) => {
    let count = 0
    Object.keys(filtersObj).forEach((key) => {
      const value = filtersObj[key];
      if (typeof value === 'string' && value !== 'All') {
        count += 1
      }
      if (typeof value === 'object' && value?.length) {
        count += 1
      }
      if (typeof value === 'number' && value > 0) {
        count += 1
      }
    });
    setAppliedFiltersCount(count)
    setAppliedFilters({ ...appliedFilters, ...filtersObj });
    setFromVal(1);
    dispatch(
      getSavedProjectAction({
        project_id: parse(location.search).project_id,
        from: 1,
        size: sizeVal,
        filters: JSON.stringify({ ...appliedFilters, ...filtersObj }),
        hierarchy,
        order_by: JSON.stringify(sortObj),
        query: searchPIInput,
        is_previous_results: parse(props.location.search).is_previous_results,
        center_query: searchCenterInput,
      }),
    );
    setIsFilterPopoverVisible(false);
  };

  const resetFilters = () => {
    const temp = {
      alliance: 'All',
      is_added: 'All',
      is_added_by_sponsor: 'All',
      is_commented: 'All',
      quality_rating: undefined,
      tier: undefined,
      status: cflStatusSelected,
      lcor_status: lcorStatusSelected,
      sponsor_status: sponsorStatusSelected,
    };
    setAppliedFiltersCount(0)
    setAppliedFilters(temp);
    setFromVal(1);
    dispatch(
      getSavedProjectAction({
        project_id: parse(location.search).project_id,
        from: 1,
        size: sizeVal,
        filters: JSON.stringify(temp),
        hierarchy,
        order_by: JSON.stringify(sortObj),
        query: searchPIInput,
        is_previous_results: parse(props.location.search).is_previous_results,
        center_query: searchCenterInput,
      }),
    );
    setIsFilterPopoverVisible(false);
  };

  const onSearchPIInputChange = (e) => {
    if (e.target.value && e.target.value.trim().length) {
      setSearchPIInput(e.target.value);
    } else {
      setSearchPIInput('');
    }
  };

  const onSearchCenterInputChange = (e) => {
    if (e.target.value && e.target.value.trim().length) {
      setSearchCenterInput(e.target.value);
    } else {
      setSearchCenterInput('');
    }
  };
  const onClearSearchPIInput = () => {
    setSearchPIInput('');
    setDisplayingSearchPIResults(false);
    setFromVal(1);
    dispatch(
      getSavedProjectAction({
        project_id: projectID,
        from: 1,
        size: sizeVal,
        filters: JSON.stringify(appliedFilters),
        hierarchy,
        order_by: JSON.stringify(sortObj),
        query: '',
        is_previous_results: parse(props.location.search).is_previous_results,
        center_query: searchCenterInput,
      }),
    );
  };

  const onClearSearchCenterInput = () => {
    setSearchCenterInput('');
    setDisplayingSearchCenterResults(false);
    setFromVal(1);
    dispatch(
      getSavedProjectAction({
        project_id: projectID,
        from: 1,
        size: sizeVal,
        filters: JSON.stringify(appliedFilters),
        hierarchy,
        order_by: JSON.stringify(sortObj),
        query: searchPIInput,
        center_query: '',
      }),
    );
  };

  const onPISearch = () => {
    if (searchPIInput) {
      setFromVal(1);
      dispatch(
        getSavedProjectAction({
          project_id: projectID,
          from: 1,
          size: sizeVal,
          filters: JSON.stringify(appliedFilters),
          hierarchy,
          order_by: JSON.stringify(sortObj),
          query: searchPIInput,
          center_query: searchCenterInput,
        }),
      );
      setDisplayingSearchPIResults(true);
    }
  };

  const onCenterSearch = () => {
    if (searchCenterInput) {
      setFromVal(1);
      dispatch(
        getSavedProjectAction({
          project_id: projectID,
          from: 1,
          size: sizeVal,
          filters: JSON.stringify(appliedFilters),
          hierarchy,
          order_by: JSON.stringify(sortObj),
          query: searchPIInput,
          center_query: searchCenterInput,
        }),
      );
      setDisplayingSearchCenterResults(true);
    }
  };

  const handleHierarchyChange = (val) => {
    setSavedProjectResults([]);
    setPages([]);
    const temp = [...val];
    if (temp.indexOf('primary_organizations') === -1 && temp.indexOf('parent_institutes') !== -1) {
      temp.splice(temp.indexOf('parent_institutes'), 1);
    }
    setSizeVal(10000);
    setHierarchy(temp.join(','));
    setFromVal(1);
    dispatch(
      getSavedProjectAction({
        project_id: projectID,
        from: 1,
        size: val.length === 1 && val[0] === 'pis' ? 100 : 25,
        filters: JSON.stringify(appliedFilters),
        hierarchy: temp.join(','),
        order_by: JSON.stringify(sortObj),
        query: searchPIInput,
        is_previous_results: parse(props.location.search).is_previous_results,
        center_query: searchCenterInput,
      }),
    );
    setCheckedPiList([]);
    setIidPiidArray([]);
    setSelectedStatusList([]);
  };

  const applyColumns = (columnsObj) => {
    setDisplayColumns(columnsObj);
    setVisible(false);
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const checkColumnDisplay = (columnName) => {
    const viewedKeys = Object.keys(displayColumns).filter((key) => displayColumns[key]);
    return viewedKeys.indexOf(columnName) !== -1;
  };

  const loadMoreRows = () => {
    setFromVal(fromVal + 1);
    dispatch(
      getSavedProjectMoreAction({
        project_id: projectID,
        from: fromVal + 1,
        size: sizeVal,
        filters: JSON.stringify(appliedFilters),
        hierarchy,
        order_by: JSON.stringify(sortObj),
        query: searchPIInput,
        is_previous_results: parse(props.location.search).is_previous_results,
        center_query: searchCenterInput,
      }),
    );
  };

  const checkCompareEnable = () => {
    return compareList.length < 2 || compareList.length > 3;
  };

  const handleStatusChange = (e, type) => {
    if (cflStatusArray.length !== 0) {
      setSaveWarningModal({ isOpen: true, type: 'filter' });
    } else if (type === 'lcor') {
      setLcorStatusSelected(e);
    } else if (type === 'sponsor') {
      setSponsorStatusSelected(e)
    } else {
      setCflStatusSelected(e);
    }
  };

  const ResetCompare = () => {
    setCompareList([]);
    setCheckCounter(0);
    setComparisionClicked(false);
    setSiteComparisionClicked(false);
    setDisableComparision(false);
    setDisableSiteComparision(false);
    const params = {
      ...parse(location.search),
    };
    delete params.compareFlag;
    delete params.compareSiteFlag;
    delete params.compareList;
    history.replace(`${location.pathname}?${stringify(params)}`);
  };

  const handleOnSiteCompareClick = () => {
    const params = {
      lastUrlPath: `${location.pathname}`,
      locationSearch: stringify(parsedSearch),
      compareList: compareList.toString(),
      project_name: parse(location.search).project_name,
      analysis_name: parse(location.search).analysis_name,
    };
    history.push(`/projects/comparesites?${stringify(params)}`);
  };

  const handleOnCompareClick = () => {
    const params = {
      lastUrlPath: `${location.pathname}`,
      locationSearch: stringify(parsedSearch),
      compareList: compareList.toString(),
      project_name: parse(location.search).project_name,
      analysis_name: parse(location.search).analysis_name,
    };
    history.push(`/projects/compareinvestigators?${stringify(params)}`);
  };

  const onHeaderScroll = () => {
    const source = document.getElementById('table-column-header');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft = source.scrollLeft;
    });
  };

  const onIndustryClick = (e, pId, industrySource, analysisId) => {
    setSelectedAnalysisId(analysisId);
    setOnIndustryClicked(true);
    setIId(pId);
    setIndustryStudiesSource(industrySource);
  };

  const checkDefaultChecked = (id) => {
    return compareList.includes(id);
  };

  const onCompetingClick = (pId, analysisId) => {
    setSelectedAnalysisId(analysisId);
    setOnItmClicked(true);
    setPId(pId);
  };

  const onCheckboxChange = (e, selectedPi) => {
    if (e.target.checked) {
      if (checkCounter <= 2) {
        setCompareList((val) => [...val, selectedPi]);
        setCheckCounter((c) => c + 1);
      }
    } else {
      setCompareList((val) => val.filter((id) => id !== selectedPi));
      setCheckCounter((c) => c - 1);
    }
  };

  const onRowScroll = (e) => {
    const source = document.getElementById('table-column-header');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft = e.target.scrollLeft;
    });
    source.scrollLeft = e.target.scrollLeft;
  };
  const onTierClick = (itm) => {
    setOnTierClicked(true);
    setTierId(itm);
  };

  const onProfileTierClick = (itm) => {
    setOnProfileTierClicked(true);
    setClickedProfleTierId(itm);
  };

  const onTableScrollRight = () => {
    const source = document.getElementById('table-column-header');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft += 100;
    });
    source.scrollLeft += 100;
  };

  const onTableScrollLeft = () => {
    const source = document.getElementById('table-column-header');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft -= 100;
    });
    source.scrollLeft -= 100;
  };

  const onPxlClick = (pId, analysisId) => {
    setSelectedAnalysisId(analysisId);
    setOnPxlClicked(true);
    setPxId(pId);
  };

  const onReliabilityClick = (itm) => {
    setOnReliabilityClicked(true);
    setReliabilityId(itm);
  };

  const onKolScoreClick = (itm) => {
    setKOLModal(true)
    setKolData(itm)
    if (itm.kol_score.length) {
      setTabName(itm.kol_score[0].therapeutic_area)
    } else {
      setTabName('')
    }
    if (Object.keys(itm.pie_chart).length) {
      setPieChartData(itm.pie_chart)
    } else {
      setPieChartData({})
    }
  }

  // const onKolScoreClick = (itm) => {
  //   setKOLModal(true)
  //   setKolData(itm)
  // }

  const handleMenuClick = (e, itm) => {
    if (e.key === '1') {
      setIsSelectCommentTypeModalVisible(true);
      setPiForComment(itm);
      setPiForComment({ ...piForComment, isSelectCommentTypeModalVisible });
    }
    if (e.key === '2') {
      setComparisionClicked(true);
      setDisableComparision(false);
      setDisableSiteComparision(true);
    }
  };

  const checkDisabled = () => {
    if (disableComparision === true || disableSiteComparision === true) {
      return true;
    }
    return false;
  };

  const dataMenu = (itm) => (
    <Menu
      onClick={(e) => handleMenuClick(e, itm)}
      className="project-detail-cardmenu-dropdown"
      items={[
        {
          key: '2',
          label: 'Select for comparision',
          className: 'project-details-dropdown-icon comparision-dropdown-icon',
          disabled: checkDisabled() || parse(location.search).compareSiteFlag,
        },
        {
          key: '4',
          label: 'Compliance Issue',
          className: 'project-details-dropdown-icon compliance-dropdown-icon disabled',
        },
      ]}
    />
  );

  const siteDataMenu = () => (
    <Menu
      onClick={(e) => handleSiteMenuClick(e)}
      className="project-detail-cardmenu-dropdown"
      items={[
        {
          key: '1',
          label: 'Select for comparision',
          className: 'project-details-dropdown-icon comparision-dropdown-icon',
          disabled: checkDisabled() || parse(location.search).compareFlag,
        },
      ]}
    />
  );

  const handleSiteMenuClick = (e) => {
    if (e.key === '1') {
      setSiteComparisionClicked(true);
      setDisableComparision(true);
      setDisableSiteComparision(false);
    }
  };
  const getOrginatingPartyForCfl = (itm) => {
    if (originatingPartyForBulk !== null) {
      return originatingPartyForBulk;
    }
    if (actionSelectedForStatus === 'Do not use') {
      return itm.originating_party;
    }
    if (actionSelectedForStatus !== '' && itm.originating_party === 'landSCAPE') {
      return 'CFL';
    }
    return itm.originating_party;
  };

  const getOrginatingPartyForLcor = (itm) => {
    if (originatingPartyForBulk !== null) {
      return originatingPartyForBulk;
    }
    if (actionSelectedForStatus === 'Not Recommended') {
      return itm.originating_party;
    }
    if (actionSelectedForStatus !== '' && itm.originating_party === 'landSCAPE') {
      return 'LCOR';
    }
    return itm.originating_party;
  };

  const handleStatusUpdateClick = () => {
    if (adminType.includes('cfl_access')) {
      if (!isSelectAllChecked) {
        const updateParams = cflObjArray || [];
        const updateArray = cflStatusArray || [];
        iidPiidArray.map((itm) => {
          if (cflStatusArray.includes(itm.pi_id)) {
            const index = cflObjArray.findIndex((item) => item.pi_id === itm.pi_id);
            if (whatIsChanged.status) {
              updateParams[index].status = actionSelectedForStatus;
              // updateParams[index].party = itm.originating_party
            }
            if (whatIsChanged.priority) {
              updateParams[index].priority = priorityForBulk;
            }
            if (whatIsChanged.version) {
              updateParams[index].version = versionForBulk;
            }
            updateParams[index].party = getOrginatingPartyForCfl(itm);
          } else {
            updateParams.push({
              pi_id: itm.pi_id,
              innoplexus_id: itm.iid,
              status: whatIsChanged.status ? actionSelectedForStatus : undefined,
              priority: whatIsChanged.priority ? priorityForBulk : undefined,
              party: getOrginatingPartyForCfl(itm),
              version: whatIsChanged.version ? versionForBulk : undefined,
            });
            updateArray.push(itm.pi_id);
          }
          setCflObjArray(updateParams);
          setCflStatusArray(updateArray);
          return null;
        });
        setIsCheckedboxEnabled(false);
        setWhatIsChanged({
          status: 0,
          priority: 0,
          party: 0,
        });
        setCheckedPiList([]);
        setIidPiidArray([]);
        setPiIdForMail([]);
        setSelectedStatusList([]);
        setPriorityForBulk(undefined);
        setOriginatingPartyForBulk(null);
        setVersionForBulk(null);
        setActionSelectedForStatus('');
        setIsSelectAllChecked(false);
        setIsCommentNeeded({
          isNeeded: false,
          isProvied: false,
        });
      }
      if (isSelectAllChecked) {
        const newFilters = {
          ...appliedFilters,
          updateObj: {
            priority: priorityForBulk !== null ? priorityForBulk : undefined,
            originating_party: originatingPartyForBulk || undefined,
            status: actionSelectedForStatus || undefined,
            version: versionForBulk || undefined,
          },
        };
        const updateParams = [];
        dispatch(
          updatePiForAllAction({
            project_id: parse(location.search).project_id,
            filters: newFilters,
            updateParams,
          }),
        );
      }
    } else {
      if (!isSelectAllChecked) {
        const updateParams = cflObjArray || [];
        const updateArray = cflStatusArray || [];
        iidPiidArray.map((itm) => {
          if (cflStatusArray.includes(itm.pi_id)) {
            const index = cflObjArray.findIndex((item) => item.pi_id === itm.pi_id);
            if (whatIsChanged.status) {
              updateParams[index].status = actionSelectedForStatus;
              // updateParams[index].party = itm.originating_party
            }
            if (whatIsChanged.priority) {
              updateParams[index].priority = priorityForBulk;
            }
            if (whatIsChanged.version) {
              updateParams[index].version = versionForBulk;
            }
            updateParams[index].party = getOrginatingPartyForLcor(itm);
          } else {
            updateParams.push({
              pi_id: itm.pi_id,
              innoplexus_id: itm.iid,
              status: whatIsChanged.status ? actionSelectedForStatus : undefined,
              priority: whatIsChanged.priority ? priorityForBulk : undefined,
              party: getOrginatingPartyForLcor(itm),
              version: whatIsChanged.version ? versionForBulk : undefined,
            });
            updateArray.push(itm.pi_id);
          }
          setCflObjArray(updateParams);
          setCflStatusArray(updateArray);
          return null;
        });
        setIsCheckedboxEnabled(false);
        setWhatIsChanged({
          status: 0,
          priority: 0,
          party: 0,
          version: 0,
        });
        setCheckedPiList([]);
        setIidPiidArray([]);
        setPiIdForMail([]);
        setSelectedStatusList([]);
        setPriorityForBulk(undefined);
        setOriginatingPartyForBulk(null);
        setVersionForBulk(null);
        setActionSelectedForStatus('');
        setIsSelectAllChecked(false);
        setIsCommentNeeded({
          isNeeded: false,
          isProvied: false,
        });
      }
      if (isSelectAllChecked) {
        const newFilters = {
          ...appliedFilters,
          updateObj: {
            priority: priorityForBulk !== null ? priorityForBulk : undefined,
            lcor_status: actionSelectedForStatus || undefined,
            originating_party: originatingPartyForBulk || undefined,
            version: versionForBulk || undefined,
          },
        };
        const updateParams = [];
        dispatch(
          updatePiForAllAction({
            project_id: parse(location.search).project_id,
            filters: newFilters,
            updateParams,
          }),
        );
      }
    }
  };

  const onSaveClick = () => {
    const updateParams = [];
    if (adminType.includes('cfl_access') && !isSelectAllChecked) {
      cflObjArray.map((itm) => {
        updateParams.push({
          innoplexus_id: itm.innoplexus_id,
          pi_id: itm.pi_id,
          updateObj: {
            status: itm.status !== '' ? itm?.status : undefined,
            priority: itm?.priority,
            originating_party: itm.party,
            version: itm.version,
          },
        });
        return null;
      });
      dispatch(
        updatePiStatusAction({
          project_id: parse(location.search).project_id,
          updateParams,
        }),
      );
    } else if (adminType.includes('lcor_access') && !isSelectAllChecked) {
      cflObjArray.map((itm) => {
        updateParams.push({
          innoplexus_id: itm.innoplexus_id,
          pi_id: itm.pi_id,
          updateObj: {
            lcor_status: itm.status !== '' ? itm?.status : undefined,
            priority: itm?.priority,
            originating_party: itm.party,
            version: itm.version,
          },
        });
        return null;
      });
      dispatch(
        updatePiStatusAction({
          project_id: parse(location.search).project_id,
          updateParams,
        }),
      );
    } else if (adminType.includes('sponsor_access') && !isSelectAllChecked) {
      cflObjArray.map((itm) => {
        updateParams.push({
          innoplexus_id: itm.innoplexus_id,
          pi_id: itm.pi_id,
          updateObj: {
            sponsor_status: itm.status !== '' ? itm?.status : undefined,
            priority: itm?.priority,
            originating_party: itm.party,
            version: itm.version,
          },
        });
        return null;
      });
      dispatch(
        updatePiStatusAction({
          project_id: parse(location.search).project_id,
          updateParams,
        }),
      );
    } else if (isSelectAllChecked && adminType.includes('cfl_access')) {
      const newFilters = {
        ...appliedFilters,
        updateObj: {
          priority: priorityForBulk !== null ? priorityForBulk : undefined,
          originating_party: originatingPartyForBulk || undefined,
          status: actionSelectedForStatus || undefined,
          version: versionForBulk || undefined,
        },
      };
      dispatch(
        updatePiForAllAction({
          project_id: parse(location.search).project_id,
          filters: newFilters,
          updateParams,
          body: [savedProjectResultsData?.data?.stateAllObject],
        }),
      );
    } else if (isSelectAllChecked && adminType.includes('lcor_access')) {
      const newFilters = {
        ...appliedFilters,
        updateObj: {
          priority: priorityForBulk !== null ? priorityForBulk : undefined,
          lcor_status: actionSelectedForStatus || undefined,
          originating_party: originatingPartyForBulk || undefined,
          version: versionForBulk || undefined,
        },
      };
      dispatch(
        updatePiForAllAction({
          project_id: parse(location.search).project_id,
          filters: newFilters,
          updateParams,
          body: [savedProjectResultsData?.data?.stateAllObject],
        }),
      );
    } else if (isSelectAllChecked && adminType.includes('sponsor_access')) {
      const newFilters = {
        ...appliedFilters,
        updateObj: {
          priority: priorityForBulk !== null ? priorityForBulk : undefined,
          sponsor_status: actionSelectedForStatus || undefined,
          originating_party: originatingPartyForBulk || undefined,
          version: versionForBulk || undefined,
        },
      };
      dispatch(
        updatePiForAllAction({
          project_id: parse(location.search).project_id,
          filters: newFilters,
          updateParams,
          body: [savedProjectResultsData?.data?.stateAllObject],
        }),
      );
    }
  };
  const handleActionClicked = (action) => {
    if (action === undefined) {
      setActionSelectedForStatus('');
      setIsCommentNeeded({ isNeeded: false, isProvied: false });
    } else {
      setActionSelectedForStatus(action);
      setWhatIsChanged({ ...whatIsChanged, status: 1 });
      if (action === 'Not Recommended' || action === 'Maybe with concern') {
        setIsCommentNeeded({ isNeeded: true });
      } else {
        setIsCommentNeeded({ isNeeded: false, isProvied: false });
      }
    }
  };
  const getLcorAction = () => {
    if (isSelectAllChecked) {
      if (cflStatusSelected.length === 1 && cflStatusSelected.includes('Action Not Taken')) {
        return lcorActionsLimited;
      }
      if (
        Object.keys(appliedFilters).includes('originating_party')
        && Object.keys(appliedFilters).includes('is_added')
      ) {
        if (
          appliedFilters.originating_party.length === 1
          && appliedFilters.originating_party.includes('LCOR')
          && appliedFilters.is_added === 'Yes'
        ) {
          return lcorActionsLimited;
        }
      } else if (
        Object.keys(appliedFilters).includes('originating_party')
        && Object.keys(appliedFilters).includes('is_added_by_sponsor')
      ) {
        if (
          appliedFilters.originating_party.length === 1
          && appliedFilters.originating_party.includes('LCOR')
          && appliedFilters.is_added_by_sponsor === 'Yes'
        ) {
          return lcorActionsLimited;
        }
      }
    } else {
      for (let i = 0; i < iidPiidArray.length; i += 1) {
        if (
          (iidPiidArray[i]?.status === 'Action Not Taken'
            || ((iidPiidArray[i]?.is_added === true
              || iidPiidArray[i]?.is_added_by_sponsor === true)
              && iidPiidArray[i]?.originating_party === 'LCOR')) === false
        ) {
          return lcorActions;
        }
      }
      return lcorActionsLimited;
    }
    return lcorActions;
  };

  const getActionItems = () => {
    if (adminType.includes('cfl_access')) {
      return (
        <div className="project-action-items-radio">
          <Select
            allowClear={actionSelectedForStatus}
            options={cflActions}
            onChange={(e) => handleActionClicked(e)}
            value={actionSelectedForStatus}
            style={{ width: '200px' }}
          />
        </div>
      );
    }
    if (adminType.includes('lcor_access')) {
      return (
        <div className="project-action-items-radio">
          <Select
            allowClear={actionSelectedForStatus}
            options={getLcorAction()}
            onChange={(e) => {
              setIsCommentNeeded({ isNeeded: false, isProvied: false })
              handleActionClicked(e)
            }}
            value={actionSelectedForStatus}
            style={{ width: '200px' }}
          />
        </div>
      );
    }

    if (adminType.includes('sponsor_access')) {
      return (
        <div className="project-action-items-radio">
          <Select
            allowClear
            options={sponsorActions}
            onChange={(e) => handleActionClicked(e)}
            value={actionSelectedForStatus}
            style={{ width: '200px' }}
          />
        </div>
      );
    }

    return <div className="action-error">Action not allowed</div>;
  };

  const getCflStatus = (itm, type) => {
    if (type === 'lcor' && adminType.includes('lcor_access')) {
      if (cflStatusArray.includes(itm.pi_id)) {
        const temp = cflObjArray.find((item) => item.pi_id === itm.pi_id);
        if (temp?.status) {
          return temp.status;
        }
      }
      return itm.lcor_status ? itm.lcor_status : '-';
    }
    if (type === 'lcor' && !adminType.includes('lcor_access')) {
      return itm.lcor_status ? itm.lcor_status : '-';
    }
    if (type === 'cfl' && adminType.includes('cfl_access')) {
      if (cflStatusArray.includes(itm.pi_id)) {
        const temp = cflObjArray.find((item) => item.pi_id === itm.pi_id);
        if (temp?.status) {
          return temp.status;
        }
      }
      return itm.status ? itm.status : '-';
    }
    if (type === 'cfl' && !adminType.includes('cfl_access')) {
      return itm.status ? itm.status : '-';
    }
    if (type === 'sponsor' && adminType.includes('sponsor_access')) {
      if (cflStatusArray.includes(itm.pi_id)) {
        const temp = cflObjArray.find((item) => item.pi_id === itm.pi_id);
        if (temp?.status) {
          return temp.status;
        }
      }
      return itm.sponsor_status ? itm.sponsor_status : '-';
    }
    if (type === 'sponsor' && !adminType.includes('sponsor_access')) {
      return itm.sponsor_status ? itm.sponsor_status : '-';
    }
    return undefined;
  };
  const getPriority = (itm) => {
    if (cflStatusArray.includes(itm.pi_id)) {
      const temp = cflObjArray.find((item) => item.pi_id === itm.pi_id);
      if (temp.priority >= 0) {
        return temp.priority;
      }
    }
    return itm.priority;
  };

  const getParty = (itm) => {
    if (cflStatusArray.includes(itm.pi_id)) {
      const temp = cflObjArray.find((item) => item.pi_id === itm.pi_id);
      if (temp?.party) {
        return temp.party;
      }
    }
    return itm.originating_party ? itm.originating_party : '-';
  };

  const getVersion = (itm) => {
    if (cflStatusArray.includes(itm.pi_id)) {
      const temp = cflObjArray.find((item) => item.pi_id === itm.pi_id);
      if (temp?.version) {
        return temp.version;
      }
    }
    return itm.version ? itm.version : '-';
  };

  const getOrigin = (itm) => {
    if (cflStatusArray.includes(itm.pi_id)) {
      const temp = cflObjArray.find((item) => item.pi_id === itm.pi_id);
      if (temp?.party && temp?.priority) {
        return `${temp?.priority}-${temp?.party}`;
      }
      if (temp?.party && !temp?.priority) {
        return `${itm?.priority}-${temp?.party}`;
      }
      if (!temp?.party && temp?.priority) {
        return `${temp?.priority}-${itm?.originating_party}`;
      }
    }
    return itm.origin ? itm.origin : '-';
  };

  const handleApplyFieldFilterClicked = () => {
    setAppliedFilters({
      ...appliedFilters,
      status: cflStatusSelected,
      lcor_status: lcorStatusSelected,
      sponsor_status: sponsorStatusSelected,
    });
    dispatch(
      getSavedProjectAction({
        project_id: parse(location.search).project_id,
        from: 1,
        size: sizeVal,
        filters: JSON.stringify({
          ...appliedFilters,
          status: cflStatusSelected,
          lcor_status: lcorStatusSelected,
          sponsor_status: sponsorStatusSelected,
        }),
        hierarchy,
        order_by: JSON.stringify(sortObj),
        query: searchPIInput,
        is_previous_results: parse(props.location.search).is_previous_results,
        center_query: searchCenterInput,
      }),
    );
    dispatch(
      getSavedProjectCountsAction({
        project_id: projectID,
        is_previous_results: parse(props.location.search).is_previous_results,
      }),
    );
  };

  const handleClearFieldFilterClicked = (type) => {
    if (type === 'Cfl') {
      setAppliedFilters({
        ...appliedFilters,
        status: type === [],
        lcor_status: lcorStatusSelected,
        sponsor_status: sponsorStatusSelected,
      });
      dispatch(
        getSavedProjectAction({
          project_id: parse(location.search).project_id,
          from: 1,
          size: sizeVal,
          filters: JSON.stringify({
            ...appliedFilters,
            status: [],
            lcor_status: lcorStatusSelected,
            sponsor_status: sponsorStatusSelected,
          }),
          hierarchy,
          order_by: JSON.stringify(sortObj),
          query: searchPIInput,
          center_query: searchCenterInput,
        }),
      );
      dispatch(
        getSavedProjectCountsAction({
          project_id: projectID,
          is_previous_results: parse(props.location.search).is_previous_results,
        }),
      );
    } else if (type === 'Lcor') {
      setAppliedFilters({
        ...appliedFilters,
        status: type === cflStatusSelected,
        lcor_status: type === [],
      });
      dispatch(
        getSavedProjectAction({
          project_id: parse(location.search).project_id,
          from: 1,
          size: sizeVal,
          filters: JSON.stringify({
            ...appliedFilters,
            status: cflStatusSelected,
            lcor_status: [],
            sponsor_status: sponsorStatusSelected,
          }),
          hierarchy,
          order_by: JSON.stringify(sortObj),
          query: searchPIInput,
          is_previous_results: parse(props.location.search).is_previous_results,
          center_query: searchCenterInput,
        }),
      );
      dispatch(
        getSavedProjectCountsAction({
          project_id: projectID,
          is_previous_results: parse(props.location.search).is_previous_results,
        }),
      );
    } else if (type === 'sponsor') {
      setAppliedFilters({
        ...appliedFilters,
        status: type === cflStatusSelected,
        sponsor_status: type === [],
      });
      dispatch(
        getSavedProjectAction({
          project_id: parse(location.search).project_id,
          from: 1,
          size: sizeVal,
          filters: JSON.stringify({
            ...appliedFilters,
            status: cflStatusSelected,
            lcor_status: lcorStatusSelected,
            sponsor_status: [],
          }),
          hierarchy,
          order_by: JSON.stringify(sortObj),
          query: searchPIInput,
          is_previous_results: parse(props.location.search).is_previous_results,
          center_query: searchCenterInput,
        }),
      );
      dispatch(
        getSavedProjectCountsAction({
          project_id: projectID,
          is_previous_results: parse(props.location.search).is_previous_results,
        }),
      );
    }
  };

  const handlePaginationChange = (page) => {
    setCurrentPageNumber(page.selected);
    setCountryClose(false);
  };

  const getData = (record) => {
    const orderedHierarchy = reOrderHierarchy(hierarchy);
    let currentTotal = 0;
    let primary_organizations = [];
    let pis = [];
    let parent_institutes = [];
    const page = [];
    switch (orderedHierarchy) {
      case 'parent_institutes,primary_organizations,pis':
        for (let j = 0; j < record.length; j += 1) {
          for (let i = 0; i < record[j]?.primary_organizations?.length; i += 1) {
            currentTotal += record[j]?.primary_organizations[i]?.pis.length;
          }
          if (currentTotal < 250) {
            parent_institutes.push(record[j]);
          } else {
            parent_institutes.push(record[j]);
            page.push(parent_institutes);
            parent_institutes = [];
            currentTotal = 0;
          }
        }
        if (parent_institutes.length) {
          page.push(parent_institutes);
        }
        setPages(page);
        setTotalPageCount(page.length);
        return undefined;
      case 'country,primary_organizations,pis':
        for (let j = 0; j < record.length; j += 1) {
          for (let i = 0; i < record[j]?.primary_organizations?.length; i += 1) {
            currentTotal += record[j]?.primary_organizations[i]?.pis.length;
            if (currentTotal < 250) {
              primary_organizations.push(record[j].primary_organizations[i]);
            } else {
              primary_organizations.push(record[j].primary_organizations[i]);
              page.push([{ country: record[j].country, primary_organizations }]);
              primary_organizations = [];
              currentTotal = 0;
            }
          }
          if (primary_organizations.length !== 0) {
            page.push([{ country: record[j].country, primary_organizations }]);
            primary_organizations = [];
            currentTotal = 0;
          }
        }
        setPages(page);
        setTotalPageCount(page.length);
        return undefined;
      case 'country,parent_institutes,primary_organizations,pis':
        for (let k = 0; k < record.length; k += 1) {
          for (let j = 0; j < record[k].parent_institutes.length; j += 1) {
            for (
              let i = 0;
              i < record[k].parent_institutes[j]?.primary_organizations?.length;
              i += 1
            ) {
              currentTotal += record[k].parent_institutes[j]?.primary_organizations[i]?.pis.length;
            }
            if (currentTotal < 250) {
              parent_institutes.push(record[k].parent_institutes[j]);
            } else {
              parent_institutes.push(record[k].parent_institutes[j]);
              page.push([{ country: record[k].country, parent_institutes }]);
              parent_institutes = [];
              currentTotal = 0;
            }
          }
          if (parent_institutes.length) {
            page.push([{ country: record[k].country, parent_institutes }]);
            parent_institutes = [];
            currentTotal = 0;
          }
        }
        setPages(page);
        setTotalPageCount(page.length);
        return undefined;
      case 'country,pis':
        for (let i = 0; i < record.length; i += 1) {
          for (let j = 0; j < record[i].pis.length; j += 1) {
            pis.push(record[i].pis[j]);
            currentTotal += 1;
            if (currentTotal === 250) {
              page.push([{ country: record[i].country, pis }]);
              pis = [];
              currentTotal = 0;
            }
          }
          if (pis.length !== 0) {
            page.push([{ country: record[i].country, pis }]);
            pis = [];
            currentTotal = 0;
          }
        }
        setPages(page);
        setTotalPageCount(page.length);
        return undefined;
      case 'primary_organizations,pis':
        for (let i = 0; i < record?.length; i += 1) {
          currentTotal += record[i]?.pis.length;
          if (currentTotal < 250) {
            primary_organizations.push(record[i]);
          } else {
            primary_organizations.push(record[i]);
            page.push(primary_organizations);
            primary_organizations = [];
            currentTotal = 0;
          }
        }
        if (primary_organizations.length !== 0) {
          page.push(primary_organizations);
        }
        setPages(page);
        setTotalPageCount(page.length);
        return undefined;
      case 'pis':
        for (let j = 0; j < record.length; j += 1) {
          pis.push(record[j]);
          currentTotal += 1;
          if (currentTotal === 250) {
            page.push(pis);
            pis = [];
            currentTotal = 0;
          }
        }
        if (pis.length) {
          page.push(pis);
        }
        setPages(page);
        setTotalPageCount(page.length);
        return undefined;
      default:
        return undefined;
    }
  };

  const renderTooltipTitleForAmerican = (value) => {
    if (value === 'High') {
      return 'Upper quartiles for American Indian Amd Alaskan Native alone';
    }
    if (value === 'Low') {
      return 'Lower quartiles for American Indian Amd Alaskan Native alone';
    }
    if (value === 'Medium') {
      return 'Middle quartiles for American Indian Amd Alaskan Native alone';
    }
    return '';
  };

  const renderTooltipTitleForHawaiian = (value) => {
    if (value === 'High') {
      return 'Upper quartiles for Native Hawaiian And Other Pacific Islander alone';
    }
    if (value === 'Low') {
      return 'Lower quartiles for Native Hawaiian And Other Pacific Islander alone';
    }
    if (value === 'Medium') {
      return 'Middle quartiles for Native Hawaiian And Other Pacific Islander alone';
    }
    return '';
  };

  const onPieChartValue = () => {
    if (Object.keys(pieChartData).length) {
      const dataForPieChart = Object.keys(pieChartData)
        .filter((key) => key === tabName)
        .reduce((obj, key) => {
          obj[key] = pieChartData[key];
          return obj;
        }, {});
      return (
        <PieChart
          data={dataForPieChart[tabName]}
          outerRadius={70}
          innerRadius={35}
          type="SITE"
          idName="pi"
          flag={false}
        />
      );
    }
    return '';
  };

  const renderProjectRecords = (record) => {
    const orderedHierarchy = reOrderHierarchy(hierarchy);
    switch (orderedHierarchy) {
      case 'primary_organizations,pis': {
        return (
          <div className="run-query-content-section">
            <div className="run-query-content-card card-country-content card">
              <div className="card-content card-country-content-header">
                <div className="body-analysis-section level-header-section">
                  <div className="leftside-column">
                    <div className="fav" />
                    <a
                      className="card-content-title"
                      title={record.site_name}
                      target="_blank"
                      href={`/search/deepdives?${stringify({
                        query: record.site_name,
                        id: record.site_id,
                        type: 'SITE',
                        currentTab: record.parent_flag ? 'parent_centers' : 'centers',
                      })}`}
                      rel="noreferrer noopener"
                    >
                      {record.site_name}
                    </a>
                    <div className="comments">
                      <Comment
                        projectId={parse(location.search).project_id}
                        analysisId={parse(location.search).analysis_id}
                        siteId={record.site_id}
                        type="site"
                        sitePIExtraType="specific"
                        count={JSON.stringify(record.comments)}
                      />
                    </div>
                    <div className="population-flag">
                      <Tooltip
                        title={renderTooltipTitleForAmerican(
                          record.american_indian_origin_category,
                        )}
                        // title={record.american_indian_origin_category === 'High' ? 'High quartiles for American Indian Amd Alaskan Native alone' : record.american_indian_origin_category === 'Low' ? 'Low Quartiles For American Indian Amd Alaskan Native alone' : record.american_indian_origin_category === 'Medium' ? 'Medium Quartiles For American Indian Amd Alaskan Native alone' : ''}
                      >
                        {record.american_indian_origin_category === 'High' ? (
                          <span className="High-population-icon-for-Alaska" />
                        ) : record.american_indian_origin_category === 'Low' ? (
                          <span className="Low-population-icon-for-Alaska" />
                        ) : record.american_indian_origin_category === 'Medium' ? (
                          <span className="Medium-population-icon-for-Alaska" />
                        ) : (
                          ''
                        )}
                      </Tooltip>
                      <Tooltip
                        title={renderTooltipTitleForHawaiian(
                          record.native_hawaiian_origin_category,
                        )}
                      >
                        {record.native_hawaiian_origin_category === 'High' ? (
                          <span className="High-population-icon-for-Hawaii" />
                        ) : record.native_hawaiian_origin_category === 'Low' ? (
                          <span className="Low-population-icon-for-Hawaii" />
                        ) : record.native_hawaiian_origin_category === 'Medium' ? (
                          <span className="Medium-population-icon-for-Hawaii" />
                        ) : (
                          ''
                        )}
                      </Tooltip>
                    </div>
                  </div>
                  <div className="table-column">
                    <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                      {checkColumnDisplay('Feasibility Status') ? (
                        <div className="filter-sel-data-value status">
                          {record.status ? record.status : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Feasibility Status Date') ? (
                        <div className="filter-sel-data-value center">
                          {record.last_cfl_status_updated_on
                            ? getFormattedDate(record.last_cfl_status_updated_on * 1000)
                            : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('LCOR Status') ? (
                        <div className="filter-sel-data-value status">
                          {record.lcor_status ? record.lcor_status : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Sponsor Status') ? (
                        <div className="filter-sel-data-value status">
                          {record.sponsor_status ? record.sponsor_status : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('LCOR Status Date') ? (
                        <div className="filter-sel-data-value center">
                          {record.last_lcor_status_updated_on
                            ? getFormattedDate(record.last_lcor_status_updated_on * 1000)
                            : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Analysis ID') ? (
                        <div className="filter-sel-data-value analysis-id">
                          {record.analysis_seq_id ? record.analysis_seq_id : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Priority') ? (
                        <div className="filter-sel-data-value priority">-</div>
                      ) : null}
                      {checkColumnDisplay('Origin') ? (
                        <div className="filter-sel-data-value origin">-</div>
                      ) : null}
                      {checkColumnDisplay('Originating Party') ? (
                        <div className="filter-sel-data-value party">-</div>
                      ) : null}
                      {checkColumnDisplay('Version') ? (
                        <div className="filter-sel-data-value priority">-</div>
                      ) : null}
                      {checkColumnDisplay('Alliance Member Name') ? (
                        <div className="filter-sel-data-value party">-</div>
                      ) : null}
                      {checkColumnDisplay('Date Added') ? (
                        <div className="filter-sel-data-value party">-</div>
                      ) : null}
                      {checkColumnDisplay('Alliance Contact') ? (
                        <div className="filter-sel-data-value party">-</div>
                      ) : null}
                      {checkColumnDisplay('Parexel Contact') ? (
                        <div className="filter-sel-data-value party">-</div>
                      ) : null}
                      {checkColumnDisplay('LCOR Comment') ? (
                        <div className="filter-sel-data-value status">
                          {record.lcor_comment ? record.lcor_comment : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('CDA Expiry') ? (
                        <div className="filter-sel-data-value expiry">-</div>
                      ) : null}
                      {checkColumnDisplay('Query ID') ? (
                        <div className="filter-sel-data-value query-id">
                          {record.query_seq_id ? record.query_seq_id : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('# Industry Studies') ? (
                        <div className="filter-sel-data-value industry-studies">
                          {record.industry_studies}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Competing Studies') ? (
                        <div className="filter-sel-data-value center">
                          {record.competing_studies}
                        </div>
                      ) : null}
                      {checkColumnDisplay('# PXL Studies') ? (
                        <div className="filter-sel-data-value pxl-studies">
                          {record.pxl_studies}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Tier (Search Specific)') ? (
                        <Button
                          className="filter-sel-data-value pxl-studies"
                          onClick={() => onTierClick(record)}
                        >
                          {record.tier}
                        </Button>
                      ) : null}
                      {checkColumnDisplay('Profile Tier(General)') ? (
                        <Button
                          className="filter-sel-data-value pxl-studies"
                          onClick={() => onProfileTierClick(record)}
                        >
                          {record.p_tier}
                        </Button>
                      ) : null}
                      {checkColumnDisplay('Specialities') ? (
                        <div className="filter-sel-data-value pxl-studies">
                          {record?.specialties?.length ? record.specialties.join(', ') : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('VAT Invoice Present') ? (
                        <div className="filter-sel-data-value industry-studies">
                          {record.vat_invoice}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Enrolled Subjects') ? (
                        <div className="filter-sel-data-value enrolled-subjects">
                          {record.enrolled_count}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Quality') ? (
                        <div className="filter-sel-data-value quality">
                          <Rate count={3} disabled value={record.quality_rating} />
                        </div>
                      ) : null}
                      {checkColumnDisplay('Reliability') ? (
                        <div className="filter-sel-data-value enrolled-subjects">
                          {record.reliability_class}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Reliability Score') ? (
                        <Button
                          className="filter-sel-data-value pxl-studies"
                          onClick={() => onReliabilityClick(record)}
                        >
                          {record.reliability_score}
                        </Button>
                      ) : null}

                      {checkColumnDisplay('KOL Score') ? (
                        <div className="filter-sel-data-value center">Not applicable</div>
                      ) : null}
                      {checkColumnDisplay('Start Up Months') ? (
                        <div className="filter-sel-data-value center">
                          {record.p_start_up ? record.p_start_up.toFixed(2) : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Start Up Index') ? (
                        <div className="filter-sel-data-value center">
                          {record.startup_index ? record.startup_index.toFixed(2) : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Recruitment Factor') ? (
                        <div className="filter-sel-data-value center">
                          {record.p_recruitment ? record.p_recruitment.toFixed(2) : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Last Trial Start Date') ? (
                        <div className="filter-sel-data-value center">Not applicable</div>
                      ) : null}
                      {checkColumnDisplay('Center') ? (
                        <div className="filter-sel-data-value center">{record.site_name}</div>
                      ) : null}
                      {checkColumnDisplay('City') ? (
                        <div className="filter-sel-data-value center">
                          {record?.city !== '' ? record?.city : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('State') ? (
                        <div className="filter-sel-data-value center">
                          {record?.state !== '' ? record?.state : '-'}
                        </div>
                      ) : null}
                      {checkColumnDisplay('Country') ? (
                        <div className="filter-sel-data-value center">{record.country}</div>
                      ) : null}
                      {checkColumnDisplay('Alliance Type') ? (
                        <div className="filter-sel-data-value center">Not applicable</div>
                      ) : null}
                      {checkColumnDisplay('Networks') ? (
                        <div className="filter-sel-data-value center">
                          {record.networks && record.networks.length
                            ? record.networks.join('; ')
                            : '-'}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="lastside-column">
                    {parse(location.search).compareSiteFlag || IsSiteComparisionClicked ? (
                      <div className="compare-checkbox">
                        <div className="checkbox-content">
                          <Checkbox
                            className={checkDefaultChecked(record.site_id) ? 'check' : 'uncheck'}
                            onChange={(e) => onCheckboxChange(e, record.site_id)}
                            checked={checkDefaultChecked(record.site_id)}
                          />
                        </div>
                      </div>
                    ) : (
                      <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                        <Button
                          className="accordion-menu"
                          role="presentation"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <Tooltip title="Kebab menu">
                            <div className="accordion-menu-icon" />
                          </Tooltip>
                        </Button>
                      </Dropdown>
                    )}
                  </div>
                </div>
              </div>
              <div className="pi-name-content">
                <ReadMore limit={5}>
                  {record.pis.map((itm, idx) => {
                    return (
                      <div className="pi-content">
                        <div className="body-analysis-section">
                          <div className="leftside-column">
                            <div className="checkbox-filter">
                              <Checkbox
                                checked={
                                  checkedPiList.includes(itm.innoplexus_id) || isSelectAllChecked
                                }
                                onChange={() => handlePiCheckbox(
                                  itm.innoplexus_id,
                                  itm.status,
                                  itm.pi_id,
                                  itm.originating_party,
                                  itm.compliance_issue_prevention_flag,
                                  itm.is_added,
                                  itm.is_added_by_sponsor,
                                )}
                              />
                            </div>
                            <div className="meta-link" />
                            <a
                              className="pi-content-title"
                              title={itm.pi_name}
                              target="_blank"
                              href={`/search/investigatorprofile?${stringify({
                                query: itm.pi_name,
                                id: itm.pi_id,
                                type: 'PI',
                              })}`}
                              rel="noreferrer noopener"
                            >
                              {itm.pi_name}
                            </a>
                            {itm.is_new ? (
                              <div className="new-label">
                                <div
                                  className="new-label-icon"
                                  title="This investigator was not part of the results earlier"
                                />
                              </div>
                            ) : null}
                            <div className="comments">
                              <Comment
                                projectId={parse(location.search).project_id}
                                analysisId={parse(location.search).analysis_id}
                                piId={itm.pi_id}
                                type="pi"
                                sitePIExtraType="specific"
                                count={JSON.stringify(itm.comments)}
                                is_lcor={adminType.includes('lcor_access')}
                              />
                            </div>
                            {itm.compliance && itm.compliance !== 'No Issues' ? (
                              <div
                                className={`${
                                  itm.compliance === 'Compliance preventing participation'
                                  || itm.compliance === 'DNU'
                                    ? 'flag filled red'
                                    : itm.compliance
                                        === 'Compliance not preventing participation'
                                      || itm.compliance === 'Approved with Warning'
                                      ? 'flag filled light-red'
                                      : ''
                                }`}
                              >
                                <Popover
                                  overlayClassName="flag-section-content flag-section-content-compliance"
                                  placement="right"
                                  title={(
                                    <div
                                      className={`${
                                        itm.compliance === 'Compliance preventing participation'
                                        || itm.compliance === 'DNU'
                                          ? 'flag-title filled red'
                                          : itm.compliance
                                              === 'Compliance not preventing participation'
                                            || itm.compliance === 'Approved with Warning'
                                            ? 'flag-title filled light-red'
                                            : ''
                                      }`}
                                    >
                                      GCP Compliance & CDD
                                    </div>
                                  )}
                                  content={(
                                    <div className="flag-data">
                                      <div className="flag-data-title-create">
                                        <div className="flag-data-content-value">
                                          <>
                                            {itm?.cdd_data?.length
                                              ? itm?.cdd_data?.map((item) => (
                                                <>
                                                  <div style={{ 'white-space': 'pre-line' }}>
                                                    CDD Status : {item.status}
                                                  </div>
                                                  <br />
                                                  <div style={{ 'white-space': 'pre-line' }}>
                                                    CDD Date: {item.date ? item.date : '-'}
                                                  </div>
                                                  <br />
                                                  <div style={{ 'white-space': 'pre-line' }}>
                                                    CDD Comment:{' '}
                                                    {item.comment ? item.comment : '-'}
                                                  </div>
                                                  <br />
                                                  <hr />
                                                </>
                                              ))
                                              : undefined}
                                            {itm?.compliances?.length
                                              && itm?.compliances?.map((itm1) => {
                                                return (
                                                  <>
                                                    <div style={{ 'white-space': 'pre-line' }}>
                                                      Compliance Preventing Participation :{' '}
                                                      {itm1.no_trial_participation_flag === 'N'
                                                        ? 'No'
                                                        : itm1.no_trial_participation_flag === 'Y'
                                                          ? 'Yes'
                                                          : itm1.no_trial_participation_flag === 'NO'
                                                            ? 'No'
                                                            : itm1.no_trial_participation_flag === 'YES'
                                                              ? 'Yes'
                                                              : itm1.no_trial_participation_flag}
                                                    </div>
                                                    <br />
                                                    <div style={{ 'white-space': 'pre-line' }}>
                                                      Compliance Issue Comment:{' '}
                                                      {itm1.compliance_issue_comment
                                                        ? itm1.compliance_issue_comment
                                                        : '-'}
                                                    </div>
                                                    <br />
                                                    <div style={{ 'white-space': 'pre-line' }}>
                                                      Compliance Issue Description:{' '}
                                                      {itm1.compliance_issue_desc
                                                        ? itm1.compliance_issue_desc
                                                        : '-'}
                                                    </div>
                                                    <br />
                                                    <div style={{ 'white-space': 'pre-line' }}>
                                                      Compliance Issue Raised:{' '}
                                                      {itm1.compliance_issue_raised_dt
                                                      && itm1.compliance_issue_raised_dt.length
                                                        ? itm1.compliance_issue_raised_dt.split(
                                                          ' ',
                                                        )[0]
                                                        : '-'}
                                                    </div>
                                                    <br />
                                                    <div style={{ 'white-space': 'pre-line' }}>
                                                      Reviewed Status:{' '}
                                                      {itm1.review_status
                                                        ? itm1.review_status
                                                        : '-'}
                                                    </div>
                                                    <br />
                                                    <div style={{ 'white-space': 'pre-line' }}>
                                                      Review Date:{' '}
                                                      {itm1.review_date && itm1.review_date.length
                                                        ? itm1.review_date.split(' ')[0]
                                                        : '-'}
                                                    </div>
                                                    <br />
                                                    <div style={{ 'white-space': 'pre-line' }}>
                                                      Reviewer Name:{' '}
                                                      {itm1.reviewer_name
                                                        ? itm1.reviewer_name
                                                        : '-'}
                                                    </div>
                                                    <br />
                                                    <div style={{ 'white-space': 'pre-line' }}>
                                                      Re-evaluation Comment:{' '}
                                                      {itm1.reevaluation_comment
                                                        ? itm1.reevaluation_comment
                                                        : '-'}
                                                    </div>
                                                    <hr />
                                                  </>
                                                );
                                              })}
                                          </>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  trigger="click"
                                >
                                  <div className="flag-img" />
                                </Popover>
                              </div>
                            ) : null}
                            {itm.emerging_kol.length ? (
                              <Tooltip
                                title={(
                                  <div className="tooltip-title">
                                    <div>Emerging Category: {itm.emerging_data.flag === 'Medium' ? 'Moderate'
                                      : itm.emerging_data.flag === 'High' ? 'High'
                                        : itm.emerging_data.flag === 'Low' ? 'Low' : ''}
                                    </div>
                                    <div className="tooltip-theraprutic-area">
                                      Therapy Area : {itm.emerging_data.therapeutic_area}
                                    </div>
                                    <div className="tooltip-score">
                                      Score : {itm.emerging_data.score}
                                    </div>
                                  </div>
                                )}
                              >
                                <div
                                  className={`${
                                    itm.emerging_data.flag === 'Medium'
                                      ? 'project-result Medium-flag'
                                      : itm.emerging_data.flag === 'Low'
                                        ? 'project-result Low-flag'
                                        : itm.emerging_data.flag === 'High'
                                          ? 'project-result High-flag'
                                          : ''
                                  }`}
                                >
                                  <Popover
                                    overlayClassName="flag-section-content flag-section-content-project-result"
                                    placement="right"
                                    title={(
                                      <div
                                        className={`${
                                          itm.emerging_data.flag === 'Medium'
                                            ? 'project-result Medium-flag'
                                            : itm.emerging_data.flag === 'Low'
                                              ? 'project-result Low-flag'
                                              : itm.emerging_data.flag === 'High'
                                                ? 'project-result High-flag'
                                                : ''
                                        }`}
                                      >
                                        TA wise Emerging KOL Score
                                      </div>
                                    )}
                                    content={(
                                      <>
                                        <div className="emerging-investigator-title">
                                          <div className="emerging-investigator-ta">Primary TA : &nbsp;</div>
                                          {Object.keys(itm.emerging_data).length ? (<div className="emerging-investigator-"> {itm.emerging_data.therapeutic_area}</div>) : ''}
                                        </div>

                                        <div className="emerging-investigator-right">
                                          <div
                                            className="emerging-investigator-header"
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-around',
                                            }}
                                          >
                                            <div className="emerging-investigator-header-th theraprutic-area">
                                              {' '}
                                              Therapy Area{' '}
                                            </div>
                                            <div className="emerging-investigator-header-th">
                                              {' '}
                                              Score{' '}
                                            </div>
                                            <div className="emerging-investigator-header-th">
                                              {' '}
                                              Emerging KOL Class{' '}
                                            </div>
                                          </div>
                                          <div className="emerging-investigator-body">
                                            {itm.emerging_kol?.length
                                              ? itm.emerging_kol?.map((itm1) => (
                                                <>
                                                  <div
                                                    className="emerging-investigator-header-tr"
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent: 'space-around',
                                                    }}
                                                  >
                                                    <div
                                                      className="emerging-investigator-header-td therapeutic-area"
                                                      style={{ display: 'flex' }}
                                                    >
                                                      <div className="emerging-investigator-header-td ta-value">
                                                        {itm1.therapeutic_area}
                                                      </div>
                                                    </div>
                                                    <div className="emerging-investigator-header-td score">
                                                      {itm1.score}
                                                    </div>
                                                    <div className="emerging-investigator-header-td">
                                                      <div className="emerging-investigator-flag">
                                                        {itm1.flag === 'Medium' ? (
                                                          <span
                                                            className="Medium-flag"
                                                            title="Moderate"
                                                          />
                                                        ) : itm1.flag === 'Low' ? (
                                                          <span className="Low-flag" title="Low" />
                                                        ) : itm1.flag === 'High' ? (
                                                          <span
                                                            className="High-flag"
                                                            title="High"
                                                          />
                                                        ) : (
                                                          ''
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ))
                                              : ''}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    trigger="click"
                                  >
                                    <div className="flag-img" />
                                  </Popover>
                                </div>
                              </Tooltip>
                            ) : null}
                            <div
                              className={
                                itm.alliancePresent
                                  ? 'primary-org-change-alliance'
                                  : 'primary-org-change'
                              }
                            >
                              <Button
                                onClick={() => setOrgChangeModalOpen({
                                  isOpen: true,
                                  piId: itm.pi_id,
                                  siteId: itm.site_id,
                                  isAlliance: itm.alliancePresent,
                                })}
                              />
                            </div>
                          </div>
                          <div className="table-column">
                            <div
                              className="table-column-sec table-column-body"
                              onScroll={onRowScroll}
                            >
                              {checkColumnDisplay('Feasibility Status') ? (
                                <div className="filter-sel-data-value status">
                                  {getCflStatus(itm, 'cfl')}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Feasibility Status Date') ? (
                                <div className="filter-sel-data-value center">
                                  {itm.last_cfl_status_updated_on
                                    ? getFormattedDate(itm.last_cfl_status_updated_on * 1000)
                                    : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('LCOR Status') ? (
                                <div className="filter-sel-data-value status">
                                  {getCflStatus(itm, 'lcor')}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Sponsor Status') ? (
                                <div className="filter-sel-data-value status">
                                  {getCflStatus(itm, 'sponsor')}
                                </div>
                              ) : null}
                              {checkColumnDisplay('LCOR Status Date') ? (
                                <div className="filter-sel-data-value center">
                                  {itm.last_lcor_status_updated_on
                                    ? getFormattedDate(itm.last_lcor_status_updated_on * 1000)
                                    : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Analysis ID') ? (
                                <div className="filter-sel-data-value analysis-id">
                                  {itm.analysis_seq_id ? itm.analysis_seq_id : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Priority') ? (
                                <div className="filter-sel-data-value priority">
                                  <Select
                                    onChange={(e) => handlePriorityForSingle(
                                      itm.innoplexus_id,
                                      e,
                                      itm.originating_party,
                                      itm.pi_id,
                                    )}
                                    // defaultValue={itm.priority ? itm.priority : null}
                                    value={getPriority(itm)}
                                    options={priorityList}
                                    disabled={
                                      isCheckedboxEnabled
                                      || !(
                                        adminType.includes('lcor_access')
                                        || adminType.includes('cfl_access')
                                      )
                                      || isSelectAllChecked
                                    }
                                  />
                                </div>
                              ) : null}
                              {checkColumnDisplay('Origin') ? (
                                <div className="filter-sel-data-value origin">{getOrigin(itm)}</div>
                              ) : null}
                              {checkColumnDisplay('Originating Party') ? (
                                <div className="filter-sel-data-value party">
                                  <div className="filter-sel-data-value priority">
                                    <Select
                                      onChange={(e) => handleOriginForSingle(itm.innoplexus_id, e, itm.pi_id)}
                                      // defaultValue={itm.originating_party ? itm.originating_party : null}
                                      value={getParty(itm)}
                                      options={originatingParty}
                                      disabled={
                                        isCheckedboxEnabled
                                        || !(
                                          adminType.includes('lcor_access')
                                          || adminType.includes('cfl_access')
                                        )
                                        || isSelectAllChecked
                                      }
                                      style={{ width: '120px' }}
                                    />
                                  </div>
                                </div>
                              ) : null}
                              {checkColumnDisplay('Version') ? (
                                <div className="filter-sel-data-value priority">
                                  <Select
                                    onChange={(e) => handleVersionForSingle(itm.innoplexus_id, e, itm.pi_id)}
                                    // defaultValue={itm.originating_party ? itm.originating_party : null}
                                    value={getVersion(itm)}
                                    options={versionList}
                                    disabled={
                                      isCheckedboxEnabled
                                      || !(
                                        adminType.includes('lcor_access')
                                        || adminType.includes('cfl_access')
                                      )
                                      || isSelectAllChecked
                                    }
                                  />
                                </div>
                              ) : null}
                              {checkColumnDisplay('Alliance Member Name') ? (
                                <div className="filter-sel-data-value status">
                                  {itm.alliance_member_name ? itm.alliance_member_name : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Date Added') ? (
                                <div className="filter-sel-data-value status">
                                  {itm.date_added ? getFormattedDate(itm.date_added * 1000) : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Alliance Contact') ? (
                                <div className="filter-sel-data-value status">
                                  {itm.alliance_contact?.full_name
                                    ? itm.alliance_contact?.full_name
                                    : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Parexel Contact') ? (
                                <div className="filter-sel-data-value status">
                                  {itm.parexel_contact?.full_name
                                    ? itm.parexel_contact?.full_name
                                    : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('LCOR Comment') ? (
                                <div className="filter-sel-data-value status">
                                  {itm.lcor_comment ? itm.lcor_comment : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('CDA Expiry') ? (
                                <div className="filter-sel-data-value expiry">
                                  {itm.cda_expiry ? itm.cda_expiry : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Query ID') ? (
                                <div className="filter-sel-data-value query-id">
                                  {itm.query_seq_id ? itm.query_seq_id : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('# Industry Studies') ? (
                                <Button
                                  className="filter-sel-data-value industry-studies"
                                  onClick={(e) => onIndustryClick(
                                    e,
                                    itm.pi_id,
                                    itm.industry_studies_source,
                                    itm.analysis_id,
                                  )}
                                >
                                  {itm.industry_studies}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('Competing Studies') ? (
                                <Button
                                  className="filter-sel-data-value center"
                                  onClick={() => onCompetingClick(itm.pi_id, itm.analysis_id)}
                                >
                                  {itm.competing_studies}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('# PXL Studies') ? (
                                <Button
                                  className="filter-sel-data-value pxl-studies"
                                  onClick={() => onPxlClick(itm.pi_id, itm.analysis_id)}
                                >
                                  {itm.pxl_studies}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('Tier (Search Specific)') ? (
                                <Button
                                  className="filter-sel-data-value pxl-studies"
                                  onClick={() => onTierClick(itm)}
                                >
                                  {itm.tier}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('Profile Tier(General)') ? (
                                <Button
                                  className="filter-sel-data-value pxl-studies"
                                  onClick={() => onProfileTierClick(itm)}
                                >
                                  {itm.p_tier}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('Specialities') ? (
                                <div className="filter-sel-data-value pxl-studies">
                                  {itm?.specialties?.length ? itm.specialties.join(', ') : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('VAT Invoice Present') ? (
                                <div className="filter-sel-data-value industry-studies">
                                  {itm.vat_invoice}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Enrolled Subjects') ? (
                                <div className="filter-sel-data-value enrolled-subjects">
                                  {itm.enrolled_count}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Quality') ? (
                                <div className="filter-sel-data-value quality">
                                  <Rate count={3} disabled value={itm.quality_rating} />
                                </div>
                              ) : null}
                              {checkColumnDisplay('Reliability') ? (
                                <div className="filter-sel-data-value enrolled-subjects">
                                  {itm.reliability_class}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Reliability Score') ? (
                                <Button
                                  className="filter-sel-data-value pxl-studies"
                                  onClick={() => onReliabilityClick(itm)}
                                >
                                  {itm.reliability_score}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('KOL Score') ? (
                                <Button className="filter-sel-data-value pxl-studies" onClick={() => onKolScoreClick(itm)}>{itm?.cls_kol_score?.toFixed(3) }</Button>
                                // <div className="filter-sel-data-value center">
                                //   {itm.cls_kol_score}
                                // </div>
                              ) : null}
                              {checkColumnDisplay('Start Up Months') ? (
                                <div className="filter-sel-data-value center">
                                  {itm.p_start_up ? record.p_start_up.toFixed(2) : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Start Up Index') ? (
                                <div className="filter-sel-data-value center">
                                  {itm.startup_index ? itm.startup_index.toFixed(2) : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Recruitment Factor') ? (
                                <div className="filter-sel-data-value center">
                                  {itm.p_recruitment ? itm.p_recruitment.toFixed(2) : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Last Trial Start Date') ? (
                                <div className="filter-sel-data-value center">
                                  {itm.recent_trial_start_date_ts
                                    ? getFormattedDate(itm.recent_trial_start_date_ts * 1000)
                                    : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Center') ? (
                                <div className="filter-sel-data-value center">{itm.site_name}</div>
                              ) : null}
                              {checkColumnDisplay('City') ? (
                                <div className="filter-sel-data-value center">
                                  {itm?.city !== '' ? itm?.city : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('State') ? (
                                <div className="filter-sel-data-value center">
                                  {itm?.state !== '' ? itm?.state : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Country') ? (
                                <div className="filter-sel-data-value center">{itm.country}</div>
                              ) : null}
                              {checkColumnDisplay('Alliance Type') ? (
                                <div className="filter-sel-data-value center">
                                  {itm.alliance_type}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Networks') ? (
                                <div className="filter-sel-data-value center">
                                  {itm.networks && itm.networks.length
                                    ? itm.networks.join('; ')
                                    : '-'}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="lastside-column">
                            {parse(location.search).compareFlag || IsComparisionClicked ? (
                              <div className="compare-checkbox">
                                <div className="checkbox-content">
                                  <Checkbox
                                    className={checkDefaultChecked(itm.pi_id) ? 'check' : 'uncheck'}
                                    key={idx}
                                    onChange={(e) => onCheckboxChange(e, itm.pi_id)}
                                    checked={checkDefaultChecked(itm.pi_id)}
                                  />
                                </div>
                              </div>
                            ) : (
                              <Dropdown overlay={() => dataMenu(itm)} trigger={['click']}>
                                <Button
                                  className="accordion-menu"
                                  role="presentation"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Tooltip title="Kebab menu">
                                    <div className="accordion-menu-icon" />
                                  </Tooltip>
                                </Button>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </ReadMore>
              </div>
            </div>
          </div>
        );
      }

      case 'pis': {
        return (
          <div className="run-query-content-section">
            <div className="run-query-content-card card-country-content card">
              <div className="pi-name-content">
                <div className="pi-content">
                  <div className="body-analysis-section">
                    <div className="leftside-column">
                      {!IsComparisionClicked && (
                        <div className="checkbox-filter">
                          <Checkbox
                            checked={
                              isSelectAllChecked || checkedPiList.includes(record.innoplexus_id)
                            }
                            disabled={isSelectAllChecked}
                            onChange={() => handlePiCheckbox(
                              record.innoplexus_id,
                              record.status,
                              record.pi_id,
                              record.originating_party,
                              record.compliance_issue_prevention_flag,
                              record.is_added,
                              record.is_added_by_sponsor,
                            )}
                          />
                        </div>
                      )}
                      <div className="meta-link" />
                      <a
                        className="pi-content-title"
                        title={record.pi_name}
                        target="_blank"
                        href={`/search/investigatorprofile?${stringify({
                          query: record.pi_name,
                          id: record.pi_id,
                          type: 'PI',
                        })}`}
                        rel="noreferrer noopener"
                      >
                        {record.pi_name}
                      </a>
                      {record.is_new ? (
                        <div className="new-label">
                          <div
                            className="new-label-icon"
                            title="This investigator was not part of the results earlier"
                          />
                        </div>
                      ) : null}
                      <div className="comments">
                        <Comment
                          projectId={projectID}
                          piId={record.pi_id}
                          type="pi"
                          sitePIExtraType="specific"
                          count={JSON.stringify(record.comments)}
                          is_lcor={adminType.includes('lcor_access')}
                        />
                      </div>
                      {record.compliance && record.compliance !== 'No Issues' ? (
                        <div
                          className={`${
                            record.compliance === 'Compliance preventing participation'
                            || record.compliance === 'DNU'
                              ? 'flag filled red'
                              : record.compliance === 'Compliance not preventing participation'
                                || record.compliance === 'Approved with Warning'
                                ? 'flag filled light-red'
                                : ''
                          }`}
                        >
                          <Popover
                            overlayClassName="flag-section-content flag-section-content-compliance"
                            placement="right"
                            title={(
                              <div
                                className={`${
                                  record.compliance === 'Compliance preventing participation'
                                  || record.compliance === 'DNU'
                                    ? 'flag-title filled red'
                                    : record.compliance
                                      === 'Compliance not preventing participation'
                                      ? 'flag-title filled light-red'
                                      : ''
                                }`}
                              >
                                GCP Compliance & CDD
                              </div>
                            )}
                            content={(
                              <div className="flag-data">
                                <div className="flag-data-title-create">
                                  <div className="flag-data-content-value">
                                    <>
                                      {record?.cdd_data?.length
                                        ? record?.cdd_data?.map((item) => (
                                          <>
                                            <div style={{ 'white-space': 'pre-line' }}>
                                              CDD Status : {item.status}
                                            </div>
                                            <br />
                                            <div style={{ 'white-space': 'pre-line' }}>
                                              CDD Date: {item.date ? item.date : '-'}
                                            </div>
                                            <br />
                                            <div style={{ 'white-space': 'pre-line' }}>
                                              CDD Comment: {item.comment ? item.comment : '-'}
                                            </div>
                                            <br />
                                            <hr />
                                          </>
                                        ))
                                        : undefined}
                                      {record?.compliances?.length
                                        && record?.compliances?.map((itm1) => {
                                          return (
                                            <>
                                              <div style={{ 'white-space': 'pre-line' }}>
                                                Compliance Preventing Participation :{' '}
                                                {itm1.no_trial_participation_flag === 'N'
                                                  ? 'No'
                                                  : itm1.no_trial_participation_flag === 'Y'
                                                    ? 'Yes'
                                                    : itm1.no_trial_participation_flag === 'NO'
                                                      ? 'No'
                                                      : itm1.no_trial_participation_flag === 'YES'
                                                        ? 'Yes'
                                                        : itm1.no_trial_participation_flag}
                                              </div>
                                              <br />

                                              <div style={{ 'white-space': 'pre-line' }}>
                                                Compliance Issue Comment:{' '}
                                                {itm1.compliance_issue_comment
                                                  ? itm1.compliance_issue_comment
                                                  : '-'}
                                              </div>
                                              <br />
                                              <div style={{ 'white-space': 'pre-line' }}>
                                                Compliance Issue Description:{' '}
                                                {itm1.compliance_issue_desc
                                                  ? itm1.compliance_issue_desc
                                                  : '-'}
                                              </div>
                                              <br />
                                              <div style={{ 'white-space': 'pre-line' }}>
                                                Compliance Issue Raised:{' '}
                                                {itm1.compliance_issue_raised_dt
                                                && itm1.compliance_issue_raised_dt.length
                                                  ? itm1.compliance_issue_raised_dt.split(' ')[0]
                                                  : '-'}
                                              </div>
                                              <br />
                                              <div style={{ 'white-space': 'pre-line' }}>
                                                Reviewed Status:{' '}
                                                {itm1.review_status ? itm1.review_status : '-'}
                                              </div>
                                              <br />
                                              <div style={{ 'white-space': 'pre-line' }}>
                                                Review Date:{' '}
                                                {itm1.review_date && itm1.review_date.length
                                                  ? itm1.review_date.split(' ')[0]
                                                  : '-'}
                                              </div>
                                              <br />
                                              <div style={{ 'white-space': 'pre-line' }}>
                                                Reviewer Name:{' '}
                                                {itm1.reviewer_name ? itm1.reviewer_name : '-'}
                                              </div>
                                              <br />
                                              <div style={{ 'white-space': 'pre-line' }}>
                                                Re-evaluation Comment:{' '}
                                                {itm1.reevaluation_comment
                                                  ? itm1.reevaluation_comment
                                                  : '-'}
                                              </div>
                                              <hr />
                                            </>
                                          );
                                        })}
                                    </>
                                  </div>
                                </div>
                              </div>
                            )}
                            trigger="click"
                          >
                            <div className="flag-img" />
                          </Popover>
                        </div>
                      ) : null}
                      {record.emerging_kol.length ? (
                        <Tooltip
                          title={(
                            <div className="tooltip-title">
                              <div>Emerging Category : {record.emerging_data.flag === 'Medium' ? 'Moderate'
                                : record.emerging_data.flag === 'High' ? 'High'
                                  : record.emerging_data.flag === 'Low' ? 'Low' : ''}
                              </div>
                              <div className="tooltip-theraprutic-area">
                                Therapy Area : {record.emerging_data.therapeutic_area}
                              </div>
                              <div className="tooltip-score">
                                Score : {record.emerging_data.score}
                              </div>
                            </div>
                          )}
                        >
                          <div
                            className={`${
                              record.emerging_data.flag === 'Medium'
                                ? 'project-result Medium-flag'
                                : record.emerging_data.flag === 'Low'
                                  ? 'project-result Low-flag'
                                  : record.emerging_data.flag === 'High'
                                    ? 'project-result High-flag'
                                    : ''
                            }`}
                          >
                            <Popover
                              overlayClassName="flag-section-content flag-section-content-project-result"
                              placement="right"
                              title={(
                                <div
                                  className={`${
                                    record.emerging_data.flag === 'Medium'
                                      ? 'project-result Medium-flag'
                                      : record.emerging_data.flag === 'Low'
                                        ? 'project-result Low-flag'
                                        : record.emerging_data.flag === 'High'
                                          ? 'project-result High-flag'
                                          : ''
                                  }`}
                                >
                                  TA wise Emerging KOL Score
                                </div>
                              )}
                              content={(
                                <>
                                  <div className="emerging-investigator-title">
                                    <div className="emerging-investigator-ta">Primary TA : &nbsp;</div>
                                    {Object.keys(record.emerging_data).length ? (<div className="emerging-investigator-"> {record.emerging_data.therapeutic_area}</div>) : ''}
                                  </div>

                                  <div className="emerging-investigator-right">
                                    <div
                                      className="emerging-investigator-header"
                                      style={{ display: 'flex', justifyContent: 'space-around' }}
                                    >

                                      <div className="emerging-investigator-header-th theraprutic-area">
                                        {' '}
                                        Therapy Area{' '}
                                      </div>
                                      <div className="emerging-investigator-header-th"> Score </div>
                                      <div className="emerging-investigator-header-th">
                                        {' '}
                                        Emerging KOL Class{' '}
                                      </div>
                                    </div>
                                    <div className="emerging-investigator-body">
                                      {record.emerging_kol?.length
                                        ? record.emerging_kol?.map((itm1) => (
                                          <>
                                            <div
                                              className="emerging-investigator-header-tr"
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                              }}
                                            >
                                              <div
                                                className="emerging-investigator-header-td therapeutic-area"
                                                style={{ display: 'flex' }}
                                              >
                                                <div className="emerging-investigator-header-td ta-value">
                                                  {itm1.therapeutic_area}
                                                </div>
                                              </div>
                                              <div className="emerging-investigator-header-td score">
                                                {itm1.score}
                                              </div>
                                              <div className="emerging-investigator-header-td">
                                                <div className="emerging-investigator-flag">
                                                  {itm1.flag === 'Medium' ? (
                                                    <span className="Medium-flag" title="Moderate" />
                                                  ) : itm1.flag === 'Low' ? (
                                                    <span className="Low-flag" title="Low" />
                                                  ) : itm1.flag === 'High' ? (
                                                    <span className="High-flag" title="High" />
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ))
                                        : ''}
                                    </div>
                                  </div>
                                </>
                              )}
                              trigger="click"
                            >
                              <div className="flag-img" />
                            </Popover>
                          </div>
                        </Tooltip>
                      ) : null}

                      <div
                        className={
                          record.alliancePresent
                            ? 'primary-org-change-alliance'
                            : 'primary-org-change'
                        }
                      >
                        <Button
                          onClick={() => setOrgChangeModalOpen({
                            isOpen: true,
                            piId: record.pi_id,
                            siteId: record.site_id,
                            isAlliance: record.alliancePresent,
                          })}
                        />
                      </div>
                    </div>
                    <div className="table-column">
                      <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                        {checkColumnDisplay('Feasibility Status') ? (
                          <div className="filter-sel-data-value status">
                            {getCflStatus(record, 'cfl')}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Feasibility Status Date') ? (
                          <div className="filter-sel-data-value center">
                            {record.last_cfl_status_updated_on
                              ? getFormattedDate(record.last_cfl_status_updated_on * 1000)
                              : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('LCOR Status') ? (
                          <div className="filter-sel-data-value status">
                            {getCflStatus(record, 'lcor')}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Sponsor Status') ? (
                          <div className="filter-sel-data-value status">
                            {getCflStatus(record, 'sponsor')}
                          </div>
                        ) : null}
                        {checkColumnDisplay('LCOR Status Date') ? (
                          <div className="filter-sel-data-value center">
                            {record.last_lcor_status_updated_on
                              ? getFormattedDate(record.last_lcor_status_updated_on * 1000)
                              : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Analysis ID') ? (
                          <div className="filter-sel-data-value analysis-id">
                            {record.analysis_seq_id ? record.analysis_seq_id : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Priority') ? (
                          <div className="filter-sel-data-value priority">
                            <Select
                              onChange={(e) => handlePriorityForSingle(
                                record.innoplexus_id,
                                e,
                                  record?.originating_party,
                                  record.pi_id,
                              )}
                              // defaultValue={record.priority ? record.priority : null}
                              value={getPriority(record)}
                              options={priorityList}
                              disabled={
                                isCheckedboxEnabled
                                || !(
                                  adminType.includes('lcor_access')
                                  || adminType.includes('cfl_access')
                                )
                                || isSelectAllChecked
                              }
                            />
                          </div>
                        ) : null}
                        {checkColumnDisplay('Origin') ? (
                          <div className="filter-sel-data-value origin">{getOrigin(record)}</div>
                        ) : null}
                        {checkColumnDisplay('Originating Party') ? (
                          <div className="filter-sel-data-value party">
                            <Select
                              onChange={(e) => handleOriginForSingle(record.innoplexus_id, e, record.pi_id)}
                              // defaultValue={record.originating_party ? record.originating_party : null}
                              value={getParty(record)}
                              options={originatingParty}
                              disabled={
                                isCheckedboxEnabled
                                || !(
                                  adminType.includes('lcor_access')
                                  || adminType.includes('cfl_access')
                                )
                                || isSelectAllChecked
                              }
                              style={{ width: '120px' }}
                            />
                          </div>
                        ) : null}
                        {checkColumnDisplay('Version') ? (
                          <div className="filter-sel-data-value priority">
                            <Select
                              onChange={(e) => handleVersionForSingle(record.innoplexus_id, e, record.pi_id)}
                              // defaultValue={itm.originating_party ? itm.originating_party : null}
                              value={getVersion(record)}
                              options={versionList}
                              disabled={
                                isCheckedboxEnabled
                                || !(
                                  adminType.includes('lcor_access')
                                  || adminType.includes('cfl_access')
                                )
                                || isSelectAllChecked
                              }
                            />
                          </div>
                        ) : null}
                        {checkColumnDisplay('Alliance Member Name') ? (
                          <div className="filter-sel-data-value status">
                            {record.alliance_member_name ? record.alliance_member_name : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Date Added') ? (
                          <div className="filter-sel-data-value status">
                            {record.date_added ? getFormattedDate(record.date_added * 1000) : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Alliance Contact') ? (
                          <div className="filter-sel-data-value status">
                            {record.alliance_contact?.full_name
                              ? record.alliance_contact?.full_name
                              : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Parexel Contact') ? (
                          <div className="filter-sel-data-value status">
                            {record.parexel_contact?.full_name
                              ? record.parexel_contact?.full_name
                              : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('LCOR Comment') ? (
                          <div className="filter-sel-data-value status">
                            {record.lcor_comment ? record.lcor_comment : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('CDA Expiry') ? (
                          <div className="filter-sel-data-value expiry">
                            {record.cda_expiry ? record.cda_expiry : '-'}
                          </div>
                        ) : null}

                        {checkColumnDisplay('Query ID') ? (
                          <div className="filter-sel-data-value query-id">
                            {record.query_seq_id ? record.query_seq_id : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('# Industry Studies') ? (
                          <Button
                            className="filter-sel-data-value industry-studies"
                            onClick={(e) => onIndustryClick(
                              e,
                              record.pi_id,
                              record.industry_studies_source,
                              record.analysis_id,
                            )}
                          >
                            {record.industry_studies}
                          </Button>
                        ) : null}
                        {checkColumnDisplay('Competing Studies') ? (
                          <Button
                            className="filter-sel-data-value center"
                            onClick={() => onCompetingClick(record.pi_id, record.analysis_id)}
                          >
                            {record.competing_studies}
                          </Button>
                        ) : null}
                        {checkColumnDisplay('# PXL Studies') ? (
                          <Button
                            className="filter-sel-data-value pxl-studies"
                            onClick={() => onPxlClick(record.pi_id, record.analysis_id)}
                          >
                            {record.pxl_studies}
                          </Button>
                        ) : null}
                        {checkColumnDisplay('Tier (Search Specific)') ? (
                          <Button
                            className="filter-sel-data-value pxl-studies"
                            onClick={() => onTierClick(record)}
                          >
                            {record.tier}
                          </Button>
                        ) : null}
                        {checkColumnDisplay('Profile Tier(General)') ? (
                          <Button
                            className="filter-sel-data-value pxl-studies"
                            onClick={() => onProfileTierClick(record)}
                          >
                            {record.p_tier}
                          </Button>
                        ) : null}
                        {checkColumnDisplay('Specialities') ? (
                          <div className="filter-sel-data-value pxl-studies">
                            {record?.specialties?.length ? record.specialties.join(', ') : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('VAT Invoice Present') ? (
                          <div className="filter-sel-data-value industry-studies">
                            {record.vat_invoice}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Enrolled Subjects') ? (
                          <div className="filter-sel-data-value enrolled-subjects">
                            {record.enrolled_count}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Quality') ? (
                          <div className="filter-sel-data-value quality">
                            <Rate count={3} disabled value={record.quality_rating} />
                          </div>
                        ) : null}
                        {checkColumnDisplay('Reliability') ? (
                          <div className="filter-sel-data-value enrolled-subjects">
                            {record.reliability_class}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Reliability Score') ? (
                          <Button
                            className="filter-sel-data-value pxl-studies"
                            onClick={() => onReliabilityClick(record)}
                          >
                            {record.reliability_score}
                          </Button>
                        ) : null}
                        {checkColumnDisplay('KOL Score') ? (
                          <Button className="filter-sel-data-value pxl-studies" onClick={() => onKolScoreClick(record)}>{record?.cls_kol_score?.toFixed(3)}</Button>
                          // <div className="filter-sel-data-value center">{record.cls_kol_score}</div>
                        ) : null}
                        {checkColumnDisplay('Start Up Months') ? (
                          <div className="filter-sel-data-value center">
                            {record.p_start_up ? record.p_start_up.toFixed(2) : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Start Up Index') ? (
                          <div className="filter-sel-data-value center">
                            {record.startup_index ? record.startup_index.toFixed(2) : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Recruitment Factor') ? (
                          <div className="filter-sel-data-value center">
                            {record.p_recruitment ? record.p_recruitment.toFixed(2) : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Last Trial Start Date') ? (
                          <div className="filter-sel-data-value center">
                            {record.recent_trial_start_date_ts
                              ? getFormattedDate(record.recent_trial_start_date_ts * 1000)
                              : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Center') ? (
                          <div className="filter-sel-data-value center">{record.site_name}</div>
                        ) : null}
                        {checkColumnDisplay('City') ? (
                          <div className="filter-sel-data-value center">
                            {record?.city !== '' ? record?.city : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('State') ? (
                          <div className="filter-sel-data-value center">
                            {record?.state !== '' ? record?.state : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Country') ? (
                          <div className="filter-sel-data-value center">{record.country}</div>
                        ) : null}
                        {checkColumnDisplay('Alliance Type') ? (
                          <div className="filter-sel-data-value center">{record.alliance_type}</div>
                        ) : null}
                        {checkColumnDisplay('Networks') ? (
                          <div className="filter-sel-data-value center">
                            {record.networks && record.networks.length
                              ? record.networks.join('; ')
                              : '-'}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="lastside-column">
                      {parse(location.search).compareFlag || IsComparisionClicked ? (
                        <div className="compare-checkbox">
                          <div className="checkbox-content">
                            <Checkbox
                              className={checkDefaultChecked(record.pi_id) ? 'check' : 'uncheck'}
                              key={record.pi_id}
                              onChange={(e) => onCheckboxChange(e, record.pi_id)}
                              checked={checkDefaultChecked(record.pi_id)}
                            />
                          </div>
                        </div>
                      ) : (
                        <Dropdown overlay={() => dataMenu(record)} trigger={['click']}>
                          <Button
                            className="accordion-menu"
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Tooltip title="Kebab menu">
                              <div className="accordion-menu-icon" />
                            </Tooltip>
                          </Button>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      case 'parent_institutes,primary_organizations,pis': {
        return (
          <div className="parent-site-wrap">
            <div className="run-query-content-section">
              <div className="run-query-content-card card-country-content card">
                <div className="card-content card-country-content-header">
                  <div className="body-analysis-section level-header-section level-two-header-section">
                    <div
                      className={`leftside-column ${
                        showPisOfParent === record.parent_id
                          ? 'dropdown-close'
                          : record.pis.length
                            ? 'dropdown-open'
                            : ''
                      }`}
                    >
                      <a
                        className="card-content-title"
                        title={record.parent_name}
                        target="_blank"
                        href={`/search/deepdives?${stringify({
                          query: record.parent_name,
                          id: record.parent_id,
                          type: 'SITE',
                          currentTab: 'parent_centers',
                        })}`}
                        rel="noreferrer noopener"
                      >
                        {record.parent_name}
                      </a>
                      <div className="population-flag" style={{ marginLeft: '10px' }}>
                        <Tooltip
                          title={renderTooltipTitleForAmerican(
                            record.primary_organizations.map(
                              (data) => data.american_indian_origin_category,
                            )[0],
                          )}
                        >
                          {record.primary_organizations.map(
                            (data) => data.american_indian_origin_category,
                          )[0] === 'High' ? (
                            <span className="High-population-icon-for-Alaska" />
                            ) : record.primary_organizations.map(
                              (data) => data.american_indian_origin_category,
                            )[0] === 'Low' ? (
                              <span className="Low-population-icon-for-Alaska" />
                              ) : record.primary_organizations.map(
                                (data) => data.american_indian_origin_category,
                              )[0] === 'Medium' ? (
                                <span className="Medium-population-icon-for-Alaska" />
                                ) : (
                                  ''
                                )}
                        </Tooltip>
                        <Tooltip
                          title={renderTooltipTitleForHawaiian(
                            record.primary_organizations.map(
                              (data) => data.native_hawaiian_origin_category,
                            )[0],
                          )}
                        >
                          {record.primary_organizations.map(
                            (data) => data.native_hawaiian_origin_category,
                          )[0] === 'High' ? (
                            <span className="High-population-icon-for-Hawaii" />
                            ) : record.primary_organizations.map(
                              (data) => data.native_hawaiian_origin_category,
                            )[0] === 'Low' ? (
                              <span className="Low-population-icon-for-Hawaii" />
                              ) : record.primary_organizations.map(
                                (data) => data.native_hawaiian_origin_category,
                              )[0] === 'Medium' ? (
                                <span className="Medium-population-icon-for-Hawaii" />
                                ) : (
                                  ''
                                )}
                        </Tooltip>
                      </div>
                      <span
                        className="dropdown-arrow"
                        role="presentation"
                        onClick={() => {
                          if (showPisOfParent !== record.parent_id) {
                            if (record.pis.length) {
                              setShowPisOfParent(record.parent_id);
                            }
                          } else {
                            setShowPisOfParent('');
                          }
                        }}
                      />
                    </div>
                    <div className="table-column">
                      <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                        {checkColumnDisplay('Feasibility Status') ? (
                          <div className="filter-sel-data-value status">
                            {record.status ? record.status : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Feasibility Status Date') ? (
                          <div className="filter-sel-data-value center">
                            {record.last_cfl_status_updated_on
                              ? getFormattedDate(record.last_cfl_status_updated_on * 1000)
                              : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('LCOR Status') ? (
                          <div className="filter-sel-data-value status">
                            {record.lcor_status ? record.lcor_status : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Sponsor Status') ? (
                          <div className="filter-sel-data-value status">
                            {record.sponsor_status ? record.sponsor_status : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('LCOR Status Date') ? (
                          <div className="filter-sel-data-value center">
                            {record.last_lcor_status_updated_on
                              ? getFormattedDate(record.last_lcor_status_updated_on * 1000)
                              : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Analysis ID') ? (
                          <div className="filter-sel-data-value analysis-id">
                            {record.analysis_seq_id ? record.analysis_seq_id : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Priority') ? (
                          <div className="filter-sel-data-value priority">-</div>
                        ) : null}
                        {checkColumnDisplay('Origin') ? (
                          <div className="filter-sel-data-value origin">-</div>
                        ) : null}
                        {checkColumnDisplay('Originating Party') ? (
                          <div className="filter-sel-data-value party">-</div>
                        ) : null}
                        {checkColumnDisplay('Version') ? (
                          <div className="filter-sel-data-value priority">-</div>
                        ) : null}
                        {checkColumnDisplay('Alliance Member Name') ? (
                          <div className="filter-sel-data-value status">-</div>
                        ) : null}
                        {checkColumnDisplay('Date Added') ? (
                          <div className="filter-sel-data-value status">-</div>
                        ) : null}
                        {checkColumnDisplay('Alliance Contact') ? (
                          <div className="filter-sel-data-value status">-</div>
                        ) : null}
                        {checkColumnDisplay('Parexel Contact') ? (
                          <div className="filter-sel-data-value status">-</div>
                        ) : null}
                        {checkColumnDisplay('LCOR Comment') ? (
                          <div className="filter-sel-data-value status">-</div>
                        ) : null}
                        {checkColumnDisplay('CDA Expiry') ? (
                          <div className="filter-sel-data-value expiry">-</div>
                        ) : null}
                        {checkColumnDisplay('Query ID') ? (
                          <div className="filter-sel-data-value query-id">
                            {record.query_seq_id ? record.query_seq_id : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('# Industry Studies') ? (
                          <div className="filter-sel-data-value industry-studies">
                            {record.industry_studies}
                          </div>
                        ) : null}
                        {checkColumnDisplay('VAT Invoice Present') ? (
                          <div className="filter-sel-data-value industry-studies">
                            {record.vat_invoice}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Competing Studies') ? (
                          <div className="filter-sel-data-value center">
                            {record.competing_studies}
                          </div>
                        ) : null}
                        {checkColumnDisplay('# PXL Studies') ? (
                          <div className="filter-sel-data-value pxl-studies">
                            {record.pxl_studies}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Tier (Search Specific)') ? (
                          <Button
                            className="filter-sel-data-value pxl-studies"
                            onClick={() => onTierClick(record.pi_id)}
                          >
                            {record.tier}
                          </Button>
                        ) : null}
                        {checkColumnDisplay('Profile Tier(General)') ? (
                          <Button
                            className="filter-sel-data-value pxl-studies"
                            onClick={() => onProfileTierClick(record.pi_id)}
                          >
                            {record.p_tier}
                          </Button>
                        ) : null}
                        {checkColumnDisplay('Specialities') ? (
                          <div className="filter-sel-data-value pxl-studies">
                            {record?.specialties?.length ? record.specialties.join(', ') : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Enrolled Subjects') ? (
                          <div className="filter-sel-data-value enrolled-subjects">
                            {record.enrolled_count}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Quality') ? (
                          <div className="filter-sel-data-value quality">
                            <Rate count={3} disabled value={record.quality_rating} />
                          </div>
                        ) : null}
                        {checkColumnDisplay('Reliability') ? (
                          <div className="filter-sel-data-value enrolled-subjects">
                            {record.reliability_class}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Reliability Score') ? (
                          <Button
                            className="filter-sel-data-value pxl-studies"
                            onClick={() => onReliabilityClick(record)}
                          >
                            {record.reliability_score}
                          </Button>
                        ) : null}
                        {checkColumnDisplay('KOL Score') ? (
                          <div className="filter-sel-data-value center">Not applicable</div>
                        ) : null}
                        {checkColumnDisplay('Start Up Months') ? (
                          <div className="filter-sel-data-value center">
                            {record.p_start_up ? record.p_start_up.toFixed(2) : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Start Up Index') ? (
                          <div className="filter-sel-data-value center">
                            {record.startup_index ? record.startup_index.toFixed(2) : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Recruitment Factor') ? (
                          <div className="filter-sel-data-value center">
                            {record.p_recruitment ? record.p_recruitment.toFixed(2) : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Last Trial Start Date') ? (
                          <div className="filter-sel-data-value center">Not applicable</div>
                        ) : null}
                        {checkColumnDisplay('Center') ? (
                          <div className="filter-sel-data-value center">{record.site_name}</div>
                        ) : null}
                        {checkColumnDisplay('City') ? (
                          <div className="filter-sel-data-value center">
                            {record?.city !== '' ? record?.city : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('State') ? (
                          <div className="filter-sel-data-value center">
                            {record?.state !== '' ? record?.state : '-'}
                          </div>
                        ) : null}
                        {checkColumnDisplay('Country') ? (
                          <div className="filter-sel-data-value center">{record.country}</div>
                        ) : null}
                        {checkColumnDisplay('Alliance Type') ? (
                          <div className="filter-sel-data-value center">Not applicable</div>
                        ) : null}
                        {checkColumnDisplay('Networks') ? (
                          <div className="filter-sel-data-value center">
                            {record.networks && record.networks.length
                              ? record.networks.join('; ')
                              : '-'}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="lastside-column">
                      {parse(location.search).compareSiteFlag || IsSiteComparisionClicked ? (
                        <div className="compare-checkbox">
                          <div className="checkbox-content">
                            <Checkbox
                              className={
                                checkDefaultChecked(record.parent_id) ? 'check' : 'uncheck'
                              }
                              onChange={(e) => onCheckboxChange(e, record.parent_id)}
                              checked={checkDefaultChecked(record.parent_id)}
                            />
                          </div>
                        </div>
                      ) : (
                        <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                          <Button
                            className="accordion-menu"
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Tooltip title="Kebab menu">
                              <div className="accordion-menu-icon" />
                            </Tooltip>
                          </Button>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                </div>
                {showPisOfParent === record.parent_id && record.pis.length ? (
                  <div className="pi-name-content">
                    <ReadMore limit={5}>
                      {record.pis.map((itm, idx) => {
                        return (
                          <div className="pi-content">
                            <div className="body-analysis-section">
                              <div className="leftside-column">
                                {!IsComparisionClicked && (
                                  <div className="checkbox-filter">
                                    <Checkbox
                                      checked={
                                        isSelectAllChecked
                                        || checkedPiList.includes(itm.innoplexus_id)
                                      }
                                      disabled={isSelectAllChecked}
                                      onChange={() => handlePiCheckbox(
                                        itm.innoplexus_id,
                                        itm.status,
                                        itm.pi_id,
                                        itm.originating_party,
                                        itm.compliance_issue_prevention_flag,
                                        itm.is_added,
                                        itm.is_added_by_sponsor,
                                      )}
                                    />
                                  </div>
                                )}
                                <div className="meta-link" />
                                <a
                                  className="pi-content-title"
                                  title={itm.pi_name}
                                  target="_blank"
                                  href={`/search/investigatorprofile?${stringify({
                                    query: itm.pi_name,
                                    id: itm.pi_id,
                                    type: 'PI',
                                  })}`}
                                  rel="noreferrer noopener"
                                >
                                  {itm.pi_name}
                                </a>
                                {itm.is_new ? (
                                  <div className="new-label">
                                    <div
                                      className="new-label-icon"
                                      title="This investigator was not part of the results earlier"
                                    />
                                  </div>
                                ) : null}
                                <div className="comments">
                                  <Comment
                                    projectId={parse(location.search).project_id}
                                    analysisId={parse(location.search).analysis_id}
                                    piId={itm.pi_id}
                                    type="pi"
                                    sitePIExtraType="specific"
                                    count={JSON.stringify(itm.comments)}
                                    is_lcor={adminType.includes('lcor_access')}
                                  />
                                </div>
                                {itm.compliance && itm.compliance !== 'No Issues' ? (
                                  <div
                                    className={`${
                                      itm.compliance === 'Compliance preventing participation'
                                      || itm.compliance === 'DNU'
                                        ? 'flag filled red'
                                        : itm.compliance
                                            === 'Compliance not preventing participation'
                                          || itm.compliance === 'Approved with Warning'
                                          ? 'flag filled light-red'
                                          : ''
                                    }`}
                                  >
                                    <Popover
                                      overlayClassName="flag-section-content flag-section-content-compliance"
                                      placement="right"
                                      title={(
                                        <div
                                          className={`${
                                            itm.compliance
                                              === 'Compliance preventing participation'
                                            || itm.compliance === 'DNU'
                                              ? 'flag-title filled red'
                                              : itm.compliance
                                                  === 'Compliance not preventing participation'
                                                || itm.compliance === 'Approved with Warning'
                                                ? 'flag-title filled light-red'
                                                : ''
                                          }`}
                                        >
                                          GCP Compliance & CDD
                                        </div>
                                      )}
                                      content={(
                                        <div className="flag-data">
                                          <div className="flag-data-title-create">
                                            <div className="flag-data-content-value">
                                              <>
                                                {itm?.cdd_data?.length
                                                  ? itm?.cdd_data?.map((item) => (
                                                    <>
                                                      <div style={{ 'white-space': 'pre-line' }}>
                                                        CDD Status : {item.status}
                                                      </div>
                                                      <br />
                                                      <div style={{ 'white-space': 'pre-line' }}>
                                                        CDD Date: {item.date ? item.date : '-'}
                                                      </div>
                                                      <br />
                                                      <div style={{ 'white-space': 'pre-line' }}>
                                                        CDD Comment:{' '}
                                                        {item.comment ? item.comment : '-'}
                                                      </div>
                                                      <br />
                                                      <hr />
                                                    </>
                                                  ))
                                                  : undefined}
                                                {itm?.compliances?.length
                                                  && itm?.compliances?.map((itm1) => {
                                                    return (
                                                      <>
                                                        <div style={{ 'white-space': 'pre-line' }}>
                                                          Compliance Preventing Participation :{' '}
                                                          {itm1.no_trial_participation_flag === 'N'
                                                            ? 'No'
                                                            : itm1.no_trial_participation_flag
                                                              === 'Y'
                                                              ? 'Yes'
                                                              : itm1.no_trial_participation_flag
                                                              === 'NO'
                                                                ? 'No'
                                                                : itm1.no_trial_participation_flag
                                                              === 'YES'
                                                                  ? 'Yes'
                                                                  : itm1.no_trial_participation_flag}
                                                        </div>
                                                        <br />

                                                        <div style={{ 'white-space': 'pre-line' }}>
                                                          Compliance Issue Comment:{' '}
                                                          {itm1.compliance_issue_comment
                                                            ? itm1.compliance_issue_comment
                                                            : '-'}
                                                        </div>
                                                        <br />
                                                        <div style={{ 'white-space': 'pre-line' }}>
                                                          Compliance Issue Description:{' '}
                                                          {itm1.compliance_issue_desc
                                                            ? itm1.compliance_issue_desc
                                                            : '-'}
                                                        </div>
                                                        <br />
                                                        <div style={{ 'white-space': 'pre-line' }}>
                                                          Compliance Issue Raised:{' '}
                                                          {itm1.compliance_issue_raised_dt
                                                          && itm1.compliance_issue_raised_dt.length
                                                            ? itm1.compliance_issue_raised_dt.split(
                                                              ' ',
                                                            )[0]
                                                            : '-'}
                                                        </div>
                                                        <br />
                                                        <div style={{ 'white-space': 'pre-line' }}>
                                                          Reviewed Status:{' '}
                                                          {itm1.review_status
                                                            ? itm1.review_status
                                                            : '-'}
                                                        </div>
                                                        <br />
                                                        <div style={{ 'white-space': 'pre-line' }}>
                                                          Review Date:{' '}
                                                          {itm1.review_date
                                                          && itm1.review_date.length
                                                            ? itm1.review_date.split(' ')[0]
                                                            : '-'}
                                                        </div>
                                                        <br />
                                                        <div style={{ 'white-space': 'pre-line' }}>
                                                          Reviewer Name:{' '}
                                                          {itm1.reviewer_name
                                                            ? itm1.reviewer_name
                                                            : '-'}
                                                        </div>
                                                        <br />
                                                        <div style={{ 'white-space': 'pre-line' }}>
                                                          Re-evaluation Comment:{' '}
                                                          {itm1.reevaluation_comment
                                                            ? itm1.reevaluation_comment
                                                            : '-'}
                                                        </div>
                                                        <hr />
                                                      </>
                                                    );
                                                  })}
                                              </>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      trigger="click"
                                    >
                                      <div className="flag-img" />
                                    </Popover>
                                  </div>
                                ) : null}
                                {itm.emerging_kol.length ? (
                                  <Tooltip
                                    title={(
                                      <div className="tooltip-title">
                                        <div>Emerging Category : {itm.emerging_data.flag === 'Medium' ? 'Moderate'
                                          : itm.emerging_data.flag === 'High' ? 'High'
                                            : itm.emerging_data.flag === 'Low' ? 'Low' : ''}
                                        </div>
                                        <div className="tooltip-theraprutic-area">
                                          Therapy Area : {itm.emerging_data.therapeutic_area}
                                        </div>
                                        <div className="tooltip-score">
                                          Score : {itm.emerging_data.score}
                                        </div>
                                      </div>
                                    )}
                                  >
                                    <div
                                      className={`${
                                        itm.emerging_data.flag === 'Medium'
                                          ? 'project-result Medium-flag'
                                          : itm.emerging_data.flag === 'Low'
                                            ? 'project-result Low-flag'
                                            : itm.emerging_data.flag === 'High'
                                              ? 'project-result High-flag'
                                              : ''
                                      }`}
                                    >
                                      <Popover
                                        overlayClassName="flag-section-content flag-section-content-project-result"
                                        placement="right"
                                        title={(
                                          <div
                                            className={`${
                                              itm.emerging_data.flag === 'Medium'
                                                ? 'project-result Medium-flag'
                                                : itm.emerging_data.flag === 'Low'
                                                  ? 'project-result Low-flag'
                                                  : itm.emerging_data.flag === 'High'
                                                    ? 'project-result High-flag'
                                                    : ''
                                            }`}
                                          >
                                            TA wise Emerging KOL Score
                                          </div>
                                        )}
                                        content={(
                                          <>
                                            <div className="emerging-investigator-title">
                                              <div className="emerging-investigator-ta">Primary TA : &nbsp;</div>
                                              {Object.keys(itm.emerging_data).length ? (<div className="emerging-investigator-"> {itm.emerging_data.therapeutic_area}</div>) : ''}
                                            </div>
                                            <div className="emerging-investigator-right">
                                              <div
                                                className="emerging-investigator-header"
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'space-around',
                                                }}
                                              >

                                                <div className="emerging-investigator-header-th theraprutic-area">
                                                  {' '}
                                                  Therapy Area{' '}
                                                </div>
                                                <div className="emerging-investigator-header-th">
                                                  {' '}
                                                  Score{' '}
                                                </div>
                                                <div className="emerging-investigator-header-th">
                                                  {' '}
                                                  Emerging KOL Class{' '}
                                                </div>
                                              </div>
                                              <div className="emerging-investigator-body">
                                                {itm.emerging_kol?.length
                                                  ? itm.emerging_kol?.map((itm1) => (
                                                    <>
                                                      <div
                                                        className="emerging-investigator-header-tr"
                                                        style={{
                                                          display: 'flex',
                                                          justifyContent: 'space-around',
                                                        }}
                                                      >
                                                        <div
                                                          className="emerging-investigator-header-td therapeutic-area"
                                                          style={{ display: 'flex' }}
                                                        >
                                                          <div className="emerging-investigator-header-td ta-value">
                                                            {itm1.therapeutic_area}
                                                          </div>
                                                        </div>
                                                        <div className="emerging-investigator-header-td score">
                                                          {itm1.score}
                                                        </div>
                                                        <div className="emerging-investigator-header-td">
                                                          <div className="emerging-investigator-flag">
                                                            {itm1.flag === 'Medium' ? (
                                                              <span
                                                                className="Medium-flag"
                                                                title="Moderate"
                                                              />
                                                            ) : itm1.flag === 'Low' ? (
                                                              <span
                                                                className="Low-flag"
                                                                title="Low"
                                                              />
                                                            ) : itm1.flag === 'High' ? (
                                                              <span
                                                                className="High-flag"
                                                                title="High"
                                                              />
                                                            ) : (
                                                              ''
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ))
                                                  : ''}
                                              </div>
                                            </div>
                                          </>
                                        )}
                                        trigger="click"
                                      >
                                        <div className="flag-img" />
                                      </Popover>
                                    </div>
                                  </Tooltip>
                                ) : null}
                                <div
                                  className={
                                    itm.alliancePresent
                                      ? 'primary-org-change-alliance'
                                      : 'primary-org-change'
                                  }
                                >
                                  <Button
                                    onClick={() => setOrgChangeModalOpen({
                                      isOpen: true,
                                      piId: itm.pi_id,
                                      siteId: itm.site_id,
                                      isAlliance: itm.alliancePresent,
                                    })}
                                  />
                                </div>
                              </div>
                              <div className="table-column">
                                <div
                                  className="table-column-sec table-column-body"
                                  onScroll={onRowScroll}
                                >
                                  {checkColumnDisplay('Feasibility Status') ? (
                                    <div className="filter-sel-data-value status">
                                      {getCflStatus(itm, 'cfl')}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Feasibility Status Date') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm.last_cfl_status_updated_on
                                        ? getFormattedDate(itm.last_cfl_status_updated_on * 1000)
                                        : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('LCOR Status') ? (
                                    <div className="filter-sel-data-value status">
                                      {getCflStatus(itm, 'lcor')}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Sponsor Status') ? (
                                    <div className="filter-sel-data-value status">
                                      {getCflStatus(itm, 'sponsor')}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('LCOR Status Date') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm.last_lcor_status_updated_on
                                        ? getFormattedDate(itm.last_lcor_status_updated_on * 1000)
                                        : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Analysis ID') ? (
                                    <div className="filter-sel-data-value analysis-id">
                                      {itm.analysis_seq_id ? itm.analysis_seq_id : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Priority') ? (
                                    <div className="filter-sel-data-value priority">
                                      <Select
                                        onChange={(e) => handlePriorityForSingle(
                                          itm.innoplexus_id,
                                          e,
                                            itm?.originating_party,
                                            itm.pi_id,
                                        )}
                                        // defaultValue={itm.priority ? itm.priority : null}
                                        value={getPriority(itm)}
                                        options={priorityList}
                                        disabled={
                                          isCheckedboxEnabled
                                          || !(
                                            adminType.includes('lcor_access')
                                            || adminType.includes('cfl_access')
                                          )
                                          || isSelectAllChecked
                                        }
                                      />
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Origin') ? (
                                    <div className="filter-sel-data-value origin">
                                      {getOrigin(itm)}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Originating Party') ? (
                                    <div className="filter-sel-data-value party">
                                      <div className="filter-sel-data-value priority">
                                        <Select
                                          onChange={(e) => handleOriginForSingle(itm.innoplexus_id, e, itm.pi_id)}
                                          // defaultValue={itm.originating_party ? itm.originating_party : null}
                                          value={getParty(itm)}
                                          options={originatingParty}
                                          disabled={
                                            isCheckedboxEnabled
                                            || !(
                                              adminType.includes('lcor_access')
                                              || adminType.includes('cfl_access')
                                            )
                                            || isSelectAllChecked
                                          }
                                          style={{ width: '120px' }}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Version') ? (
                                    <div className="filter-sel-data-value priority">
                                      <Select
                                        onChange={(e) => handleVersionForSingle(itm.innoplexus_id, e, itm.pi_id)}
                                        // defaultValue={itm.originating_party ? itm.originating_party : null}
                                        value={getVersion(itm)}
                                        options={versionList}
                                        disabled={
                                          isCheckedboxEnabled
                                          || !(
                                            adminType.includes('lcor_access')
                                            || adminType.includes('cfl_access')
                                          )
                                          || isSelectAllChecked
                                        }
                                      />
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Alliance Member Name') ? (
                                    <div className="filter-sel-data-value status">
                                      {itm.alliance_member_name ? itm.alliance_member_name : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Date Added') ? (
                                    <div className="filter-sel-data-value status">
                                      {itm.date_added
                                        ? getFormattedDate(itm.date_added * 1000)
                                        : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Alliance Contact') ? (
                                    <div className="filter-sel-data-value status">
                                      {itm.alliance_contact?.full_name
                                        ? itm.alliance_contact?.full_name
                                        : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Parexel Contact') ? (
                                    <div className="filter-sel-data-value status">
                                      {itm.parexel_contact?.full_name
                                        ? itm.parexel_contact?.full_name
                                        : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('LCOR Comment') ? (
                                    <div className="filter-sel-data-value status">
                                      {itm.lcor_comment ? itm.lcor_comment : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('CDA Expiry') ? (
                                    <div className="filter-sel-data-value expiry">
                                      {itm.cda_expiry ? itm.cda_expiry : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Query ID') ? (
                                    <div className="filter-sel-data-value query-id">
                                      {itm.query_seq_id ? itm.query_seq_id : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('# Industry Studies') ? (
                                    <Button
                                      className="filter-sel-data-value industry-studies"
                                      onClick={(e) => onIndustryClick(
                                        e,
                                        itm.pi_id,
                                        itm.industry_studies_source,
                                        itm.analysis_id,
                                      )}
                                    >
                                      {itm.industry_studies}
                                    </Button>
                                  ) : null}
                                  {checkColumnDisplay('VAT Invoice Present') ? (
                                    <div className="filter-sel-data-value industry-studies">
                                      {itm.vat_invoice}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Competing Studies') ? (
                                    <Button
                                      className="filter-sel-data-value center"
                                      onClick={() => onCompetingClick(itm.pi_id, itm.analysis_id)}
                                    >
                                      {itm.competing_studies}
                                    </Button>
                                  ) : null}
                                  {checkColumnDisplay('# PXL Studies') ? (
                                    <Button
                                      className="filter-sel-data-value pxl-studies"
                                      onClick={() => onPxlClick(itm.pi_id, itm.analysis_id)}
                                    >
                                      {itm.pxl_studies}
                                    </Button>
                                  ) : null}
                                  {checkColumnDisplay('Tier (Search Specific)') ? (
                                    <Button
                                      className="filter-sel-data-value pxl-studies"
                                      onClick={() => onTierClick(itm.pi_id)}
                                    >
                                      {itm.tier}
                                    </Button>
                                  ) : null}
                                  {checkColumnDisplay('Profile Tier(General)') ? (
                                    <Button
                                      className="filter-sel-data-value pxl-studies"
                                      onClick={() => onProfileTierClick(itm.pi_id)}
                                    >
                                      {itm.p_tier}
                                    </Button>
                                  ) : null}
                                  {checkColumnDisplay('Specialities') ? (
                                    <div className="filter-sel-data-value pxl-studies">
                                      {itm?.specialties?.length ? itm.specialties.join(', ') : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Enrolled Subjects') ? (
                                    <div className="filter-sel-data-value enrolled-subjects">
                                      {itm.enrolled_count}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Quality') ? (
                                    <div className="filter-sel-data-value quality">
                                      <Rate count={3} disabled value={itm.quality_rating} />
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Reliability') ? (
                                    <div className="filter-sel-data-value enrolled-subjects">
                                      {itm.reliability_class}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Reliability Score') ? (
                                    <Button
                                      className="filter-sel-data-value pxl-studies"
                                      onClick={() => onReliabilityClick(itm)}
                                    >
                                      {itm.reliability_score}
                                    </Button>
                                  ) : null}
                                  {checkColumnDisplay('KOL Score') ? (
                                    <Button className="filter-sel-data-value pxl-studies" onClick={() => onKolScoreClick(itm)}>{itm?.cls_kol_score?.toFixed(3)}</Button>

                                  // <div className="filter-sel-data-value center">
                                  //   {itm.cls_kol_score}
                                  // </div>
                                  ) : null}
                                  {checkColumnDisplay('Start Up Months') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm.p_start_up ? itm.p_start_up.toFixed(2) : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Start Up Index') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm.startup_index ? itm.startup_index.toFixed(2) : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Recruitment Factor') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm.p_recruitment ? itm.p_recruitment.toFixed(2) : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Last Trial Start Date') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm.recent_trial_start_date_ts
                                        ? getFormattedDate(itm.recent_trial_start_date_ts * 1000)
                                        : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Center') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm.site_name}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('City') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm?.city !== '' ? itm?.city : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('State') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm?.state !== '' ? itm?.state : '-'}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Country') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm.country}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Alliance Type') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm.alliance_type}
                                    </div>
                                  ) : null}
                                  {checkColumnDisplay('Networks') ? (
                                    <div className="filter-sel-data-value center">
                                      {itm.networks && itm.networks.length
                                        ? itm.networks.join('; ')
                                        : '-'}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="lastside-column">
                                {parse(location.search).compareFlag || IsComparisionClicked ? (
                                  <div className="compare-checkbox">
                                    <div className="checkbox-content">
                                      <Checkbox
                                        className={
                                          checkDefaultChecked(itm.pi_id) ? 'check' : 'uncheck'
                                        }
                                        key={idx}
                                        onChange={(e) => onCheckboxChange(e, itm.pi_id)}
                                        checked={checkDefaultChecked(itm.pi_id)}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <Dropdown overlay={() => dataMenu(itm)} trigger={['click']}>
                                    <Button
                                      className="accordion-menu"
                                      role="presentation"
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Tooltip title="Kebab menu">
                                        <div className="accordion-menu-icon" />
                                      </Tooltip>
                                    </Button>
                                  </Dropdown>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </ReadMore>
                  </div>
                ) : null}
              </div>
            </div>
            {record.primary_organizations && record.primary_organizations.length ? (
              <>
                {record.primary_organizations.map((recod, indexVal) => (
                  <div
                    className="run-query-content-section run-query-content-section-child"
                    key={indexVal}
                  >
                    <div className="run-query-content-card card-country-content card">
                      <div className="card-content card-country-content-header">
                        <div className="body-analysis-section level-middle-header-section">
                          <div className="leftside-column">
                            <a
                              className="card-content-title"
                              title={recod.site_name}
                              target="_blank"
                              href={`/search/deepdives?${stringify({
                                query: recod.site_name,
                                id: recod.site_id,
                                type: 'SITE',
                                currentTab: recod.parent_flag ? 'parent_centers' : 'centers',
                              })}`}
                              rel="noreferrer noopener"
                            >
                              {recod.site_name}
                            </a>
                            <div className="comments">
                              <Comment
                                projectId={parse(location.search).project_id}
                                analysisId={parse(location.search).analysis_id}
                                siteId={recod.site_id}
                                type="site"
                                sitePIExtraType="specific"
                                count={JSON.stringify(recod.comments)}
                              />
                            </div>
                            <div className="population-flag">
                              <Tooltip
                                title={renderTooltipTitleForAmerican(
                                  recod.american_indian_origin_category,
                                )}
                              >
                                {recod.american_indian_origin_category === 'High' ? (
                                  <span className="High-population-icon-for-Alaska" />
                                ) : recod.american_indian_origin_category === 'Low' ? (
                                  <span className="Low-population-icon-for-Alaska" />
                                ) : recod.american_indian_origin_category === 'Medium' ? (
                                  <span className="Medium-population-icon-for-Alaska" />
                                ) : (
                                  ''
                                )}
                              </Tooltip>
                              <Tooltip
                                title={renderTooltipTitleForHawaiian(
                                  recod.native_hawaiian_origin_category,
                                )}
                              >
                                {recod.native_hawaiian_origin_category === 'High' ? (
                                  <span className="High-population-icon-for-Hawaii" />
                                ) : recod.native_hawaiian_origin_category === 'Low' ? (
                                  <span className="Low-population-icon-for-Hawaii" />
                                ) : recod.native_hawaiian_origin_category === 'Medium' ? (
                                  <span className="Medium-population-icon-for-Hawaii" />
                                ) : (
                                  ''
                                )}
                              </Tooltip>
                            </div>
                          </div>
                          <div className="table-column">
                            <div
                              className="table-column-sec table-column-body"
                              onScroll={onRowScroll}
                            >
                              {checkColumnDisplay('Feasibility Status') ? (
                                <div className="filter-sel-data-value status">
                                  {record.status ? record.status : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Feasibility Status Date') ? (
                                <div className="filter-sel-data-value center">
                                  {recod.last_cfl_status_updated_on
                                    ? getFormattedDate(recod.last_cfl_status_updated_on * 1000)
                                    : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('LCOR Status') ? (
                                <div className="filter-sel-data-value status">
                                  {recod.lcor_status ? recod.lcor_status : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Sponsor Status') ? (
                                <div className="filter-sel-data-value status">
                                  {recod.sponsor_status ? recod.sponsor_status : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('LCOR Status Date') ? (
                                <div className="filter-sel-data-value center">
                                  {recod.last_lcor_status_updated_on
                                    ? getFormattedDate(recod.last_lcor_status_updated_on * 1000)
                                    : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Analysis ID') ? (
                                <div className="filter-sel-data-value analysis-id">
                                  {recod.analysis_seq_id ? recod.analysis_seq_id : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Priority') ? (
                                <div className="filter-sel-data-value priority">-</div>
                              ) : null}
                              {checkColumnDisplay('Origin') ? (
                                <div className="filter-sel-data-value origin">-</div>
                              ) : null}
                              {checkColumnDisplay('Originating Party') ? (
                                <div className="filter-sel-data-value party">-</div>
                              ) : null}
                              {checkColumnDisplay('Version') ? (
                                <div className="filter-sel-data-value priority">-</div>
                              ) : null}
                              {checkColumnDisplay('Alliance Member Name') ? (
                                <div className="filter-sel-data-value status">-</div>
                              ) : null}
                              {checkColumnDisplay('Date Added') ? (
                                <div className="filter-sel-data-value status">-</div>
                              ) : null}
                              {checkColumnDisplay('Alliance Contact') ? (
                                <div className="filter-sel-data-value status">-</div>
                              ) : null}
                              {checkColumnDisplay('Parexel Contact') ? (
                                <div className="filter-sel-data-value status">-</div>
                              ) : null}
                              {checkColumnDisplay('LCOR Comment') ? (
                                <div className="filter-sel-data-value status">-</div>
                              ) : null}
                              {checkColumnDisplay('CDA Expiry') ? (
                                <div className="filter-sel-data-value expiry">-</div>
                              ) : null}
                              {checkColumnDisplay('Query ID') ? (
                                <div className="filter-sel-data-value query-id">
                                  {record.query_seq_id ? record.query_seq_id : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('# Industry Studies') ? (
                                <div className="filter-sel-data-value industry-studies">
                                  {recod.industry_studies}
                                </div>
                              ) : null}
                              {checkColumnDisplay('VAT Invoice Present') ? (
                                <div className="filter-sel-data-value industry-studies">
                                  {recod.vat_invoice}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Competing Studies') ? (
                                <div className="filter-sel-data-value center">
                                  {recod.competing_studies}
                                </div>
                              ) : null}
                              {checkColumnDisplay('# PXL Studies') ? (
                                <div className="filter-sel-data-value pxl-studies">
                                  {recod.pxl_studies}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Tier (Search Specific)') ? (
                                <Button
                                  className="filter-sel-data-value pxl-studies"
                                  onClick={() => onTierClick(recod.pi_id)}
                                >
                                  {recod.tier}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('Profile Tier(General)') ? (
                                <Button
                                  className="filter-sel-data-value pxl-studies"
                                  onClick={() => onProfileTierClick(recod.pi_id)}
                                >
                                  {recod.p_tier}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('Specialities') ? (
                                <div className="filter-sel-data-value pxl-studies">
                                  {recod?.specialties?.length ? recod.specialties.join(', ') : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Enrolled Subjects') ? (
                                <div className="filter-sel-data-value enrolled-subjects">
                                  {recod.enrolled_count}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Quality') ? (
                                <div className="filter-sel-data-value quality">
                                  <Rate count={3} disabled value={recod.quality_rating} />
                                </div>
                              ) : null}
                              {checkColumnDisplay('Reliability') ? (
                                <div className="filter-sel-data-value enrolled-subjects">
                                  {recod.reliability_class}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Reliability Score') ? (
                                <Button
                                  className="filter-sel-data-value pxl-studies"
                                  onClick={() => onReliabilityClick(record)}
                                >
                                  {recod.reliability_score}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('KOL Score') ? (
                                <div className="filter-sel-data-value center">Not applicable</div>
                              ) : null}
                              {checkColumnDisplay('Start Up Months') ? (
                                <div className="filter-sel-data-value center">
                                  {recod.p_start_up ? recod.p_start_up.toFixed(2) : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Start Up Index') ? (
                                <div className="filter-sel-data-value center">
                                  {recod.startup_index ? recod.startup_index.toFixed(2) : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Recruitment Factor') ? (
                                <div className="filter-sel-data-value center">
                                  {recod.p_recruitment ? recod.p_recruitment.toFixed(2) : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Last Trial Start Date') ? (
                                <div className="filter-sel-data-value center">Not applicable</div>
                              ) : null}
                              {checkColumnDisplay('Center') ? (
                                <div className="filter-sel-data-value center">
                                  {recod.site_name}
                                </div>
                              ) : null}
                              {checkColumnDisplay('City') ? (
                                <div className="filter-sel-data-value center">
                                  {recod?.city !== '' ? recod?.city : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('State') ? (
                                <div className="filter-sel-data-value center">
                                  {recod?.state !== '' ? recod?.state : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Country') ? (
                                <div className="filter-sel-data-value center">{recod.country}</div>
                              ) : null}
                              {checkColumnDisplay('Alliance Type') ? (
                                <div className="filter-sel-data-value center">Not applicable</div>
                              ) : null}
                              {checkColumnDisplay('Networks') ? (
                                <div className="filter-sel-data-value center">
                                  {recod.networks && recod.networks.length
                                    ? recod.networks.join('; ')
                                    : '-'}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="lastside-column">
                            {parse(location.search).compareSiteFlag || IsSiteComparisionClicked ? (
                              <div className="compare-checkbox">
                                <div className="checkbox-content">
                                  <Checkbox
                                    className={
                                      checkDefaultChecked(recod.site_id) ? 'check' : 'uncheck'
                                    }
                                    onChange={(e) => onCheckboxChange(e, recod.site_id)}
                                    checked={checkDefaultChecked(recod.site_id)}
                                  />
                                </div>
                              </div>
                            ) : (
                              <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                                <Button
                                  className="accordion-menu"
                                  role="presentation"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Tooltip title="Kebab menu">
                                    <div className="accordion-menu-icon" />
                                  </Tooltip>
                                </Button>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="pi-name-content">
                        <ReadMore limit={5}>
                          {recod.pis.map((piitm, idy) => {
                            return (
                              <div className="pi-content" key={idy}>
                                <div className="body-analysis-section">
                                  <div className="leftside-column">
                                    {!IsComparisionClicked && (
                                      <div className="checkbox-filter">
                                        <Checkbox
                                          checked={
                                            isSelectAllChecked
                                            || checkedPiList.includes(piitm.innoplexus_id)
                                          }
                                          disabled={isSelectAllChecked}
                                          onChange={() => handlePiCheckbox(
                                            piitm.innoplexus_id,
                                            piitm.status,
                                            piitm.pi_id,
                                            piitm.originating_party,
                                            piitm.compliance_issue_prevention_flag,
                                            piitm.is_added,
                                            piitm.is_added_by_sponsor,
                                          )}
                                        />
                                      </div>
                                    )}
                                    <div className="meta-link" />
                                    <a
                                      className="pi-content-title"
                                      title={piitm.pi_name}
                                      target="_blank"
                                      href={`/search/investigatorprofile?${stringify({
                                        query: piitm.pi_name,
                                        id: piitm.pi_id,
                                        type: 'PI',
                                      })}`}
                                      rel="noreferrer noopener"
                                    >
                                      {piitm.pi_name}
                                    </a>
                                    {piitm.is_new ? (
                                      <div className="new-label">
                                        <div
                                          className="new-label-icon"
                                          title="This investigator was not part of the results earlier"
                                        />
                                      </div>
                                    ) : null}
                                    <div className="comments">
                                      <Comment
                                        projectId={parse(location.search).project_id}
                                        analysisId={parse(location.search).analysis_id}
                                        piId={piitm.pi_id}
                                        type="pi"
                                        sitePIExtraType="specific"
                                        count={JSON.stringify(piitm.comments)}
                                        is_lcor={adminType.includes('lcor_access')}
                                      />
                                    </div>
                                    {piitm.compliance && piitm.compliance !== 'No Issues' ? (
                                      <div
                                        className={`${
                                          piitm.compliance
                                            === 'Compliance preventing participation'
                                          || piitm.compliance === 'DNU'
                                            ? 'flag filled red'
                                            : piitm.compliance
                                                === 'Compliance not preventing participation'
                                              || piitm.compliance === 'Approved with Warning'
                                              ? 'flag filled light-red'
                                              : ''
                                        }`}
                                      >
                                        <Popover
                                          overlayClassName="flag-section-content flag-section-content-compliance"
                                          placement="right"
                                          title={(
                                            <div
                                              className={`${
                                                piitm.compliance
                                                  === 'Compliance preventing participation'
                                                || piitm.compliance === 'DNU'
                                                  ? 'flag-title filled red'
                                                  : piitm.compliance
                                                      === 'Compliance not preventing participation'
                                                    || piitm.compliance === 'Approved with Warning'
                                                    ? 'flag-title filled light-red'
                                                    : ''
                                              }`}
                                            >
                                              GCP Compliance & CDD
                                            </div>
                                          )}
                                          content={(
                                            <div className="flag-data">
                                              <div className="flag-data-title-create">
                                                <div className="flag-data-content-value">
                                                  <>
                                                    {piitm?.cdd_data?.length
                                                      ? piitm?.cdd_data?.map((item) => (
                                                        <>
                                                          <div
                                                            style={{ 'white-space': 'pre-line' }}
                                                          >
                                                            CDD Status : {item.status}
                                                          </div>
                                                          <br />
                                                          <div
                                                            style={{ 'white-space': 'pre-line' }}
                                                          >
                                                            CDD Date:{' '}
                                                            {item.date ? item.date : '-'}
                                                          </div>
                                                          <br />
                                                          <div
                                                            style={{ 'white-space': 'pre-line' }}
                                                          >
                                                            CDD Comment:{' '}
                                                            {item.comment ? item.comment : '-'}
                                                          </div>
                                                          <br />
                                                          <hr />
                                                        </>
                                                      ))
                                                      : undefined}
                                                    {piitm?.compliances?.length
                                                      && piitm?.compliances?.map((itm1) => {
                                                        return (
                                                          <>
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Compliance Preventing Participation :{' '}
                                                              {itm1.no_trial_participation_flag
                                                              === 'N'
                                                                ? 'No'
                                                                : itm1.no_trial_participation_flag
                                                                  === 'Y'
                                                                  ? 'Yes'
                                                                  : itm1.no_trial_participation_flag
                                                                  === 'NO'
                                                                    ? 'No'
                                                                    : itm1.no_trial_participation_flag
                                                                  === 'YES'
                                                                      ? 'Yes'
                                                                      : itm1.no_trial_participation_flag}
                                                            </div>
                                                            <br />

                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Compliance Issue Comment:{' '}
                                                              {itm1.compliance_issue_comment
                                                                ? itm1.compliance_issue_comment
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Compliance Issue Description:{' '}
                                                              {itm1.compliance_issue_desc
                                                                ? itm1.compliance_issue_desc
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Compliance Issue Raised:{' '}
                                                              {itm1.compliance_issue_raised_dt
                                                              && itm1.compliance_issue_raised_dt.length
                                                                ? itm1.compliance_issue_raised_dt.split(
                                                                  ' ',
                                                                )[0]
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Reviewed Status:{' '}
                                                              {itm1.review_status
                                                                ? itm1.review_status
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Review Date:{' '}
                                                              {itm1.review_date
                                                              && itm1.review_date.length
                                                                ? itm1.review_date.split(' ')[0]
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Reviewer Name:{' '}
                                                              {itm1.reviewer_name
                                                                ? itm1.reviewer_name
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Re-evaluation Comment:{' '}
                                                              {itm1.reevaluation_comment
                                                                ? itm1.reevaluation_comment
                                                                : '-'}
                                                            </div>
                                                            <hr />
                                                          </>
                                                        );
                                                      })}
                                                  </>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          trigger="click"
                                        >
                                          <div className="flag-img" />
                                        </Popover>
                                      </div>
                                    ) : null}
                                    {piitm.emerging_kol.length ? (
                                      <Tooltip
                                        title={(
                                          <div className="tooltip-title">
                                            <div>Emerging Category : {piitm.emerging_data.flag === 'Medium' ? 'Moderate'
                                              : piitm.emerging_data.flag === 'High' ? 'High'
                                                : piitm.emerging_data.flag === 'Low' ? 'Low' : ''}
                                            </div>
                                            <div className="tooltip-theraprutic-area">
                                              Therapy Area : {piitm.emerging_data.therapeutic_area}
                                            </div>
                                            <div className="tooltip-score">
                                              Score : {piitm.emerging_data.score}
                                            </div>
                                          </div>
                                        )}
                                      >
                                        <div
                                          className={`${
                                            piitm.emerging_data.flag === 'Medium'
                                              ? 'project-result Medium-flag'
                                              : piitm.emerging_data.flag === 'Low'
                                                ? 'project-result Low-flag'
                                                : piitm.emerging_data.flag === 'High'
                                                  ? 'project-result High-flag'
                                                  : ''
                                          }`}
                                        >
                                          <Popover
                                            overlayClassName="flag-section-content flag-section-content-project-result"
                                            placement="right"
                                            title={(
                                              <div
                                                className={`${
                                                  piitm.emerging_data.flag === 'Medium'
                                                    ? 'project-result Medium-flag'
                                                    : piitm.emerging_data.flag === 'Low'
                                                      ? 'project-result Low-flag'
                                                      : piitm.emerging_data.flag === 'High'
                                                        ? 'project-result High-flag'
                                                        : ''
                                                }`}
                                              >
                                                TA wise Emerging KOL Score
                                              </div>
                                            )}
                                            content={(
                                              <>
                                                <div className="emerging-investigator-title">
                                                  <div className="emerging-investigator-ta">Primary TA : &nbsp;</div>
                                                  {Object.keys(piitm.emerging_data).length ? (<div className="emerging-investigator-"> {piitm.emerging_data.therapeutic_area}</div>) : ''}
                                                </div>
                                                <div className="emerging-investigator-right">
                                                  <div
                                                    className="emerging-investigator-header"
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent: 'space-around',
                                                    }}
                                                  >

                                                    <div className="emerging-investigator-header-th theraprutic-area">
                                                      {' '}
                                                      Therapy Area{' '}
                                                    </div>
                                                    <div className="emerging-investigator-header-th">
                                                      {' '}
                                                      Score{' '}
                                                    </div>
                                                    <div className="emerging-investigator-header-th">
                                                      {' '}
                                                      Emerging KOL Class{' '}
                                                    </div>
                                                  </div>
                                                  <div className="emerging-investigator-body">
                                                    {piitm.emerging_kol?.length
                                                      ? piitm.emerging_kol?.map((itm1) => (
                                                        <>
                                                          <div
                                                            className="emerging-investigator-header-tr"
                                                            style={{
                                                              display: 'flex',
                                                              justifyContent: 'space-around',
                                                            }}
                                                          >
                                                            <div
                                                              className="emerging-investigator-header-td therapeutic-area"
                                                              style={{ display: 'flex' }}
                                                            >
                                                              <div className="emerging-investigator-header-td ta-value">
                                                                {itm1.therapeutic_area}
                                                              </div>
                                                            </div>
                                                            <div className="emerging-investigator-header-td score">
                                                              {itm1.score}
                                                            </div>
                                                            <div className="emerging-investigator-header-td">
                                                              <div className="emerging-investigator-flag">
                                                                {itm1.flag === 'Medium' ? (
                                                                  <span
                                                                    className="Medium-flag"
                                                                    title="Moderate"
                                                                  />
                                                                ) : itm1.flag === 'Low' ? (
                                                                  <span
                                                                    className="Low-flag"
                                                                    title="Low"
                                                                  />
                                                                ) : itm1.flag === 'High' ? (
                                                                  <span
                                                                    className="High-flag"
                                                                    title="High"
                                                                  />
                                                                ) : (
                                                                  ''
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ))
                                                      : ''}
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                            trigger="click"
                                          >
                                            <div className="flag-img" />
                                          </Popover>
                                        </div>
                                      </Tooltip>
                                    ) : null}
                                    <div
                                      className={
                                        piitm.alliancePresent
                                          ? 'primary-org-change-alliance'
                                          : 'primary-org-change'
                                      }
                                    >
                                      <Button
                                        onClick={() => setOrgChangeModalOpen({
                                          isOpen: true,
                                          piId: piitm.pi_id,
                                          siteId: piitm.site_id,
                                          isAlliance: piitm.alliancePresent,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="table-column">
                                    <div
                                      className="table-column-sec table-column-body"
                                      onScroll={onRowScroll}
                                    >
                                      {checkColumnDisplay('Feasibility Status') ? (
                                        <div className="filter-sel-data-value status">
                                          {getCflStatus(piitm, 'cfl')}{' '}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Feasibility Status Date') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm.last_cfl_status_updated_on
                                            ? getFormattedDate(
                                              piitm.last_cfl_status_updated_on * 1000,
                                            )
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('LCOR Status') ? (
                                        <div className="filter-sel-data-value status">
                                          {getCflStatus(piitm, 'lcor')}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Sponsor Status') ? (
                                        <div className="filter-sel-data-value status">
                                          {getCflStatus(piitm, 'sponsor')}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('LCOR Status Date') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm.last_lcor_status_updated_on
                                            ? getFormattedDate(
                                              piitm.last_lcor_status_updated_on * 1000,
                                            )
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Analysis ID') ? (
                                        <div className="filter-sel-data-value analysis-id">
                                          {piitm.analysis_seq_id ? piitm.analysis_seq_id : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Priority') ? (
                                        <div className="filter-sel-data-value priority">
                                          <Select
                                            onChange={(e) => handlePriorityForSingle(
                                              piitm.innoplexus_id,
                                              e,
                                                piitm?.originating_party,
                                                piitm.pi_id,
                                            )}
                                            // defaultValue={piitm.priority ? piitm.priority : null}
                                            value={getPriority(piitm)}
                                            options={priorityList}
                                            disabled={
                                              isCheckedboxEnabled
                                              || !(
                                                adminType.includes('lcor_access')
                                                || adminType.includes('cfl_access')
                                              )
                                              || isSelectAllChecked
                                            }
                                          />
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Origin') ? (
                                        <div className="filter-sel-data-value origin">
                                          {getOrigin(piitm)}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Originating Party') ? (
                                        <div className="filter-sel-data-value party">
                                          <div className="filter-sel-data-value priority">
                                            <Select
                                              onChange={(e) => handleOriginForSingle(
                                                piitm.innoplexus_id,
                                                e,
                                                piitm.pi_id,
                                              )}
                                              // defaultValue={piitm.originating_party ? piitm.originating_party : null}
                                              value={getParty(piitm)}
                                              options={originatingParty}
                                              disabled={
                                                isCheckedboxEnabled
                                                || !(
                                                  adminType.includes('lcor_access')
                                                  || adminType.includes('cfl_access')
                                                )
                                                || isSelectAllChecked
                                              }
                                              style={{ width: '120px' }}
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Version') ? (
                                        <div className="filter-sel-data-value priority">
                                          <Select
                                            onChange={(e) => handleVersionForSingle(
                                              piitm.innoplexus_id,
                                              e,
                                              piitm.pi_id,
                                            )}
                                            // defaultValue={itm.originating_party ? itm.originating_party : null}
                                            value={getVersion(piitm)}
                                            options={versionList}
                                            disabled={
                                              isCheckedboxEnabled
                                              || !(
                                                adminType.includes('lcor_access')
                                                || adminType.includes('cfl_access')
                                              )
                                              || isSelectAllChecked
                                            }
                                          />
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Alliance Member Name') ? (
                                        <div className="filter-sel-data-value status">
                                          {piitm.alliance_member_name
                                            ? piitm.alliance_member_name
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Date Added') ? (
                                        <div className="filter-sel-data-value status">
                                          {piitm.date_added
                                            ? getFormattedDate(piitm.date_added * 1000)
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Alliance Contact') ? (
                                        <div className="filter-sel-data-value status">
                                          {piitm.alliance_contact?.full_name
                                            ? piitm.alliance_contact?.full_name
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Parexel Contact') ? (
                                        <div className="filter-sel-data-value status">
                                          {piitm.parexel_contact?.full_name
                                            ? piitm.parexel_contact?.full_name
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('LCOR Comment') ? (
                                        <div className="filter-sel-data-value status">
                                          {piitm.lcor_comment ? piitm.lcor_comment : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('CDA Expiry') ? (
                                        <div className="filter-sel-data-value expiry">
                                          {piitm.cda_expiry ? piitm.cda_expiry : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Query ID') ? (
                                        <div className="filter-sel-data-value query-id">
                                          {piitm.query_seq_id ? piitm.query_seq_id : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('# Industry Studies') ? (
                                        <Button
                                          className="filter-sel-data-value industry-studies"
                                          onClick={(e) => onIndustryClick(
                                            e,
                                            piitm.pi_id,
                                            piitm.industry_studies_source,
                                            piitm.analysis_id,
                                          )}
                                        >
                                          {piitm.industry_studies}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('VAT Invoice Present') ? (
                                        <div className="filter-sel-data-value industry-studies">
                                          {piitm.vat_invoice}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Competing Studies') ? (
                                        <Button
                                          className="filter-sel-data-value center"
                                          onClick={() => onCompetingClick(piitm.pi_id, piitm.analysis_id)}
                                        >
                                          {piitm.competing_studies}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('# PXL Studies') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onPxlClick(piitm.pi_id, piitm.analysis_id)}
                                        >
                                          {piitm.pxl_studies}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('Tier (Search Specific)') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onTierClick(piitm.pi_id)}
                                        >
                                          {piitm.tier}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('Profile Tier(General)') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onProfileTierClick(record.pi_id)}
                                        >
                                          {piitm.p_tier}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('Specialities') ? (
                                        <div className="filter-sel-data-value pxl-studies">
                                          {piitm?.specialties?.length
                                            ? piitm.specialties.join(', ')
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Enrolled Subjects') ? (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.enrolled_count}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Quality') ? (
                                        <div className="filter-sel-data-value quality">
                                          <Rate count={3} disabled value={piitm.quality_rating} />
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Reliability') ? (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {piitm.reliability_class}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Reliability Score') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onReliabilityClick(piitm)}
                                        >
                                          {piitm.reliability_score}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('KOL Score') ? (
                                        <Button className="filter-sel-data-value pxl-studies" onClick={() => onKolScoreClick(piitm)}>{piitm?.cls_kol_score?.toFixed(3)}</Button>
                                        // <div className="filter-sel-data-value center">
                                        //   {piitm.cls_kol_score}
                                        // </div>
                                      ) : null}
                                      {checkColumnDisplay('Start Up Months') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm.p_start_up ? piitm.p_start_up.toFixed(2) : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Start Up Index') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm.startup_index
                                            ? piitm.startup_index.toFixed(2)
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Recruitment Factor') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm.p_recruitment
                                            ? piitm.p_recruitment.toFixed(2)
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Last Trial Start Date') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm.recent_trial_start_date_ts
                                            ? getFormattedDate(
                                              piitm.recent_trial_start_date_ts * 1000,
                                            )
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Center') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm.site_name}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('City') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm?.city !== '' ? piitm?.city : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('State') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm?.state !== '' ? piitm?.state : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Country') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm.country}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Alliance Type') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm.alliance_type}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Networks') ? (
                                        <div className="filter-sel-data-value center">
                                          {piitm.networks && piitm.networks.length
                                            ? piitm.networks.join('; ')
                                            : '-'}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="lastside-column">
                                    {parse(location.search).compareFlag || IsComparisionClicked ? (
                                      <div className="compare-checkbox">
                                        <div className="checkbox-content">
                                          <Checkbox
                                            className={
                                              checkDefaultChecked(piitm.pi_id) ? 'check' : 'uncheck'
                                            }
                                            key={idy}
                                            onChange={(e) => onCheckboxChange(e, piitm.pi_id)}
                                            checked={checkDefaultChecked(piitm.pi_id)}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <Dropdown overlay={() => dataMenu(piitm)} trigger={['click']}>
                                        <Button
                                          className="accordion-menu"
                                          role="presentation"
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <Tooltip title="Kebab menu">
                                            <div className="accordion-menu-icon" />
                                          </Tooltip>
                                        </Button>
                                      </Dropdown>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>
        );
      }

      case 'country,parent_institutes,primary_organizations,pis': {
        if (record?.country[0]?.country !== showCountryHierarchy && !countryClose) {
          setShowCountryHierarchy(record?.country[0]?.country);
        }
        return (
          <div className="country-runanalysis-wrap country-check-tbl-view">
            <div
              className="card-content-title body-analysis-section"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className="leftside-column first-sec-country"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <a
                  title={record.country[0]?.country}
                  target="_blank"
                  href={`/search/countryprofile?${stringify({
                    country: record.country[0]?.country,
                    type: 'COUNTRY',
                    projectId: parse(location.search).project_id,
                    analysisId: parse(location.search).analysis_id,
                  })}`}
                  rel="noreferrer noopener"
                >
                  <div className="country-name">
                    <span className="country-name-title">{record.country[0]?.country}</span>
                  </div>
                </a>
                <div
                  className="country-name-no-arrow"
                  role="presentation"
                  onClick={() => {
                    if (showCountryHierarchy !== record.country[0]?.country) {
                      setShowCountryHierarchy(record.country[0]?.country);
                      setCountryClose(false);
                    } else {
                      setShowCountryHierarchy('');
                      setCountryClose(true);
                    }
                  }}
                >
                  <span
                    className={`country-name-title ${
                      showCountryHierarchy === record.country[0].country
                        ? 'dropdown-close'
                        : 'dropdown-open'
                    }`}
                  >
                    <span className="dropdown-arrow" />
                  </span>
                </div>
              </div>
              <div className="table-column">
                <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                  {checkColumnDisplay('Feasibility Status') ? (
                    <div className="filter-sel-data-value status">
                      {record.country[0]?.country.status ? record.country[0]?.country.status : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Feasibility Status Date') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.last_cfl_status_updated_on
                        ? getFormattedDate(
                            record.country[0]?.country.last_cfl_status_updated_on * 1000,
                        )
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('LCOR Status') ? (
                    <div className="filter-sel-data-value status">
                      {record.country[0]?.country.lcor_status
                        ? record.country[0]?.country.lcor_status
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Sponsor Status') ? (
                    <div className="filter-sel-data-value status">
                      -
                    </div>
                  ) : null}
                  {checkColumnDisplay('LCOR Status Date') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.last_lcor_status_updated_on
                        ? getFormattedDate(
                            record.country[0]?.country.last_lcor_status_updated_on * 1000,
                        )
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Analysis ID') ? (
                    <div className="filter-sel-data-value analysis-id">
                      {record.country[0]?.country.analysis_seq_id
                        ? record.country[0]?.country.analysis_seq_id
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Priority') ? (
                    <div className="filter-sel-data-value priority">-</div>
                  ) : null}
                  {checkColumnDisplay('Origin') ? (
                    <div className="filter-sel-data-value origin">
                      {record.country[0]?.country.origin ? record.country[0]?.country.origin : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Originating Party') ? (
                    <div className="filter-sel-data-value party">
                      {record.country[0]?.country.originating_party
                        ? record.country[0]?.country.originating_party
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Version') ? (
                    <div className="filter-sel-data-value priority">-</div>
                  ) : null}
                  {checkColumnDisplay('Alliance Member Name') ? (
                    <div className="filter-sel-data-value status">
                      {record.country[0]?.country.alliance_member_name
                        ? record.country[0]?.country.alliance_member_name
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Date Added') ? (
                    <div className="filter-sel-data-value status">
                      {record.country[0]?.date_added
                        ? getFormattedDate(record.country[0]?.date_added * 1000)
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Alliance Contact') ? (
                    <div className="filter-sel-data-value status">
                      {record.country[0]?.country.alliance_contact?.full_name
                        ? record.country[0]?.country.alliance_contact?.full_name
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Parexel Contact') ? (
                    <div className="filter-sel-data-value status">
                      {record.country[0]?.country?.parexel_contact?.full_name
                        ? record.country[0]?.country?.parexel_contact?.full_name
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('LCOR Comment') ? (
                    <div className="filter-sel-data-value status">-</div>
                  ) : null}
                  {checkColumnDisplay('CDA Expiry') ? (
                    <div className="filter-sel-data-value expiry">
                      {record.country[0]?.country.cda_expiry
                        ? record.country[0]?.country.cda_expiry
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Query ID') ? (
                    <div className="filter-sel-data-value query-id">
                      {record.country[0]?.country.query_seq_id
                        ? record.country[0]?.country.query_seq_id
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('# Industry Studies') ? (
                    <div className="filter-sel-data-value industry-studies">
                      {record.country[0]?.industry_studies}
                    </div>
                  ) : null}
                  {checkColumnDisplay('VAT Invoice Present') ? (
                    <div className="filter-sel-data-value industry-studies">-</div>
                  ) : null}
                  {checkColumnDisplay('Competing Studies') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.competing_studies}
                    </div>
                  ) : null}
                  {checkColumnDisplay('# PXL Studies') ? (
                    <div className="filter-sel-data-value pxl-studies">
                      {record.country[0]?.pxl_studies}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Tier (Search Specific)') ? (
                    <div className="filter-sel-data-value pxl-studies">-</div>
                  ) : null}
                  {checkColumnDisplay('Profile Tier(General)') ? (
                    <div className="filter-sel-data-value pxl-studies">{record.p_tier}</div>
                  ) : null}
                  {checkColumnDisplay('Specialities') ? (
                    <div className="filter-sel-data-value pxl-studies">
                      {record?.specialties?.length ? record.specialties.join(', ') : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Enrolled Subjects') ? (
                    <div className="filter-sel-data-value enrolled-subjects">
                      {record.country[0]?.enrolled_count}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Quality') ? (
                    <div className="filter-sel-data-value quality">
                      <Rate count={3} disabled value={record.country[0]?.country.quality_rating} />
                    </div>
                  ) : null}
                  {checkColumnDisplay('Reliability') ? (
                    <div className="filter-sel-data-value enrolled-subjects">
                      {record.country[0]?.country.reliability_class}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Reliability Score') ? (
                    <Button
                      className="filter-sel-data-value pxl-studies"
                      onClick={() => onReliabilityClick(record.country[0]?.country)}
                    >
                      {record.country[0]?.country.reliability_score}
                    </Button>
                  ) : null}
                  {checkColumnDisplay('KOL Score') ? (
                    <Button className="filter-sel-data-value pxl-studies" onClick={() => onKolScoreClick(record?.country[0]?.country)}>{record?.country[0]?.country?.cls_kol_score?.toFixed(3)}</Button>
                    // <div className="filter-sel-data-value center">
                    //   {record.country[0]?.country.cls_kol_score}
                    // </div>
                  ) : null}
                  {checkColumnDisplay('Start Up Months') ? (
                    <div className="filter-sel-data-value center">-</div>
                  ) : null}
                  {checkColumnDisplay('Start Up Index') ? (
                    <div className="filter-sel-data-value center">{record.country[0]?.country.startup_index.toFixed(2)}</div>
                  ) : null}
                  {checkColumnDisplay('Recruitment Factor') ? (
                    <div className="filter-sel-data-value center">{record.country[0]?.country.p_recruitment ? record.country[0]?.country.p_recruitment.toFixed(2) : '-'}</div>
                  ) : null}
                  {checkColumnDisplay('Last Trial Start Date') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.recent_trial_start_date_ts
                        ? getFormattedDate(
                            record.country[0]?.country.recent_trial_start_date_ts * 1000,
                        )
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Center') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.site_name}
                    </div>
                  ) : null}
                  {checkColumnDisplay('City') ? (
                    <div className="filter-sel-data-value center">-</div>
                  ) : null}
                  {checkColumnDisplay('State') ? (
                    <div className="filter-sel-data-value center">-</div>
                  ) : null}
                  {checkColumnDisplay('Country') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.country}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Alliance Type') ? (
                    <div className="filter-sel-data-value center">Not applicable</div>
                  ) : null}
                  {checkColumnDisplay('Networks') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.networks
                      && record.country[0]?.country.networks.length
                        ? record.country[0]?.country.networks.join('; ')
                        : '-'}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="lastside-column" />
            </div>
            {showCountryHierarchy === record.country[0]?.country
            && record.parent_institutes
            && record.parent_institutes.length ? (
              <>
                {record.parent_institutes.map((rcd, indexv) => (
                  <div className="parent-site-wrap" key={indexv}>
                    <div className="run-query-content-section">
                      <div className="run-query-content-card card-country-content card">
                        <div className="card-content card-country-content-header">
                          <div className="body-analysis-section level-two-header-section">
                            <div
                              className={`leftside-column ${
                                showPisOfParent === rcd.parent_id
                                  ? 'dropdown-close'
                                  : rcd.pis.length
                                    ? 'dropdown-open'
                                    : ''
                              }`}
                            >
                              <a
                                className="card-content-title"
                                title={rcd.parent_name}
                                target="_blank"
                                href={`/search/deepdives?${stringify({
                                  query: rcd.parent_name,
                                  id: rcd.parent_id,
                                  type: 'SITE',
                                  currentTab: 'parent_centers',
                                })}`}
                                rel="noreferrer noopener"
                              >
                                {rcd.parent_name}
                              </a>
                              <div className="population-flag" style={{ marginLeft: '10px' }}>
                                <Tooltip
                                  title={renderTooltipTitleForAmerican(
                                    rcd.primary_organizations.map(
                                      (data) => data.american_indian_origin_category,
                                    )[0],
                                  )}
                                >
                                  {rcd.primary_organizations.map(
                                    (data) => data.american_indian_origin_category,
                                  )[0] === 'High' ? (
                                    <span className="High-population-icon-for-Alaska" />
                                    ) : rcd.primary_organizations.map(
                                      (data) => data.american_indian_origin_category,
                                    )[0] === 'Low' ? (
                                      <span className="Low-population-icon-for-Alaska" />
                                      ) : rcd.primary_organizations.map(
                                        (data) => data.american_indian_origin_category,
                                      )[0] === 'Medium' ? (
                                        <span className="Medium-population-icon-for-Alaska" />
                                        ) : (
                                          ''
                                        )}
                                </Tooltip>
                                <Tooltip
                                  title={renderTooltipTitleForHawaiian(
                                    rcd.primary_organizations.map(
                                      (data) => data.native_hawaiian_origin_category,
                                    )[0],
                                  )}
                                >
                                  {rcd.primary_organizations.map(
                                    (data) => data.native_hawaiian_origin_category,
                                  )[0] === 'High' ? (
                                    <span className="High-population-icon-for-Hawaii" />
                                    ) : rcd.primary_organizations.map(
                                      (data) => data.native_hawaiian_origin_category,
                                    )[0] === 'Low' ? (
                                      <span className="Low-population-icon-for-Hawaii" />
                                      ) : rcd.primary_organizations.map(
                                        (data) => data.native_hawaiian_origin_category,
                                      )[0] === 'Medium' ? (
                                        <span className="Medium-population-icon-for-Hawaii" />
                                        ) : (
                                          ''
                                        )}
                                </Tooltip>
                              </div>
                              <span
                                className="dropdown-arrow"
                                role="presentation"
                                onClick={() => {
                                  if (showPisOfParent !== rcd.parent_id) {
                                    if (rcd.pis.length) {
                                      setShowPisOfParent(rcd.parent_id);
                                    }
                                  } else {
                                    setShowPisOfParent('');
                                  }
                                }}
                              />
                            </div>
                            <div className="table-column">
                              <div
                                className="table-column-sec table-column-body"
                                onScroll={onRowScroll}
                              >
                                {checkColumnDisplay('Feasibility Status') ? (
                                  <div className="filter-sel-data-value status">
                                    {rcd.status ? rcd.status : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Feasibility Status Date') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.last_cfl_status_updated_on
                                      ? getFormattedDate(rcd.last_cfl_status_updated_on * 1000)
                                      : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('LCOR Status') ? (
                                  <div className="filter-sel-data-value status">
                                    {rcd.lcor_status ? rcd.lcor_status : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Sponsor Status') ? (
                                  <div className="filter-sel-data-value status">
                                    {rcd.sponsor_status ? rcd.sponsor_status : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('LCOR Status Date') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.last_lcor_status_updated_on
                                      ? getFormattedDate(rcd.last_lcor_status_updated_on * 1000)
                                      : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Analysis ID') ? (
                                  <div className="filter-sel-data-value analysis-id">
                                    {rcd.analysis_seq_id ? rcd.analysis_seq_id : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Priority') ? (
                                  <div className="filter-sel-data-value priority">-</div>
                                ) : null}
                                {checkColumnDisplay('Origin') ? (
                                  <div className="filter-sel-data-value origin">-</div>
                                ) : null}
                                {checkColumnDisplay('Originating Party') ? (
                                  <div className="filter-sel-data-value party">-</div>
                                ) : null}
                                {checkColumnDisplay('Version') ? (
                                  <div className="filter-sel-data-value priority">-</div>
                                ) : null}
                                {checkColumnDisplay('Alliance Member Name') ? (
                                  <div className="filter-sel-data-value status">-</div>
                                ) : null}
                                {checkColumnDisplay('Date Added') ? (
                                  <div className="filter-sel-data-value status">-</div>
                                ) : null}
                                {checkColumnDisplay('Alliance Contact') ? (
                                  <div className="filter-sel-data-value status">-</div>
                                ) : null}
                                {checkColumnDisplay('Parexel Contact') ? (
                                  <div className="filter-sel-data-value status">-</div>
                                ) : null}
                                {checkColumnDisplay('LCOR Comment') ? (
                                  <div className="filter-sel-data-value status">-</div>
                                ) : null}
                                {checkColumnDisplay('CDA Expiry') ? (
                                  <div className="filter-sel-data-value expiry">-</div>
                                ) : null}
                                {checkColumnDisplay('Query ID') ? (
                                  <div className="filter-sel-data-value query-id">
                                    {rcd.query_seq_id ? rcd.query_seq_id : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('# Industry Studies') ? (
                                  <div className="filter-sel-data-value industry-studies">
                                    {rcd.industry_studies}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('VAT Invoice Present') ? (
                                  <div className="filter-sel-data-value industry-studies">
                                    {rcd.vat_invoice}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Competing Studies') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.competing_studies}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('# PXL Studies') ? (
                                  <div className="filter-sel-data-value pxl-studies">
                                    {rcd.pxl_studies}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Tier (Search Specific)') ? (
                                  <Button
                                    className="filter-sel-data-value pxl-studies"
                                    onClick={() => onTierClick(rcd)}
                                  >
                                    {rcd.tier}
                                  </Button>
                                ) : null}
                                {checkColumnDisplay('Profile Tier(General)') ? (
                                  <Button
                                    className="filter-sel-data-value pxl-studies"
                                    onClick={() => onProfileTierClick(record.pi_id)}
                                  >
                                    {rcd.p_tier}
                                  </Button>
                                ) : null}
                                {checkColumnDisplay('Specialities') ? (
                                  <div className="filter-sel-data-value pxl-studies">
                                    {rcd?.specialties?.length ? rcd.specialties.join(', ') : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Enrolled Subjects') ? (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {rcd.enrolled_count}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Quality') ? (
                                  <div className="filter-sel-data-value quality">
                                    <Rate count={3} disabled value={rcd.quality_rating} />
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Reliability') ? (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {rcd.reliability_class}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Reliability Score') ? (
                                  <Button
                                    className="filter-sel-data-value pxl-studies"
                                    onClick={() => onReliabilityClick(rcd)}
                                  >
                                    {rcd.reliability_score}
                                  </Button>
                                ) : null}
                                {checkColumnDisplay('KOL Score') ? (
                                  <div className="filter-sel-data-value center">Not applicable</div>
                                ) : null}
                                {checkColumnDisplay('Start Up Months') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.p_start_up ? rcd.p_start_up.toFixed(2) : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Start Up Index') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.startup_index ? rcd.startup_index.toFixed(2) : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Recruitment Factor') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.p_recruitment ? rcd.p_recruitment.toFixed(2) : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Last Trial Start Date') ? (
                                  <div className="filter-sel-data-value center">Not applicable</div>
                                ) : null}
                                {checkColumnDisplay('Center') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.site_name}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('City') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd?.city !== '' ? rcd?.city : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('State') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd?.state !== '' ? rcd?.state : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Country') ? (
                                  <div className="filter-sel-data-value center">{rcd.country}</div>
                                ) : null}
                                {checkColumnDisplay('Alliance Type') ? (
                                  <div className="filter-sel-data-value center">Not applicable</div>
                                ) : null}
                                {checkColumnDisplay('Networks') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.networks && rcd.networks.length
                                      ? rcd.networks.join('; ')
                                      : '-'}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="lastside-column">
                              {parse(location.search).compareSiteFlag
                              || IsSiteComparisionClicked ? (
                                <div className="compare-checkbox">
                                  <div className="checkbox-content">
                                    <Checkbox
                                      className={
                                        checkDefaultChecked(rcd.parent_id) ? 'check' : 'uncheck'
                                      }
                                      onChange={(e) => onCheckboxChange(e, rcd.parent_id)}
                                      checked={checkDefaultChecked(rcd.parent_id)}
                                    />
                                  </div>
                                </div>
                                ) : (
                                  <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                                    <Button
                                      className="accordion-menu"
                                      role="presentation"
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Tooltip title="Kebab menu">
                                        <div className="accordion-menu-icon" />
                                      </Tooltip>
                                    </Button>
                                  </Dropdown>
                                )}
                            </div>
                          </div>
                        </div>
                        {showPisOfParent === rcd.parent_id && rcd.pis.length ? (
                          <div className="pi-name-content">
                            <ReadMore limit={5}>
                              {rcd.pis.map((itm, idx) => {
                                return (
                                  <div className="pi-content">
                                    <div className="body-analysis-section">
                                      <div className="leftside-column">
                                        {!IsComparisionClicked && (
                                          <div className="checkbox-filter">
                                            <Checkbox
                                              checked={
                                                isSelectAllChecked
                                                || checkedPiList.includes(itm.innoplexus_id)
                                              }
                                              disabled={isSelectAllChecked}
                                              onChange={() => handlePiCheckbox(
                                                itm.innoplexus_id,
                                                itm.status,
                                                itm.pi_id,
                                                itm.originating_party,
                                                itm.compliance_issue_prevention_flag,
                                                itm.is_added,
                                                itm.is_added_by_sponsor,
                                              )}
                                            />
                                          </div>
                                        )}
                                        <div className="meta-link" />
                                        <a
                                          className="pi-content-title"
                                          title={itm.pi_name}
                                          target="_blank"
                                          href={`/search/investigatorprofile?${stringify({
                                            query: itm.pi_name,
                                            id: itm.pi_id,
                                            type: 'PI',
                                          })}`}
                                          rel="noreferrer noopener"
                                        >
                                          {itm.pi_name}
                                        </a>
                                        {itm.is_new ? (
                                          <div className="new-label">
                                            <div
                                              className="new-label-icon"
                                              title="This investigator was not part of the results earlier"
                                            />
                                          </div>
                                        ) : null}
                                        <div className="comments">
                                          <Comment
                                            projectId={parse(location.search).project_id}
                                            analysisId={parse(location.search).analysis_id}
                                            piId={itm.pi_id}
                                            type="pi"
                                            sitePIExtraType="specific"
                                            count={JSON.stringify(itm.comments)}
                                            is_lcor={adminType.includes('lcor_access')}
                                          />
                                        </div>
                                        {itm.compliance && itm.compliance !== 'No Issues' ? (
                                          <div
                                            className={`${
                                              itm.compliance
                                                === 'Compliance preventing participation'
                                              || itm.compliance === 'DNU'
                                                ? 'flag filled red'
                                                : itm.compliance
                                                    === 'Compliance not preventing participation'
                                                  || itm.compliance === 'Approved with Warning'
                                                  ? 'flag filled light-red'
                                                  : ''
                                            }`}
                                          >
                                            <Popover
                                              overlayClassName="flag-section-content flag-section-content-compliance"
                                              placement="right"
                                              title={(
                                                <div
                                                  className={`${
                                                    itm.compliance
                                                      === 'Compliance preventing participation'
                                                    || itm.compliance === 'DNU'
                                                      ? 'flag-title filled red'
                                                      : itm.compliance
                                                          === 'Compliance not preventing participation'
                                                        || itm.compliance === 'Approved with Warning'
                                                        ? 'flag-title filled light-red'
                                                        : ''
                                                  }`}
                                                >
                                                  GCP Compliance & CDD
                                                </div>
                                              )}
                                              content={(
                                                <div className="flag-data">
                                                  <div className="flag-data-title-create">
                                                    <div className="flag-data-content-value">
                                                      <>
                                                        {itm?.cdd_data?.length
                                                          ? itm?.cdd_data?.map((item) => (
                                                            <>
                                                              <div
                                                                style={{
                                                                  'white-space': 'pre-line',
                                                                }}
                                                              >
                                                                CDD Status : {item.status}
                                                              </div>
                                                              <br />
                                                              <div
                                                                style={{
                                                                  'white-space': 'pre-line',
                                                                }}
                                                              >
                                                                CDD Date:{' '}
                                                                {item.date ? item.date : '-'}
                                                              </div>
                                                              <br />
                                                              <div
                                                                style={{
                                                                  'white-space': 'pre-line',
                                                                }}
                                                              >
                                                                CDD Comment:{' '}
                                                                {item.comment
                                                                  ? item.comment
                                                                  : '-'}
                                                              </div>
                                                              <br />
                                                            </>
                                                          ))
                                                          : undefined}
                                                        {itm?.compliances?.length
                                                          && itm?.compliances?.map((itm1) => {
                                                            return (
                                                              <>
                                                                <div
                                                                  style={{
                                                                    'white-space': 'pre-line',
                                                                  }}
                                                                >
                                                                  Compliance Preventing
                                                                  Participation :{' '}
                                                                  {itm1.no_trial_participation_flag
                                                                  === 'N'
                                                                    ? 'No'
                                                                    : itm1.no_trial_participation_flag
                                                                      === 'Y'
                                                                      ? 'Yes'
                                                                      : itm1.no_trial_participation_flag
                                                                      === 'NO'
                                                                        ? 'No'
                                                                        : itm1.no_trial_participation_flag
                                                                      === 'YES'
                                                                          ? 'Yes'
                                                                          : itm1.no_trial_participation_flag}
                                                                </div>
                                                                <br />

                                                                <div
                                                                  style={{
                                                                    'white-space': 'pre-line',
                                                                  }}
                                                                >
                                                                  Compliance Issue Comment:{' '}
                                                                  {itm1.compliance_issue_comment
                                                                    ? itm1.compliance_issue_comment
                                                                    : '-'}
                                                                </div>
                                                                <br />
                                                                <div
                                                                  style={{
                                                                    'white-space': 'pre-line',
                                                                  }}
                                                                >
                                                                  Compliance Issue Description:{' '}
                                                                  {itm1.compliance_issue_desc
                                                                    ? itm1.compliance_issue_desc
                                                                    : '-'}
                                                                </div>
                                                                <br />
                                                                <div
                                                                  style={{
                                                                    'white-space': 'pre-line',
                                                                  }}
                                                                >
                                                                  Compliance Issue Raised:{' '}
                                                                  {itm1.compliance_issue_raised_dt
                                                                  && itm1.compliance_issue_raised_dt
                                                                    .length
                                                                    ? itm1.compliance_issue_raised_dt.split(
                                                                      ' ',
                                                                    )[0]
                                                                    : '-'}
                                                                </div>
                                                                <br />
                                                                <div
                                                                  style={{
                                                                    'white-space': 'pre-line',
                                                                  }}
                                                                >
                                                                  Reviewed Status:{' '}
                                                                  {itm1.review_status
                                                                    ? itm1.review_status
                                                                    : '-'}
                                                                </div>
                                                                <br />
                                                                <div
                                                                  style={{
                                                                    'white-space': 'pre-line',
                                                                  }}
                                                                >
                                                                  Review Date:{' '}
                                                                  {itm1.review_date
                                                                  && itm1.review_date.length
                                                                    ? itm1.review_date.split(' ')[0]
                                                                    : '-'}
                                                                </div>
                                                                <br />
                                                                <div
                                                                  style={{
                                                                    'white-space': 'pre-line',
                                                                  }}
                                                                >
                                                                  Reviewer Name:{' '}
                                                                  {itm1.reviewer_name
                                                                    ? itm1.reviewer_name
                                                                    : '-'}
                                                                </div>
                                                                <br />
                                                                <div
                                                                  style={{
                                                                    'white-space': 'pre-line',
                                                                  }}
                                                                >
                                                                  Re-evaluation Comment:{' '}
                                                                  {itm1.reevaluation_comment
                                                                    ? itm1.reevaluation_comment
                                                                    : '-'}
                                                                </div>
                                                                <hr />
                                                              </>
                                                            );
                                                          })}
                                                      </>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                              trigger="click"
                                            >
                                              <div className="flag-img" />
                                            </Popover>
                                          </div>
                                        ) : null}
                                        {itm.emerging_kol.length ? (
                                          <Tooltip
                                            title={(
                                              <div className="tooltip-title">
                                                <div>Emerging Category : {itm.emerging_data.flag === 'Medium' ? 'Moderate'
                                                  : itm.emerging_data.flag === 'High' ? 'High'
                                                    : itm.emerging_data.flag === 'Low' ? 'Low' : ''}
                                                </div>
                                                <div className="tooltip-theraprutic-area">
                                                  Therapy Area :{' '}
                                                  {itm.emerging_data.therapeutic_area}
                                                </div>
                                                <div className="tooltip-score">
                                                  Score : {itm.emerging_data.score}
                                                </div>
                                              </div>
                                            )}
                                          >
                                            <div
                                              className={`${
                                                itm.emerging_data.flag === 'Medium'
                                                  ? 'project-result Medium-flag'
                                                  : itm.emerging_data.flag === 'Low'
                                                    ? 'project-result Low-flag'
                                                    : itm.emerging_data.flag === 'High'
                                                      ? 'project-result High-flag'
                                                      : ''
                                              }`}
                                            >
                                              <Popover
                                                overlayClassName="flag-section-content flag-section-content-project-result"
                                                placement="right"
                                                title={(
                                                  <div
                                                    className={`${
                                                      itm.emerging_data.flag === 'Medium'
                                                        ? 'project-result Medium-flag'
                                                        : itm.emerging_data.flag === 'Low'
                                                          ? 'project-result Low-flag'
                                                          : itm.emerging_data.flag === 'High'
                                                            ? 'project-result High-flag'
                                                            : ''
                                                    }`}
                                                  >
                                                    TA wise Emerging KOL Score
                                                  </div>
                                                )}
                                                content={(
                                                  <>
                                                    <div className="emerging-investigator-title">
                                                      <div className="emerging-investigator-ta">Primary TA : &nbsp;</div>
                                                      {Object.keys(itm.emerging_data).length ? (<div className="emerging-investigator-"> {itm.emerging_data.therapeutic_area}</div>) : ''}
                                                    </div>
                                                    <div className="emerging-investigator-right">
                                                      <div
                                                        className="emerging-investigator-header"
                                                        style={{
                                                          display: 'flex',
                                                          justifyContent: 'space-around',
                                                        }}
                                                      >

                                                        <div className="emerging-investigator-header-th theraprutic-area">
                                                          {' '}
                                                          Therapy Area{' '}
                                                        </div>
                                                        <div className="emerging-investigator-header-th">
                                                          {' '}
                                                          Score{' '}
                                                        </div>
                                                        <div className="emerging-investigator-header-th">
                                                          {' '}
                                                          Emerging KOL Class{' '}
                                                        </div>
                                                      </div>
                                                      <div className="emerging-investigator-body">
                                                        {itm.emerging_kol?.length
                                                          ? itm.emerging_kol?.map((itm1) => (
                                                            <>
                                                              <div
                                                                className="emerging-investigator-header-tr"
                                                                style={{
                                                                  display: 'flex',
                                                                  justifyContent: 'space-around',
                                                                }}
                                                              >
                                                                <div
                                                                  className="emerging-investigator-header-td therapeutic-area"
                                                                  style={{ display: 'flex' }}
                                                                >
                                                                  <div className="emerging-investigator-header-td ta-value">
                                                                    {itm1.therapeutic_area}
                                                                  </div>
                                                                </div>
                                                                <div className="emerging-investigator-header-td score">
                                                                  {itm1.score}
                                                                </div>
                                                                <div className="emerging-investigator-header-td">
                                                                  <div className="emerging-investigator-flag">
                                                                    {itm1.flag === 'Medium' ? (
                                                                      <span
                                                                        className="Medium-flag"
                                                                        title="Moderate"
                                                                      />
                                                                    ) : itm1.flag === 'Low' ? (
                                                                      <span
                                                                        className="Low-flag"
                                                                        title="Low"
                                                                      />
                                                                    ) : itm1.flag === 'High' ? (
                                                                      <span
                                                                        className="High-flag"
                                                                        title="High"
                                                                      />
                                                                    ) : (
                                                                      ''
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          ))
                                                          : ''}
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                                trigger="click"
                                              >
                                                <div className="flag-img" />
                                              </Popover>
                                            </div>
                                          </Tooltip>
                                        ) : null}
                                        <div
                                          className={
                                            itm.alliancePresent
                                              ? 'primary-org-change-alliance'
                                              : 'primary-org-change'
                                          }
                                        >
                                          <Button
                                            onClick={() => setOrgChangeModalOpen({
                                              isOpen: true,
                                              piId: itm.pi_id,
                                              siteId: itm.site_id,
                                              isAlliance: itm.alliancePresent,
                                            })}
                                          />
                                        </div>
                                      </div>
                                      <div className="table-column">
                                        <div
                                          className="table-column-sec table-column-body"
                                          onScroll={onRowScroll}
                                        >
                                          {checkColumnDisplay('Feasibility Status') ? (
                                            <div className="filter-sel-data-value status">
                                              {getCflStatus(itm, 'cfl')}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Feasibility Status Date') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm.last_cfl_status_updated_on
                                                ? getFormattedDate(
                                                  itm.last_cfl_status_updated_on * 1000,
                                                )
                                                : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('LCOR Status') ? (
                                            <div className="filter-sel-data-value status">
                                              {getCflStatus(itm, 'lcor')}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Sponsor Status') ? (
                                            <div className="filter-sel-data-value status">
                                              {getCflStatus(itm, 'sponsor')}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('LCOR Status Date') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm.last_lcor_status_updated_on
                                                ? getFormattedDate(
                                                  itm.last_lcor_status_updated_on * 1000,
                                                )
                                                : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Analysis ID') ? (
                                            <div className="filter-sel-data-value analysis-id">
                                              {itm.analysis_seq_id ? itm.analysis_seq_id : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Priority') ? (
                                            <div className="filter-sel-data-value priority">
                                              <Select
                                                onChange={(e) => handlePriorityForSingle(
                                                  itm.innoplexus_id,
                                                  e,
                                                    itm?.originating_party,
                                                    itm.pi_id,
                                                )}
                                                // defaultValue={itm.priority ? itm.priority : null}
                                                value={getPriority(itm)}
                                                options={priorityList}
                                                disabled={
                                                  isCheckedboxEnabled
                                                  || !(
                                                    adminType.includes('lcor_access')
                                                    || adminType.includes('cfl_access')
                                                  )
                                                  || isSelectAllChecked
                                                }
                                              />
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Origin') ? (
                                            <div className="filter-sel-data-value origin">
                                              {getOrigin(itm)}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Originating Party') ? (
                                            <div className="filter-sel-data-value party">
                                              <div className="filter-sel-data-value priority">
                                                <Select
                                                  onChange={(e) => handleOriginForSingle(
                                                    itm.innoplexus_id,
                                                    e,
                                                    itm.pi_id,
                                                  )}
                                                  // defaultValue={itm.originating_party ? itm.originating_party : null}
                                                  value={getParty(itm)}
                                                  options={originatingParty}
                                                  disabled={
                                                    isCheckedboxEnabled
                                                    || !(
                                                      adminType.includes('lcor_access')
                                                      || adminType.includes('cfl_access')
                                                    )
                                                    || isSelectAllChecked
                                                  }
                                                  style={{ width: '120px' }}
                                                />
                                              </div>
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Version') ? (
                                            <div className="filter-sel-data-value priority">
                                              <Select
                                                onChange={(e) => handleVersionForSingle(
                                                  itm.innoplexus_id,
                                                  e,
                                                  itm.pi_id,
                                                )}
                                                // defaultValue={itm.originating_party ? itm.originating_party : null}
                                                value={getVersion(itm)}
                                                options={versionList}
                                                disabled={
                                                  isCheckedboxEnabled
                                                  || !(
                                                    adminType.includes('lcor_access')
                                                    || adminType.includes('cfl_access')
                                                  )
                                                  || isSelectAllChecked
                                                }
                                              />
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Alliance Member Name') ? (
                                            <div className="filter-sel-data-value status">
                                              {itm.alliance_member_name
                                                ? itm.alliance_member_name
                                                : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Date Added') ? (
                                            <div className="filter-sel-data-value status">
                                              {itm.date_added
                                                ? getFormattedDate(itm.date_added * 1000)
                                                : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Alliance Contact') ? (
                                            <div className="filter-sel-data-value status">
                                              {itm.alliance_contact?.full_name
                                                ? itm.alliance_contact?.full_name
                                                : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Parexel Contact') ? (
                                            <div className="filter-sel-data-value status">
                                              {itm.parexel_contact?.full_name
                                                ? itm.parexel_contact?.full_name
                                                : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('LCOR Comment') ? (
                                            <div className="filter-sel-data-value status">
                                              {itm.lcor_comment ? itm.lcor_comment : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('CDA Expiry') ? (
                                            <div className="filter-sel-data-value expiry">
                                              {itm.cda_expiry ? itm.cda_expiry : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Query ID') ? (
                                            <div className="filter-sel-data-value query-id">
                                              {itm.query_seq_id ? itm.query_seq_id : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('# Industry Studies') ? (
                                            <Button
                                              className="filter-sel-data-value industry-studies"
                                              onClick={(e) => onIndustryClick(
                                                e,
                                                itm.pi_id,
                                                itm.industry_studies_source,
                                                itm.analysis_id,
                                              )}
                                            >
                                              {itm.industry_studies}
                                            </Button>
                                          ) : null}
                                          {checkColumnDisplay('VAT Invoice Present') ? (
                                            <div className="filter-sel-data-value industry-studies">
                                              {itm.vat_invoice}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Competing Studies') ? (
                                            <Button
                                              className="filter-sel-data-value center"
                                              onClick={() => onCompetingClick(itm.pi_id, itm.analysis_id)}
                                            >
                                              {itm.competing_studies}
                                            </Button>
                                          ) : null}
                                          {checkColumnDisplay('# PXL Studies') ? (
                                            <Button
                                              className="filter-sel-data-value pxl-studies"
                                              onClick={() => onPxlClick(itm.pi_id, itm.analysis_id)}
                                            >
                                              {itm.pxl_studies}
                                            </Button>
                                          ) : null}
                                          {checkColumnDisplay('Tier (Search Specific)') ? (
                                            <Button
                                              className="filter-sel-data-value pxl-studies"
                                              onClick={() => onTierClick(itm)}
                                            >
                                              {itm.tier}
                                            </Button>
                                          ) : null}
                                          {checkColumnDisplay('Profile Tier(General)') ? (
                                            <Button
                                              className="filter-sel-data-value pxl-studies"
                                              onClick={() => onProfileTierClick(itm)}
                                            >
                                              {itm.p_tier}
                                            </Button>
                                          ) : null}
                                          {checkColumnDisplay('Specialities') ? (
                                            <div className="filter-sel-data-value pxl-studies">
                                              {itm?.specialties.length
                                                ? itm?.specialties.join(', ')
                                                : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Enrolled Subjects') ? (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {itm.enrolled_count}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Quality') ? (
                                            <div className="filter-sel-data-value quality">
                                              <Rate count={3} disabled value={itm.quality_rating} />
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Reliability') ? (
                                            <div className="filter-sel-data-value enrolled-subjects">
                                              {itm.reliability_class}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Reliability Score') ? (
                                            <Button
                                              className="filter-sel-data-value pxl-studies"
                                              onClick={() => onReliabilityClick(itm)}
                                            >
                                              {itm.reliability_score}
                                            </Button>
                                          ) : null}
                                          {checkColumnDisplay('KOL Score') ? (
                                            <Button className="filter-sel-data-value pxl-studies" onClick={() => onKolScoreClick(itm)}>{itm?.cls_kol_score?.toFixed(3)}</Button>
                                            // <div className="filter-sel-data-value center">
                                            //   {itm.cls_kol_score}
                                            // </div>
                                          ) : null}
                                          {checkColumnDisplay('Start Up Months') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm.p_start_up ? itm.p_start_up.toFixed(2) : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Start Up Index') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm.startup_index
                                                ? itm.startup_index.toFixed(2)
                                                : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Recruitment Factor') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm.p_recruitment
                                                ? itm.p_recruitment.toFixed(2)
                                                : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Last Trial Start Date') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm.recent_trial_start_date_ts
                                                ? getFormattedDate(
                                                  itm.recent_trial_start_date_ts * 1000,
                                                )
                                                : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Center') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm.site_name}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('City') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm?.city !== '' ? itm?.city : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('State') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm?.state !== '' ? itm?.state : '-'}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Country') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm.country}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Alliance Type') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm.alliance_type}
                                            </div>
                                          ) : null}
                                          {checkColumnDisplay('Networks') ? (
                                            <div className="filter-sel-data-value center">
                                              {itm.networks && itm.networks.length
                                                ? itm.networks.join('; ')
                                                : '-'}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="lastside-column">
                                        {parse(location.search).compareFlag
                                        || IsComparisionClicked ? (
                                          <div className="compare-checkbox">
                                            <div className="checkbox-content">
                                              <Checkbox
                                                className={
                                                  checkDefaultChecked(itm.pi_id)
                                                    ? 'check'
                                                    : 'uncheck'
                                                }
                                                key={idx}
                                                onChange={(e) => onCheckboxChange(e, itm.pi_id)}
                                                checked={checkDefaultChecked(itm.pi_id)}
                                              />
                                            </div>
                                          </div>
                                          ) : (
                                            <Dropdown
                                              overlay={() => dataMenu(itm)}
                                              trigger={['click']}
                                            >
                                              <Button
                                                className="accordion-menu"
                                                role="presentation"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                }}
                                              >
                                                <Tooltip title="Kebab menu">
                                                  <div className="accordion-menu-icon" />
                                                </Tooltip>
                                              </Button>
                                            </Dropdown>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </ReadMore>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {rcd.primary_organizations && rcd.primary_organizations.length ? (
                      <>
                        {rcd.primary_organizations.map((recod, indexVal) => (
                          <div
                            className="run-query-content-section run-query-content-section-child"
                            key={indexVal}
                          >
                            <div className="run-query-content-card card-country-content card">
                              <div className="card-content card-country-content-header">
                                <div className="body-analysis-section level-two-header-section">
                                  <div className="leftside-column">
                                    <div className="fav" />
                                    <a
                                      className="card-content-title"
                                      title={recod.site_name}
                                      target="_blank"
                                      href={`/search/deepdives?${stringify({
                                        query: recod.site_name,
                                        id: recod.site_id,
                                        type: 'SITE',
                                        currentTab: recod.parent_flag
                                          ? 'parent_centers'
                                          : 'centers',
                                      })}`}
                                      rel="noreferrer noopener"
                                    >
                                      {recod.site_name}
                                    </a>
                                    <div className="comments">
                                      <Comment
                                        projectId={parse(location.search).project_id}
                                        analysisId={parse(location.search).analysis_id}
                                        siteId={recod.site_id}
                                        type="site"
                                        sitePIExtraType="specific"
                                        count={JSON.stringify(recod.comments)}
                                      />
                                    </div>
                                    <div className="population-flag">
                                      <Tooltip
                                        title={renderTooltipTitleForAmerican(
                                          recod.american_indian_origin_category,
                                        )}
                                      >
                                        {recod.american_indian_origin_category === 'High' ? (
                                          <span className="High-population-icon-for-Alaska" />
                                        ) : recod.american_indian_origin_category === 'Low' ? (
                                          <span className="Low-population-icon-for-Alaska" />
                                        ) : recod.american_indian_origin_category === 'Medium' ? (
                                          <span className="Medium-population-icon-for-Alaska" />
                                        ) : (
                                          ''
                                        )}
                                      </Tooltip>
                                      <Tooltip
                                        title={renderTooltipTitleForHawaiian(
                                          recod.native_hawaiian_origin_category,
                                        )}
                                      >
                                        {recod.native_hawaiian_origin_category === 'High' ? (
                                          <span className="High-population-icon-for-Hawaii" />
                                        ) : recod.native_hawaiian_origin_category === 'Low' ? (
                                          <span className="Low-population-icon-for-Hawaii" />
                                        ) : recod.native_hawaiian_origin_category === 'Medium' ? (
                                          <span className="Medium-population-icon-for-Hawaii" />
                                        ) : (
                                          ''
                                        )}
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div className="table-column">
                                    <div
                                      className="table-column-sec table-column-body"
                                      onScroll={onRowScroll}
                                    >
                                      {checkColumnDisplay('Feasibility Status') ? (
                                        <div className="filter-sel-data-value status">
                                          {recod.status ? recod.status : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Feasibility Status Date') ? (
                                        <div className="filter-sel-data-value center">
                                          {recod.last_cfl_status_updated_on
                                            ? getFormattedDate(
                                              recod.last_cfl_status_updated_on * 1000,
                                            )
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('LCOR Status') ? (
                                        <div className="filter-sel-data-value status">
                                          {recod.lcor_status ? recod.lcor_status : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Sponsor Status') ? (
                                        <div className="filter-sel-data-value status">
                                          {recod.sponsor_status ? recod.sponsor_status : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('LCOR Status Date') ? (
                                        <div className="filter-sel-data-value center">
                                          {recod.last_lcor_status_updated_on
                                            ? getFormattedDate(
                                              recod.last_lcor_status_updated_on * 1000,
                                            )
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Analysis ID') ? (
                                        <div className="filter-sel-data-value analysis-id">
                                          {recod.analysis_seq_id ? recod.analysis_seq_id : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Priority') ? (
                                        <div className="filter-sel-data-value priority">-</div>
                                      ) : null}
                                      {checkColumnDisplay('Origin') ? (
                                        <div className="filter-sel-data-value origin">-</div>
                                      ) : null}
                                      {checkColumnDisplay('Originating Party') ? (
                                        <div className="filter-sel-data-value party">-</div>
                                      ) : null}
                                      {checkColumnDisplay('Version') ? (
                                        <div className="filter-sel-data-value priority">-</div>
                                      ) : null}
                                      {checkColumnDisplay('Alliance Member Name') ? (
                                        <div className="filter-sel-data-value status">-</div>
                                      ) : null}
                                      {checkColumnDisplay('Date Added') ? (
                                        <div className="filter-sel-data-value status">-</div>
                                      ) : null}
                                      {checkColumnDisplay('Alliance Contact') ? (
                                        <div className="filter-sel-data-value status">-</div>
                                      ) : null}
                                      {checkColumnDisplay('Parexel Contact') ? (
                                        <div className="filter-sel-data-value status">-</div>
                                      ) : null}
                                      {checkColumnDisplay('LCOR Comment') ? (
                                        <div className="filter-sel-data-value status">-</div>
                                      ) : null}
                                      {checkColumnDisplay('CDA Expiry') ? (
                                        <div className="filter-sel-data-value expiry">-</div>
                                      ) : null}
                                      {checkColumnDisplay('Query ID') ? (
                                        <div className="filter-sel-data-value query-id">
                                          {recod.query_seq_id ? recod.query_seq_id : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('# Industry Studies') ? (
                                        <div className="filter-sel-data-value industry-studies">
                                          {recod.industry_studies}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('VAT Invoice Present') ? (
                                        <div className="filter-sel-data-value industry-studies">
                                          {recod.vat_invoice}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Competing Studies') ? (
                                        <div className="filter-sel-data-value center">
                                          {recod.competing_studies}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('# PXL Studies') ? (
                                        <div className="filter-sel-data-value pxl-studies">
                                          {recod.pxl_studies}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Tier (Search Specific)') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onTierClick(recod)}
                                        >
                                          {recod.tier}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('Profile Tier(General)') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onProfileTierClick(recod)}
                                        >
                                          {recod.p_tier}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('Specialities') ? (
                                        <div className="filter-sel-data-value pxl-studies">
                                          {recod?.specialties?.length
                                            ? recod.specialties.join(', ')
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Enrolled Subjects') ? (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {recod.enrolled_count}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Quality') ? (
                                        <div className="filter-sel-data-value quality">
                                          <Rate count={3} disabled value={recod.quality_rating} />
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Reliability') ? (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {recod.reliability_class}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Reliability Score') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onReliabilityClick(recod)}
                                        >
                                          {recod.reliability_score}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('KOL Score') ? (
                                        <div className="filter-sel-data-value center">
                                          Not applicable
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Start Up Months') ? (
                                        <div className="filter-sel-data-value center">
                                          {recod.p_start_up ? recod.p_start_up.toFixed(2) : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Start Up Index') ? (
                                        <div className="filter-sel-data-value center">
                                          {recod.startup_index
                                            ? recod.startup_index.toFixed(2)
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Recruitment Factor') ? (
                                        <div className="filter-sel-data-value center">
                                          {recod.p_recruitment
                                            ? recod.p_recruitment.toFixed(2)
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Last Trial Start Date') ? (
                                        <div className="filter-sel-data-value center">
                                          Not applicable
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Center') ? (
                                        <div className="filter-sel-data-value center">
                                          {recod.site_name}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('City') ? (
                                        <div className="filter-sel-data-value center">
                                          {recod?.city !== '' ? recod?.city : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('State') ? (
                                        <div className="filter-sel-data-value center">
                                          {recod?.state !== '' ? recod?.state : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Country') ? (
                                        <div className="filter-sel-data-value center">
                                          {recod.country}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Alliance Type') ? (
                                        <div className="filter-sel-data-value center">
                                          Not applicable
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Networks') ? (
                                        <div className="filter-sel-data-value center">
                                          {recod.networks && recod.networks.length
                                            ? recod.networks.join('; ')
                                            : '-'}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="lastside-column">
                                    {parse(location.search).compareSiteFlag
                                    || IsSiteComparisionClicked ? (
                                      <div className="compare-checkbox">
                                        <div className="checkbox-content">
                                          <Checkbox
                                            className={
                                              checkDefaultChecked(recod.site_id)
                                                ? 'check'
                                                : 'uncheck'
                                            }
                                            onChange={(e) => onCheckboxChange(e, recod.site_id)}
                                            checked={checkDefaultChecked(recod.site_id)}
                                          />
                                        </div>
                                      </div>
                                      ) : (
                                        <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                                          <Button
                                            className="accordion-menu"
                                            role="presentation"
                                            onClick={(e) => {
                                              e.preventDefault();
                                            }}
                                          >
                                            <Tooltip title="Kebab menu">
                                              <div className="accordion-menu-icon" />
                                            </Tooltip>
                                          </Button>
                                        </Dropdown>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="pi-name-content">
                                <ReadMore limit={5}>
                                  {recod.pis.map((piitm, idy) => {
                                    return (
                                      <div className="pi-content" key={idy}>
                                        <div className="body-analysis-section">
                                          <div className="leftside-column">
                                            {!IsComparisionClicked && (
                                              <div className="checkbox-filter">
                                                <Checkbox
                                                  checked={
                                                    isSelectAllChecked
                                                    || checkedPiList.includes(piitm.innoplexus_id)
                                                  }
                                                  disabled={isSelectAllChecked}
                                                  onChange={() => handlePiCheckbox(
                                                    piitm.innoplexus_id,
                                                    piitm.status,
                                                    piitm.pi_id,
                                                    piitm.originating_party,
                                                    piitm.compliance_issue_prevention_flag,
                                                    piitm.is_added,
                                                    piitm.is_added_by_sponsor,
                                                  )}
                                                />
                                              </div>
                                            )}
                                            <div className="meta-link" />
                                            <a
                                              className="pi-content-title"
                                              title={piitm.pi_name}
                                              target="_blank"
                                              href={`/search/investigatorprofile?${stringify({
                                                query: piitm.pi_name,
                                                id: piitm.pi_id,
                                                type: 'PI',
                                              })}`}
                                              rel="noreferrer noopener"
                                            >
                                              {piitm.pi_name}
                                            </a>
                                            {piitm.is_new ? (
                                              <div className="new-label">
                                                <div
                                                  className="new-label-icon"
                                                  title="This investigator was not part of the results earlier"
                                                />
                                              </div>
                                            ) : null}
                                            <div className="comments">
                                              <Comment
                                                projectId={parse(location.search).project_id}
                                                analysisId={parse(location.search).analysis_id}
                                                piId={piitm.pi_id}
                                                type="pi"
                                                sitePIExtraType="specific"
                                                count={JSON.stringify(piitm.comments)}
                                                is_lcor={adminType.includes('lcor_access')}
                                              />
                                            </div>
                                            {piitm.compliance
                                            && piitm.compliance !== 'No Issues' ? (
                                              <div
                                                className={`${
                                                  piitm.compliance
                                                    === 'Compliance preventing participation'
                                                  || piitm.compliance === 'DNU'
                                                    ? 'flag filled red'
                                                    : piitm.compliance
                                                        === 'Compliance not preventing participation'
                                                      || piitm.compliance === 'Approved with Warning'
                                                      ? 'flag filled light-red'
                                                      : ''
                                                }`}
                                              >
                                                <Popover
                                                  overlayClassName="flag-section-content flag-section-content-compliance"
                                                  placement="right"
                                                  title={(
                                                    <div
                                                      className={`${
                                                        piitm.compliance
                                                          === 'Compliance preventing participation'
                                                        || piitm.compliance === 'DNU'
                                                          ? 'flag-title filled red'
                                                          : piitm.compliance
                                                              === 'Compliance not preventing participation'
                                                            || piitm.compliance
                                                              === 'Approved with Warning'
                                                            ? 'flag-title filled light-red'
                                                            : ''
                                                      }`}
                                                    >
                                                      GCP Compliance & CDD
                                                    </div>
                                                  )}
                                                  content={(
                                                    <div className="flag-data">
                                                      <div className="flag-data-title-create">
                                                        <div className="flag-data-content-value">
                                                          <>
                                                            {piitm?.cdd_data?.length
                                                              ? piitm?.cdd_data?.map((item) => (
                                                                <>
                                                                  <div
                                                                    style={{
                                                                      'white-space': 'pre-line',
                                                                    }}
                                                                  >
                                                                    CDD Status : {item.status}
                                                                  </div>
                                                                  <br />
                                                                  <div
                                                                    style={{
                                                                      'white-space': 'pre-line',
                                                                    }}
                                                                  >
                                                                    CDD Date:{' '}
                                                                    {item.date ? item.date : '-'}
                                                                  </div>
                                                                  <br />
                                                                  <div
                                                                    style={{
                                                                      'white-space': 'pre-line',
                                                                    }}
                                                                  >
                                                                    CDD Comment:{' '}
                                                                    {item.comment
                                                                      ? item.comment
                                                                      : '-'}
                                                                  </div>
                                                                  <br />
                                                                  <hr />
                                                                </>
                                                              ))
                                                              : undefined}
                                                            {piitm?.compliances?.length
                                                              && piitm?.compliances?.map((itm1) => {
                                                                return (
                                                                  <>
                                                                    <div
                                                                      style={{
                                                                        'white-space': 'pre-line',
                                                                      }}
                                                                    >
                                                                      Compliance Preventing
                                                                      Participation :{' '}
                                                                      {itm1.no_trial_participation_flag
                                                                      === 'N'
                                                                        ? 'No'
                                                                        : itm1.no_trial_participation_flag
                                                                          === 'Y'
                                                                          ? 'Yes'
                                                                          : itm1.no_trial_participation_flag
                                                                          === 'NO'
                                                                            ? 'No'
                                                                            : itm1.no_trial_participation_flag
                                                                          === 'YES'
                                                                              ? 'Yes'
                                                                              : itm1.no_trial_participation_flag}
                                                                    </div>
                                                                    <br />

                                                                    <div
                                                                      style={{
                                                                        'white-space': 'pre-line',
                                                                      }}
                                                                    >
                                                                      Compliance Issue Comment:{' '}
                                                                      {itm1.compliance_issue_comment
                                                                        ? itm1.compliance_issue_comment
                                                                        : '-'}
                                                                    </div>
                                                                    <br />
                                                                    <div
                                                                      style={{
                                                                        'white-space': 'pre-line',
                                                                      }}
                                                                    >
                                                                      Compliance Issue Description:{' '}
                                                                      {itm1.compliance_issue_desc
                                                                        ? itm1.compliance_issue_desc
                                                                        : '-'}
                                                                    </div>
                                                                    <br />
                                                                    <div
                                                                      style={{
                                                                        'white-space': 'pre-line',
                                                                      }}
                                                                    >
                                                                      Compliance Issue Raised:{' '}
                                                                      {itm1.compliance_issue_raised_dt
                                                                      && itm1
                                                                        .compliance_issue_raised_dt
                                                                        .length
                                                                        ? itm1.compliance_issue_raised_dt.split(
                                                                          ' ',
                                                                        )[0]
                                                                        : '-'}
                                                                    </div>
                                                                    <br />
                                                                    <div
                                                                      style={{
                                                                        'white-space': 'pre-line',
                                                                      }}
                                                                    >
                                                                      Reviewed Status:{' '}
                                                                      {itm1.review_status
                                                                        ? itm1.review_status
                                                                        : '-'}
                                                                    </div>
                                                                    <br />
                                                                    <div
                                                                      style={{
                                                                        'white-space': 'pre-line',
                                                                      }}
                                                                    >
                                                                      Review Date:{' '}
                                                                      {itm1.review_date
                                                                      && itm1.review_date.length
                                                                        ? itm1.review_date.split(
                                                                          ' ',
                                                                        )[0]
                                                                        : '-'}
                                                                    </div>
                                                                    <br />
                                                                    <div
                                                                      style={{
                                                                        'white-space': 'pre-line',
                                                                      }}
                                                                    >
                                                                      Reviewer Name:{' '}
                                                                      {itm1.reviewer_name
                                                                        ? itm1.reviewer_name
                                                                        : '-'}
                                                                    </div>
                                                                    <br />
                                                                    <div
                                                                      style={{
                                                                        'white-space': 'pre-line',
                                                                      }}
                                                                    >
                                                                      Re-evaluation Comment:{' '}
                                                                      {itm1.reevaluation_comment
                                                                        ? itm1.reevaluation_comment
                                                                        : '-'}
                                                                    </div>
                                                                    <hr />
                                                                  </>
                                                                );
                                                              })}
                                                          </>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                  trigger="click"
                                                >
                                                  <div className="flag-img" />
                                                </Popover>
                                              </div>
                                              ) : null}
                                            {piitm.emerging_kol.length ? (
                                              <Tooltip
                                                title={(
                                                  <div className="tooltip-title">
                                                    <div>Emerging Category : {piitm.emerging_data.flag === 'Medium' ? 'Moderate'
                                                      : piitm.emerging_data.flag === 'High' ? 'High'
                                                        : piitm.emerging_data.flag === 'Low' ? 'Low' : ''}
                                                    </div>
                                                    <div className="tooltip-theraprutic-area">
                                                      Therapy Area :{' '}
                                                      {piitm.emerging_data.therapeutic_area}
                                                    </div>
                                                    <div className="tooltip-score">
                                                      Score : {piitm.emerging_data.score}
                                                    </div>
                                                  </div>
                                                )}
                                              >
                                                <div
                                                  className={`${
                                                    piitm.emerging_data.flag === 'Medium'
                                                      ? 'project-result Medium-flag'
                                                      : piitm.emerging_data.flag === 'Low'
                                                        ? 'project-result Low-flag'
                                                        : piitm.emerging_data.flag === 'High'
                                                          ? 'project-result High-flag'
                                                          : ''
                                                  }`}
                                                >
                                                  <Popover
                                                    overlayClassName="flag-section-content flag-section-content-project-result"
                                                    placement="right"
                                                    title={(
                                                      <div
                                                        className={`${
                                                          piitm.emerging_data.flag === 'Medium'
                                                            ? 'project-result Medium-flag'
                                                            : piitm.emerging_data.flag === 'Low'
                                                              ? 'project-result Low-flag'
                                                              : piitm.emerging_data.flag === 'High'
                                                                ? 'project-result High-flag'
                                                                : ''
                                                        }`}
                                                      >
                                                        TA wise Emerging KOL Score
                                                      </div>
                                                    )}
                                                    content={(
                                                      <>
                                                        <div className="emerging-investigator-title">
                                                          <div className="emerging-investigator-ta">Primary TA : &nbsp;</div>
                                                          {Object.keys(piitm.emerging_data).length ? (<div className="emerging-investigator-"> {piitm.emerging_data.therapeutic_area}</div>) : ''}
                                                        </div>
                                                        <div className="emerging-investigator-right">
                                                          <div
                                                            className="emerging-investigator-header"
                                                            style={{
                                                              display: 'flex',
                                                              justifyContent: 'space-around',
                                                            }}
                                                          >

                                                            <div className="emerging-investigator-header-th theraprutic-area">
                                                              {' '}
                                                              Therapy Area{' '}
                                                            </div>
                                                            <div className="emerging-investigator-header-th">
                                                              {' '}
                                                              Score{' '}
                                                            </div>
                                                            <div className="emerging-investigator-header-th">
                                                              {' '}
                                                              Emerging KOL Class{' '}
                                                            </div>
                                                          </div>
                                                          <div className="emerging-investigator-body">
                                                            {piitm.emerging_kol?.length
                                                              ? piitm.emerging_kol?.map((itm1) => (
                                                                <>
                                                                  <div
                                                                    className="emerging-investigator-header-tr"
                                                                    style={{
                                                                      display: 'flex',
                                                                      justifyContent:
                                                                        'space-around',
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className="emerging-investigator-header-td therapeutic-area"
                                                                      style={{ display: 'flex' }}
                                                                    >
                                                                      <div className="emerging-investigator-header-td ta-value">
                                                                        {itm1.therapeutic_area}
                                                                      </div>
                                                                    </div>
                                                                    <div className="emerging-investigator-header-td score">
                                                                      {itm1.score}
                                                                    </div>
                                                                    <div className="emerging-investigator-header-td">
                                                                      <div className="emerging-investigator-flag">
                                                                        {itm1.flag === 'Medium' ? (
                                                                          <span
                                                                            className="Medium-flag"
                                                                            title="Moderate"
                                                                          />
                                                                        ) : itm1.flag === 'Low' ? (
                                                                          <span
                                                                            className="Low-flag"
                                                                            title="Low"
                                                                          />
                                                                        ) : itm1.flag === 'High' ? (
                                                                          <span
                                                                            className="High-flag"
                                                                            title="High"
                                                                          />
                                                                        ) : (
                                                                          ''
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ))
                                                              : ''}
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                    trigger="click"
                                                  >
                                                    <div className="flag-img" />
                                                  </Popover>
                                                </div>
                                              </Tooltip>
                                            ) : null}
                                            <div
                                              className={
                                                piitm.alliancePresent
                                                  ? 'primary-org-change-alliance'
                                                  : 'primary-org-change'
                                              }
                                            >
                                              <Button
                                                onClick={() => setOrgChangeModalOpen({
                                                  isOpen: true,
                                                  piId: piitm.pi_id,
                                                  siteId: piitm.site_id,
                                                  isAlliance: piitm.alliancePresent,
                                                })}
                                              />
                                            </div>
                                          </div>
                                          <div className="table-column">
                                            <div
                                              className="table-column-sec table-column-body"
                                              onScroll={onRowScroll}
                                            >
                                              {checkColumnDisplay('Feasibility Status') ? (
                                                <div className="filter-sel-data-value status">
                                                  {getCflStatus(piitm, 'cfl')}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Feasibility Status Date') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm.last_cfl_status_updated_on
                                                    ? getFormattedDate(
                                                      piitm.last_cfl_status_updated_on * 1000,
                                                    )
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('LCOR Status') ? (
                                                <div className="filter-sel-data-value status">
                                                  {getCflStatus(piitm, 'lcor')}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Sponsor Status') ? (
                                                <div className="filter-sel-data-value status">
                                                  {getCflStatus(piitm, 'sponsor')}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('LCOR Status Date') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm.last_lcor_status_updated_on
                                                    ? getFormattedDate(
                                                      piitm.last_lcor_status_updated_on * 1000,
                                                    )
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Analysis ID') ? (
                                                <div className="filter-sel-data-value analysis-id">
                                                  {piitm.analysis_seq_id
                                                    ? piitm.analysis_seq_id
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Priority') ? (
                                                <div className="filter-sel-data-value priority">
                                                  <Select
                                                    onChange={(e) => handlePriorityForSingle(
                                                      piitm.innoplexus_id,
                                                      e,
                                                        piitm?.originating_party,
                                                        piitm.pi_id,
                                                    )}
                                                    // defaultValue={piitm.priority ? piitm.priority : null}
                                                    value={getPriority(piitm)}
                                                    options={priorityList}
                                                    disabled={
                                                      isCheckedboxEnabled
                                                      || !(
                                                        adminType.includes('lcor_access')
                                                        || adminType.includes('cfl_access')
                                                      )
                                                      || isSelectAllChecked
                                                    }
                                                  />
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Origin') ? (
                                                <div className="filter-sel-data-value origin">
                                                  {getOrigin(piitm)}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Originating Party') ? (
                                                <div className="filter-sel-data-value party">
                                                  <div className="filter-sel-data-value priority">
                                                    <Select
                                                      onChange={(e) => handleOriginForSingle(
                                                        piitm.innoplexus_id,
                                                        e,
                                                        piitm.pi_id,
                                                      )}
                                                      value={getParty(piitm)}
                                                      options={originatingParty}
                                                      disabled={
                                                        isCheckedboxEnabled
                                                        || !(
                                                          adminType.includes('lcor_access')
                                                          || adminType.includes('cfl_access')
                                                        )
                                                        || isSelectAllChecked
                                                      }
                                                      style={{ width: '120px' }}
                                                    />
                                                  </div>
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Version') ? (
                                                <div className="filter-sel-data-value priority">
                                                  <Select
                                                    onChange={(e) => handleVersionForSingle(
                                                      piitm.innoplexus_id,
                                                      e,
                                                      piitm.pi_id,
                                                    )}
                                                    // defaultValue={itm.originating_party ? itm.originating_party : null}
                                                    value={getVersion(piitm)}
                                                    options={versionList}
                                                    disabled={
                                                      isCheckedboxEnabled
                                                      || !(
                                                        adminType.includes('lcor_access')
                                                        || adminType.includes('cfl_access')
                                                      )
                                                      || isSelectAllChecked
                                                    }
                                                  />
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Alliance Member Name') ? (
                                                <div className="filter-sel-data-value status">
                                                  {piitm.alliance_member_name
                                                    ? piitm.alliance_member_name
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Date Added') ? (
                                                <div className="filter-sel-data-value status">
                                                  {piitm.date_added
                                                    ? getFormattedDate(piitm.date_added * 1000)
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Alliance Contact') ? (
                                                <div className="filter-sel-data-value status">
                                                  {piitm.alliance_contact?.full_name
                                                    ? piitm.alliance_contact?.full_name
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Parexel Contact') ? (
                                                <div className="filter-sel-data-value status">
                                                  {piitm.parexel_contact?.full_name
                                                    ? piitm.parexel_contact?.full_name
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('LCOR Comment') ? (
                                                <div className="filter-sel-data-value status">
                                                  {piitm.lcor_comment ? piitm.lcor_comment : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('CDA Expiry') ? (
                                                <div className="filter-sel-data-value expiry">
                                                  {piitm.cda_expiry ? piitm.cda_expiry : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Query ID') ? (
                                                <div className="filter-sel-data-value query-id">
                                                  {piitm.query_seq_id ? piitm.query_seq_id : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('# Industry Studies') ? (
                                                <Button
                                                  className="filter-sel-data-value industry-studies"
                                                  onClick={(e) => onIndustryClick(
                                                    e,
                                                    piitm.pi_id,
                                                    piitm.industry_studies_source,
                                                  )}
                                                >
                                                  {piitm.industry_studies}
                                                </Button>
                                              ) : null}
                                              {checkColumnDisplay('VAT Invoice Present') ? (
                                                <div className="filter-sel-data-value industry-studies">
                                                  {piitm.vat_invoice}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Competing Studies') ? (
                                                <Button
                                                  className="filter-sel-data-value center"
                                                  onClick={() => onCompetingClick(piitm.pi_id, piitm.analysis_id)}
                                                >
                                                  {piitm.competing_studies}
                                                </Button>
                                              ) : null}
                                              {checkColumnDisplay('# PXL Studies') ? (
                                                <Button
                                                  className="filter-sel-data-value pxl-studies"
                                                  onClick={() => onPxlClick(piitm.pi_id, piitm.analysis_id)}
                                                >
                                                  {piitm.pxl_studies}
                                                </Button>
                                              ) : null}
                                              {checkColumnDisplay('Tier (Search Specific)') ? (
                                                <Button
                                                  className="filter-sel-data-value pxl-studies"
                                                  onClick={() => onTierClick(piitm)}
                                                >
                                                  {piitm.tier}
                                                </Button>
                                              ) : null}
                                              {checkColumnDisplay('Profile Tier(General)') ? (
                                                <Button
                                                  className="filter-sel-data-value pxl-studies"
                                                  onClick={() => onProfileTierClick(piitm)}
                                                >
                                                  {piitm.p_tier}
                                                </Button>
                                              ) : null}
                                              {checkColumnDisplay('Specialities') ? (
                                                <div className="filter-sel-data-value pxl-studies">
                                                  {piitm?.specialties?.length
                                                    ? piitm.specialties.join(', ')
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Enrolled Subjects') ? (
                                                <div className="filter-sel-data-value enrolled-subjects">
                                                  {piitm.enrolled_count}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Quality') ? (
                                                <div className="filter-sel-data-value quality">
                                                  <Rate
                                                    count={3}
                                                    disabled
                                                    value={piitm.quality_rating}
                                                  />
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Reliability') ? (
                                                <div className="filter-sel-data-value enrolled-subjects">
                                                  {piitm.reliability_class}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Reliability Score') ? (
                                                <Button
                                                  className="filter-sel-data-value pxl-studies"
                                                  onClick={() => onReliabilityClick(piitm)}
                                                >
                                                  {piitm.reliability_score}
                                                </Button>
                                              ) : null}
                                              {checkColumnDisplay('KOL Score') ? (
                                                <Button className="filter-sel-data-value pxl-studies" onClick={() => onKolScoreClick(piitm)}>{piitm?.cls_kol_score?.toFixed(3)}</Button>

                                              // <div className="filter-sel-data-value center">
                                              //   {piitm.cls_kol_score}
                                              // </div>
                                              ) : null}
                                              {checkColumnDisplay('Start Up Months') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm.p_start_up
                                                    ? piitm.p_start_up.toFixed(2)
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Start Up Index') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm.startup_index
                                                    ? piitm.startup_index.toFixed(2)
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Recruitment Factor') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm.p_recruitment
                                                    ? piitm.p_recruitment.toFixed(2)
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Last Trial Start Date') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm.recent_trial_start_date_ts
                                                    ? getFormattedDate(
                                                      piitm.recent_trial_start_date_ts * 1000,
                                                    )
                                                    : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Center') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm.site_name}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('City') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm?.city !== '' ? piitm?.city : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('State') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm?.state !== '' ? piitm?.state : '-'}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Country') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm.country}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Alliance Type') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm.alliance_type}
                                                </div>
                                              ) : null}
                                              {checkColumnDisplay('Networks') ? (
                                                <div className="filter-sel-data-value center">
                                                  {piitm.networks && piitm.networks.length
                                                    ? piitm.networks.join('; ')
                                                    : '-'}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="lastside-column">
                                            {parse(location.search).compareFlag
                                            || IsComparisionClicked ? (
                                              <div className="compare-checkbox">
                                                <div className="checkbox-content">
                                                  <Checkbox
                                                    className={
                                                      checkDefaultChecked(piitm.pi_id)
                                                        ? 'check'
                                                        : 'uncheck'
                                                    }
                                                    key={idy}
                                                    onChange={(e) => onCheckboxChange(e, piitm.pi_id)}
                                                    checked={checkDefaultChecked(piitm.pi_id)}
                                                  />
                                                </div>
                                              </div>
                                              ) : (
                                                <Dropdown
                                                  overlay={() => dataMenu(piitm)}
                                                  trigger={['click']}
                                                >
                                                  <Button
                                                    className="accordion-menu"
                                                    role="presentation"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                    }}
                                                  >
                                                    <Tooltip title="Kebab menu">
                                                      <div className="accordion-menu-icon" />
                                                    </Tooltip>
                                                  </Button>
                                                </Dropdown>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </ReadMore>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}
                  </div>
                ))}
              </>
              ) : null}
          </div>
        );
      }

      case 'country,pis': {
        if (record?.country[0]?.country !== showCountryHierarchy && !countryClose) {
          setShowCountryHierarchy(record?.country[0]?.country);
        }

        return (
          <div className="country-runanalysis-wrap country-check-tbl-view">
            <div
              className="card-content-title body-analysis-section"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className="leftside-column first-sec-country"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <a
                  title={record.country[0]?.country}
                  target="_blank"
                  href={`/search/countryprofile?${stringify({
                    country: record.country[0]?.country,
                    type: 'COUNTRY',
                    projectId: parse(location.search).project_id,
                    analysisId: parse(location.search).analysis_id,
                  })}`}
                  rel="noreferrer noopener"
                >
                  <div className="country-name">
                    <span className="country-name-title">{record.country[0]?.country}</span>
                  </div>
                </a>
                <div
                  className="country-name-no-arrow"
                  role="presentation"
                  onClick={() => {
                    if (showCountryHierarchy !== record.country[0]?.country) {
                      setShowCountryHierarchy(record.country[0]?.country);
                      setCountryClose(false);
                    } else {
                      setShowCountryHierarchy('');
                      setCountryClose(true);
                    }
                  }}
                >
                  <span
                    className={`country-name-title ${
                      showCountryHierarchy === record.country[0].country
                        ? 'dropdown-close'
                        : 'dropdown-open'
                    }`}
                  >
                    <span className="dropdown-arrow" />
                  </span>
                </div>
              </div>
              <div className="table-column">
                <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                  {checkColumnDisplay('Feasibility Status') ? (
                    <div className="filter-sel-data-value status">
                      {record.country[0]?.country.status ? record.country[0]?.country.status : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Feasibility Status Date') ? (
                    <div className="filter-sel-data-value center">
                      {record?.country[0]?.country?.last_cfl_status_updated_on
                        ? getFormattedDate(
                            record?.country[0]?.country?.last_cfl_status_updated_on * 1000,
                        )
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('LCOR Status') ? (
                    <div className="filter-sel-data-value status">
                      {record.country[0]?.country.lcor_status
                        ? record.country[0]?.country.lcor_status
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Sponsor Status') ? (
                    <div className="filter-sel-data-value status">
                      -
                    </div>
                  ) : null}
                  {checkColumnDisplay('LCOR Status Date') ? (
                    <div className="filter-sel-data-value center">
                      {record?.country[0]?.country.last_lcor_status_updated_on
                        ? getFormattedDate(
                            record?.country[0]?.country?.last_lcor_status_updated_on * 1000,
                        )
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Analysis ID') ? (
                    <div className="filter-sel-data-value analysis-id">
                      {record.country[0]?.country.analysis_seq_id
                        ? record.country[0]?.country.analysis_seq_id
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Priority') ? (
                    <div className="filter-sel-data-value priority">-</div>
                  ) : null}
                  {checkColumnDisplay('Origin') ? (
                    <div className="filter-sel-data-value origin">
                      {record.country[0]?.country.origin ? record.country[0]?.country.origin : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Originating Party') ? (
                    <div className="filter-sel-data-value party">
                      {record.country[0]?.country.originating_party
                        ? record.country[0]?.country.originating_party
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Version') ? (
                    <div className="filter-sel-data-value priority">-</div>
                  ) : null}
                  {checkColumnDisplay('Alliance Member Name') ? (
                    <div className="filter-sel-data-value status">
                      {record.country[0]?.country.alliance_member_name
                        ? record.country[0]?.country.alliance_member_name
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Date Added') ? (
                    <div className="filter-sel-data-value status">-</div>
                  ) : null}
                  {checkColumnDisplay('Alliance Contact') ? (
                    <div className="filter-sel-data-value status">
                      {record.country[0]?.country.alliance_contact?.full_name
                        ? record.country[0]?.country.alliance_contact?.full_name
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Parexel Contact') ? (
                    <div className="filter-sel-data-value status">
                      {record.country[0]?.country.parexel_contact?.full_name
                        ? record.country[0]?.country.parexel_contact?.full_name
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('LCOR Comment') ? (
                    <div className="filter-sel-data-value status">-</div>
                  ) : null}
                  {checkColumnDisplay('CDA Expiry') ? (
                    <div className="filter-sel-data-value expiry">
                      {record.country[0]?.country.cda_expiry
                        ? record.country[0]?.country.cda_expiry
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Query ID') ? (
                    <div className="filter-sel-data-value query-id">
                      {record.country[0]?.country.query_seq_id
                        ? record.country[0]?.country.query_seq_id
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('# Industry Studies') ? (
                    <div className="filter-sel-data-value industry-studies">
                      {record.country[0]?.industry_studies}
                    </div>
                  ) : null}
                  {checkColumnDisplay('VAT Invoice Present') ? (
                    <div className="filter-sel-data-value industry-studies">-</div>
                  ) : null}
                  {checkColumnDisplay('Competing Studies') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.competing_studies}
                    </div>
                  ) : null}
                  {checkColumnDisplay('# PXL Studies') ? (
                    <div className="filter-sel-data-value pxl-studies">
                      {record.country[0]?.pxl_studies}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Tier (Search Specific)') ? (
                    <div className="filter-sel-data-value pxl-studies">-</div>
                  ) : null}
                  {checkColumnDisplay('Profile Tier(General)') ? (
                    <div className="filter-sel-data-value pxl-studies">{record.p_tier}</div>
                  ) : null}
                  {checkColumnDisplay('Specialities') ? (
                    <div className="filter-sel-data-value pxl-studies">
                      {record?.specialties?.length ? record.specialties.join(', ') : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Enrolled Subjects') ? (
                    <div className="filter-sel-data-value enrolled-subjects">
                      {record.country[0]?.enrolled_count}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Quality') ? (
                    <div className="filter-sel-data-value quality">
                      <Rate count={3} disabled value={record.country[0]?.country.quality_rating} />
                    </div>
                  ) : null}
                  {checkColumnDisplay('Reliability') ? (
                    <div className="filter-sel-data-value enrolled-subjects">
                      {record.country[0]?.country.reliability_class}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Reliability Score') ? (
                    <Button
                      className="filter-sel-data-value pxl-studies"
                      onClick={() => onReliabilityClick(record.country[0]?.country)}
                    >
                      {record.country[0]?.country.reliability_score}
                    </Button>
                  ) : null}
                  {checkColumnDisplay('KOL Score') ? (
                    <Button
                      className="filter-sel-data-value pxl-studies"
                      onClick={() => onKolScoreClick(record.country[0]?.country)}
                    >
                      {record?.country[0]?.country?.cls_kol_score?.toFixed(3)}
                    </Button>
                  // <div className="filter-sel-data-value center">
                  //   {record.country[0]?.country.cls_kol_score}
                  // </div>
                  ) : null}
                  {checkColumnDisplay('Start Up Months') ? (
                    <div className="filter-sel-data-value center">-</div>
                  ) : null}
                  {checkColumnDisplay('Start Up Index') ? (
                    <div className="filter-sel-data-value center">{record.country[0]?.country.startup_index ? record.country[0]?.country.startup_index.toFixed(2) : '-'}</div>
                  ) : null}
                  {checkColumnDisplay('Recruitment Factor') ? (
                    <div className="filter-sel-data-value center">{record.country[0]?.country.p_recruitment ? record.country[0]?.country.p_recruitment.toFixed(2) : '-'}</div>
                  ) : null}
                  {checkColumnDisplay('Last Trial Start Date') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.recent_trial_start_date_ts
                        ? getFormattedDate(
                            record.country[0]?.country.recent_trial_start_date_ts * 1000,
                        )
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Center') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.site_name}
                    </div>
                  ) : null}
                  {checkColumnDisplay('City') ? (
                    <div className="filter-sel-data-value center">-</div>
                  ) : null}
                  {checkColumnDisplay('State') ? (
                    <div className="filter-sel-data-value center">-</div>
                  ) : null}
                  {checkColumnDisplay('Country') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.country}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Alliance Type') ? (
                    <div className="filter-sel-data-value center">Not applicable</div>
                  ) : null}
                  {checkColumnDisplay('Networks') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.networks
                      && record.country[0]?.country.networks.length
                        ? record.country[0]?.country.networks.join('; ')
                        : '-'}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="lastside-column" />
            </div>
            {showCountryHierarchy === record.country[0]?.country
            && record.pis
            && record.pis.length ? (
              <>
                {record.pis.map((rcd, indexv) => (
                  <div className="run-query-content-section" key={indexv}>
                    <div className="run-query-content-card card-country-content card">
                      <div className="pi-name-content">
                        <div className="pi-content">
                          <div className="body-analysis-section">
                            <div className="leftside-column">
                              {!IsComparisionClicked && (
                                <div className="checkbox-filter">
                                  <Checkbox
                                    checked={
                                      isSelectAllChecked
                                      || checkedPiList.includes(rcd.innoplexus_id)
                                    }
                                    disabled={isSelectAllChecked}
                                    onChange={() => handlePiCheckbox(
                                      rcd.innoplexus_id,
                                      rcd.status,
                                      rcd.pi_id,
                                      rcd.originating_party,
                                      rcd.compliance_issue_prevention_flag,
                                      rcd.is_added,
                                      rcd.is_added_by_sponsor,
                                    )}
                                  />
                                </div>
                              )}
                              <div className="meta-link" />
                              <a
                                className="pi-content-title"
                                title={rcd.pi_name}
                                target="_blank"
                                href={`/search/investigatorprofile?${stringify({
                                  query: rcd.pi_name,
                                  id: rcd.pi_id,
                                  type: 'PI',
                                })}`}
                                rel="noreferrer noopener"
                              >
                                {rcd.pi_name}
                              </a>
                              {rcd.is_new ? (
                                <div className="new-label">
                                  <div
                                    className="new-label-icon"
                                    title="This investigator was not part of the results earlier"
                                  />
                                </div>
                              ) : null}
                              <div className="comments">
                                <Comment
                                  projectId={parse(location.search).project_id}
                                  analysisId={parse(location.search).analysis_id}
                                  piId={rcd.pi_id}
                                  type="pi"
                                  sitePIExtraType="specific"
                                  count={JSON.stringify(rcd.comments)}
                                  is_lcor={adminType.includes('lcor_access')}
                                />
                              </div>
                              {rcd.compliance && rcd.compliance !== 'No Issues' ? (
                                <div
                                  className={`${
                                    rcd.compliance === 'Compliance preventing participation'
                                    || rcd.compliance === 'DNU'
                                      ? 'flag filled red'
                                      : rcd.compliance
                                          === 'Compliance not preventing participation'
                                        || rcd.compliance === 'Approved with Warning'
                                        ? 'flag filled light-red'
                                        : ''
                                  }`}
                                >
                                  <Popover
                                    overlayClassName="flag-section-content flag-section-content-compliance"
                                    placement="right"
                                    title={(
                                      <div
                                        className={`${
                                          rcd.compliance
                                            === 'Compliance preventing participation'
                                          || rcd.compliance === 'DNU'
                                            ? 'flag-title filled red'
                                            : rcd.compliance
                                                === 'Compliance not preventing participation'
                                              || rcd.compliance === 'Approved with Warning'
                                              ? 'flag-title filled light-red'
                                              : ''
                                        }`}
                                      >
                                        GCP Compliance & CDD
                                      </div>
                                    )}
                                    content={(
                                      <div className="flag-data">
                                        <div className="flag-data-title-create">
                                          <div className="flag-data-content-value">
                                            <>
                                              {rcd?.cdd_data?.length
                                                ? rcd?.cdd_data?.map((item) => (
                                                  <>
                                                    <div style={{ 'white-space': 'pre-line' }}>
                                                      CDD Status : {item.status}
                                                    </div>
                                                    <br />
                                                    <div style={{ 'white-space': 'pre-line' }}>
                                                      CDD Date: {item.date ? item.date : '-'}
                                                    </div>
                                                    <br />
                                                    <div style={{ 'white-space': 'pre-line' }}>
                                                      CDD Comment:{' '}
                                                      {item.comment ? item.comment : '-'}
                                                    </div>
                                                    <br />
                                                    <hr />
                                                  </>
                                                ))
                                                : undefined}
                                              {rcd?.compliances?.length
                                                && rcd?.compliances.map((itm1) => {
                                                  return (
                                                    <>
                                                      <div style={{ 'white-space': 'pre-line' }}>
                                                        Compliance Preventing Participation :{' '}
                                                        {itm1.no_trial_participation_flag === 'N'
                                                          ? 'No'
                                                          : itm1.no_trial_participation_flag === 'Y'
                                                            ? 'Yes'
                                                            : itm1.no_trial_participation_flag
                                                            === 'NO'
                                                              ? 'No'
                                                              : itm1.no_trial_participation_flag
                                                            === 'YES'
                                                                ? 'Yes'
                                                                : itm1.no_trial_participation_flag}
                                                      </div>
                                                      <br />

                                                      <div style={{ 'white-space': 'pre-line' }}>
                                                        Compliance Issue Comment:{' '}
                                                        {itm1.compliance_issue_comment
                                                          ? itm1.compliance_issue_comment
                                                          : '-'}
                                                      </div>
                                                      <br />
                                                      <div style={{ 'white-space': 'pre-line' }}>
                                                        Compliance Issue Description:{' '}
                                                        {itm1.compliance_issue_desc
                                                          ? itm1.compliance_issue_desc
                                                          : '-'}
                                                      </div>
                                                      <br />
                                                      <div style={{ 'white-space': 'pre-line' }}>
                                                        Compliance Issue Raised:{' '}
                                                        {itm1.compliance_issue_raised_dt
                                                        && itm1.compliance_issue_raised_dt.length
                                                          ? itm1.compliance_issue_raised_dt.split(
                                                            ' ',
                                                          )[0]
                                                          : '-'}
                                                      </div>
                                                      <br />
                                                      <div style={{ 'white-space': 'pre-line' }}>
                                                        Reviewed Status:{' '}
                                                        {itm1.review_status
                                                          ? itm1.review_status
                                                          : '-'}
                                                      </div>
                                                      <br />
                                                      <div style={{ 'white-space': 'pre-line' }}>
                                                        Review Date:{' '}
                                                        {itm1.review_date && itm1.review_date.length
                                                          ? itm1.review_date.split(' ')[0]
                                                          : '-'}
                                                      </div>
                                                      <br />
                                                      <div style={{ 'white-space': 'pre-line' }}>
                                                        Reviewer Name:{' '}
                                                        {itm1.reviewer_name
                                                          ? itm1.reviewer_name
                                                          : '-'}
                                                      </div>
                                                      <br />
                                                      <div style={{ 'white-space': 'pre-line' }}>
                                                        Re-evaluation Comment:{' '}
                                                        {itm1.reevaluation_comment
                                                          ? itm1.reevaluation_comment
                                                          : '-'}
                                                      </div>
                                                      <hr />
                                                    </>
                                                  );
                                                })}
                                            </>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    trigger="click"
                                  >
                                    <div className="flag-img" />
                                  </Popover>
                                </div>
                              ) : null}{' '}
                              {rcd.emerging_kol.length ? (
                                <Tooltip
                                  title={(
                                    <div className="tooltip-title">
                                      <div>Emerging Category : {rcd.emerging_data.flag === 'Medium' ? 'Moderate'
                                        : rcd.emerging_data.flag === 'High' ? 'High'
                                          : rcd.emerging_data.flag === 'Low' ? 'Low' : ''}
                                      </div>
                                      <div className="tooltip-theraprutic-area">
                                        Therapy Area : {rcd.emerging_data.therapeutic_area}
                                      </div>
                                      <div className="tooltip-score">
                                        Score : {rcd.emerging_data.score}
                                      </div>
                                    </div>
                                  )}
                                >
                                  <div
                                    className={`${
                                      rcd.emerging_data.flag === 'Medium'
                                        ? 'project-result Medium-flag'
                                        : rcd.emerging_data.flag === 'Low'
                                          ? 'project-result Low-flag'
                                          : rcd.emerging_data.flag === 'High'
                                            ? 'project-result High-flag'
                                            : ''
                                    }`}
                                  >
                                    <Popover
                                      overlayClassName="flag-section-content flag-section-content-project-result"
                                      placement="right"
                                      title={(
                                        <div
                                          className={`${
                                            rcd.emerging_data.flag === 'Medium'
                                              ? 'project-result Medium-flag'
                                              : rcd.emerging_data.flag === 'Low'
                                                ? 'project-result Low-flag'
                                                : rcd.emerging_data.flag === 'High'
                                                  ? 'project-result High-flag'
                                                  : ''
                                          }`}
                                        >
                                          TA wise Emerging KOL Score
                                        </div>
                                      )}
                                      content={(
                                        <>
                                          <div className="emerging-investigator-title">
                                            <div className="emerging-investigator-ta">Primary TA : &nbsp;</div>
                                            {Object.keys(rcd.emerging_data).length ? (<div className="emerging-investigator-"> {rcd.emerging_data.therapeutic_area}</div>) : ''}
                                          </div>
                                          <div className="emerging-investigator-right">
                                            <div
                                              className="emerging-investigator-header"
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                              }}
                                            >

                                              <div className="emerging-investigator-header-th theraprutic-area">
                                                {' '}
                                                Therapy Area{' '}
                                              </div>
                                              <div className="emerging-investigator-header-th">
                                                {' '}
                                                Score{' '}
                                              </div>
                                              <div className="emerging-investigator-header-th">
                                                {' '}
                                                Emerging KOL Class{' '}
                                              </div>
                                            </div>
                                            <div className="emerging-investigator-body">
                                              {rcd.emerging_kol?.length
                                                ? rcd.emerging_kol?.map((itm1) => (
                                                  <>
                                                    <div
                                                      className="emerging-investigator-header-tr"
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-around',
                                                      }}
                                                    >
                                                      <div
                                                        className="emerging-investigator-header-td therapeutic-area"
                                                        style={{ display: 'flex' }}
                                                      >
                                                        <div className="emerging-investigator-header-td ta-value">
                                                          {itm1.therapeutic_area}
                                                        </div>
                                                      </div>
                                                      <div className="emerging-investigator-header-td score">
                                                        {itm1.score}
                                                      </div>
                                                      <div className="emerging-investigator-header-td">
                                                        <div className="emerging-investigator-flag">
                                                          {itm1.flag === 'Medium' ? (
                                                            <span
                                                              className="Medium-flag"
                                                              title="Moderate"
                                                            />
                                                          ) : itm1.flag === 'Low' ? (
                                                            <span
                                                              className="Low-flag"
                                                              title="Low"
                                                            />
                                                          ) : itm1.flag === 'High' ? (
                                                            <span
                                                              className="High-flag"
                                                              title="High"
                                                            />
                                                          ) : (
                                                            ''
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                ))
                                                : ''}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      trigger="click"
                                    >
                                      <div className="flag-img" />
                                    </Popover>
                                  </div>
                                </Tooltip>
                              ) : null}
                              <div
                                className={
                                  rcd.alliancePresent
                                    ? 'primary-org-change-alliance'
                                    : 'primary-org-change'
                                }
                              >
                                <Button
                                  onClick={() => setOrgChangeModalOpen({
                                    isOpen: true,
                                    piId: rcd.pi_id,
                                    siteId: rcd.site_id,
                                    isAlliance: rcd.alliancePresent,
                                  })}
                                />
                              </div>
                            </div>
                            <div className="table-column">
                              <div
                                className="table-column-sec table-column-body"
                                onScroll={onRowScroll}
                              >
                                {checkColumnDisplay('Feasibility Status') ? (
                                  <div className="filter-sel-data-value status">
                                    {getCflStatus(rcd, 'cfl')}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Feasibility Status Date') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.last_cfl_status_updated_on
                                      ? getFormattedDate(rcd.last_cfl_status_updated_on * 1000)
                                      : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('LCOR Status') ? (
                                  <div className="filter-sel-data-value status">
                                    {getCflStatus(rcd, 'lcor')}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Sponsor Status') ? (
                                  <div className="filter-sel-data-value status">
                                    {getCflStatus(rcd, 'sponsor')}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('LCOR Status Date') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.last_lcor_status_updated_on
                                      ? getFormattedDate(rcd.last_lcor_status_updated_on * 1000)
                                      : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Analysis ID') ? (
                                  <div className="filter-sel-data-value analysis-id">
                                    {rcd.analysis_seq_id ? rcd.analysis_seq_id : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Priority') ? (
                                  <div className="filter-sel-data-value priority">
                                    <Select
                                      onChange={(e) => handlePriorityForSingle(
                                        rcd.innoplexus_id,
                                        e,
                                          rcd?.originating_party,
                                          rcd.pi_id,
                                      )}
                                      // defaultValue={rcd.priority ? rcd.priority : null}
                                      value={getPriority(rcd)}
                                      options={
                                        priorityList
                                        || !(
                                          adminType.includes('lcor_access')
                                          || adminType.includes('cfl_access')
                                        )
                                        || isSelectAllChecked
                                      }
                                      disabled={isCheckedboxEnabled}
                                    />
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Origin') ? (
                                  <div className="filter-sel-data-value origin">
                                    {getOrigin(rcd)}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Originating Party') ? (
                                  <div className="filter-sel-data-value party">
                                    <div className="filter-sel-data-value priority">
                                      <Select
                                        onChange={(e) => handleOriginForSingle(rcd.innoplexus_id, e, rcd.pi_id)}
                                        // defaultValue={rcd.originating_party ? rcd.originating_party : null}
                                        value={getParty(rcd)}
                                        options={originatingParty}
                                        disabled={
                                          isCheckedboxEnabled
                                          || !(
                                            adminType.includes('lcor_access')
                                            || adminType.includes('cfl_access')
                                          )
                                          || isSelectAllChecked
                                        }
                                        style={{ width: '120px' }}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Version') ? (
                                  <div className="filter-sel-data-value priority">
                                    <Select
                                      onChange={(e) => handleVersionForSingle(rcd.innoplexus_id, e, rcd.pi_id)}
                                      // defaultValue={itm.originating_party ? itm.originating_party : null}
                                      value={getVersion(rcd)}
                                      options={versionList}
                                      disabled={
                                        isCheckedboxEnabled
                                        || !(
                                          adminType.includes('lcor_access')
                                          || adminType.includes('cfl_access')
                                        )
                                        || isSelectAllChecked
                                      }
                                    />
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Alliance Member Name') ? (
                                  <div className="filter-sel-data-value status">
                                    {rcd.alliance_member_name ? rcd.alliance_member_name : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Date Added') ? (
                                  <div className="filter-sel-data-value status">
                                    {rcd.date_added ? getFormattedDate(rcd.date_added * 1000) : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Alliance Contact') ? (
                                  <div className="filter-sel-data-value status">
                                    {rcd.alliance_contact?.full_name
                                      ? rcd.alliance_contact?.full_name
                                      : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Parexel Contact') ? (
                                  <div className="filter-sel-data-value status">
                                    {rcd.parexel_contact?.full_name
                                      ? rcd.parexel_contact?.full_name
                                      : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('LCOR Comment') ? (
                                  <div className="filter-sel-data-value status">
                                    {rcd.lcor_comment ? rcd.lcor_comment : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('CDA Expiry') ? (
                                  <div className="filter-sel-data-value expiry">
                                    {rcd.cda_expiry ? rcd.cda_expiry : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Query ID') ? (
                                  <div className="filter-sel-data-value query-id">
                                    {rcd.query_seq_id ? rcd.query_seq_id : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('# Industry Studies') ? (
                                  <Button
                                    className="filter-sel-data-value industry-studies"
                                    onClick={(e) => onIndustryClick(
                                      e,
                                      rcd.pi_id,
                                      rcd.industry_studies_source,
                                      rcd.analysis_id,
                                    )}
                                  >
                                    {rcd.industry_studies}
                                  </Button>
                                ) : null}
                                {checkColumnDisplay('VAT Invoice Present') ? (
                                  <div className="filter-sel-data-value industry-studies">
                                    {rcd.vat_invoice}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Competing Studies') ? (
                                  <Button
                                    className="filter-sel-data-value center"
                                    onClick={() => onCompetingClick(rcd.pi_id, rcd.analysis_id)}
                                  >
                                    {rcd.competing_studies}
                                  </Button>
                                ) : null}
                                {checkColumnDisplay('# PXL Studies') ? (
                                  <Button
                                    className="filter-sel-data-value pxl-studies"
                                    onClick={() => onPxlClick(rcd.pi_id, rcd.analysis_id)}
                                  >
                                    {rcd.pxl_studies}
                                  </Button>
                                ) : null}
                                {checkColumnDisplay('Tier (Search Specific)') ? (
                                  <Button
                                    className="filter-sel-data-value pxl-studies"
                                    onClick={() => onTierClick(rcd)}
                                  >
                                    {rcd.tier}
                                  </Button>
                                ) : null}
                                {checkColumnDisplay('Profile Tier(General)') ? (
                                  <Button
                                    className="filter-sel-data-value pxl-studies"
                                    onClick={() => onProfileTierClick(rcd)}
                                  >
                                    {rcd.p_tier}
                                  </Button>
                                ) : null}
                                {checkColumnDisplay('Specialities') ? (
                                  <div className="filter-sel-data-value pxl-studies">
                                    {rcd?.specialties?.length ? rcd.specialties.join(', ') : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Enrolled Subjects') ? (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {rcd.enrolled_count}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Quality') ? (
                                  <div className="filter-sel-data-value quality">
                                    <Rate count={3} disabled value={rcd.quality_rating} />
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Reliability') ? (
                                  <div className="filter-sel-data-value enrolled-subjects">
                                    {rcd.reliability_class}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Reliability Score') ? (
                                  <Button
                                    className="filter-sel-data-value pxl-studies"
                                    onClick={() => onReliabilityClick(rcd)}
                                  >
                                    {rcd.reliability_score}
                                  </Button>
                                ) : null}
                                {checkColumnDisplay('KOL Score') ? (
                                  <Button
                                    className="filter-sel-data-value pxl-studies"
                                    onClick={() => onKolScoreClick(rcd)}
                                  >
                                    {rcd?.cls_kol_score?.toFixed(3)}
                                  </Button>
                                // <div className="filter-sel-data-value center">
                                //   {rcd.cls_kol_score}
                                // </div>
                                ) : null}
                                {checkColumnDisplay('Start Up Months') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.p_start_up ? rcd.p_start_up.toFixed(2) : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Start Up Index') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.startup_index ? rcd.startup_index.toFixed(2) : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Recruitment Factor') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.p_recruitment ? rcd.p_recruitment.toFixed(2) : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Last Trial Start Date') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.recent_trial_start_date_ts
                                      ? getFormattedDate(rcd.recent_trial_start_date_ts * 1000)
                                      : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Center') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.site_name}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('City') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd?.city !== '' ? rcd?.city : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('State') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd?.state !== '' ? rcd?.state : '-'}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Country') ? (
                                  <div className="filter-sel-data-value center">{rcd.country}</div>
                                ) : null}
                                {checkColumnDisplay('Alliance Type') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.alliance_type}
                                  </div>
                                ) : null}
                                {checkColumnDisplay('Networks') ? (
                                  <div className="filter-sel-data-value center">
                                    {rcd.networks && rcd.networks.length
                                      ? rcd.networks.join('; ')
                                      : '-'}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="lastside-column">
                              {parse(location.search).compareFlag || IsComparisionClicked ? (
                                <div className="compare-checkbox">
                                  <div className="checkbox-content">
                                    <Checkbox
                                      className={
                                        checkDefaultChecked(rcd.pi_id) ? 'check' : 'uncheck'
                                      }
                                      key={rcd.pi_id}
                                      onChange={(e) => onCheckboxChange(e, rcd.pi_id)}
                                      checked={checkDefaultChecked(rcd.pi_id)}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <Dropdown overlay={() => dataMenu(rcd)} trigger={['click']}>
                                  <Button
                                    className="accordion-menu"
                                    role="presentation"
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <Tooltip title="Kebab menu">
                                      <div className="accordion-menu-icon" />
                                    </Tooltip>
                                  </Button>
                                </Dropdown>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
              ) : null}
          </div>
        );
      }

      case 'country,primary_organizations,pis': {
        if (record?.country[0]?.country !== showCountryHierarchy && !countryClose) {
          setShowCountryHierarchy(record?.country[0]?.country);
        }

        return (
          <div className="country-runanalysis-wrap country-check-tbl-view">
            <div
              className="card-content-title body-analysis-section"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className="leftside-column first-sec-country"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <a
                  title={record?.country[0]?.country}
                  target="_blank"
                  href={`/search/countryprofile?${stringify({
                    country: record?.country[0]?.country,
                    type: 'COUNTRY',
                    projectId: parse(location.search).project_id,
                    analysisId: parse(location.search).analysis_id,
                  })}`}
                  rel="noreferrer noopener"
                >
                  <div className="country-name">
                    <span className="country-name-title">{record?.country[0]?.country}</span>
                  </div>
                </a>
                <div
                  className="country-name-no-arrow"
                  role="presentation"
                  onClick={() => {
                    if (showCountryHierarchy !== record?.country[0]?.country) {
                      setShowCountryHierarchy(record?.country[0]?.country);
                      setCountryClose(false);
                    } else {
                      setShowCountryHierarchy('');
                      setCountryClose(true);
                    }
                  }}
                >
                  <span
                    className={`country-name-title ${
                      showCountryHierarchy === record?.country[0].country
                        ? 'dropdown-close'
                        : 'dropdown-open'
                    }`}
                  >
                    <span className="dropdown-arrow" />
                  </span>
                </div>
              </div>
              <div className="table-column">
                <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                  {checkColumnDisplay('Feasibility Status') ? (
                    <div className="filter-sel-data-value status">
                      {record?.country[0]?.country.status
                        ? record?.country[0]?.country.status
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Feasibility Status Date') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.last_cfl_status_updated_on
                        ? getFormattedDate(
                            record.country[0]?.country.last_cfl_status_updated_on * 1000,
                        )
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('LCOR Status') ? (
                    <div className="filter-sel-data-value status">
                      {record?.country[0]?.country.lcor_status
                        ? record?.country[0]?.country.lcor_status
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Sponsor Status') ? (
                    <div className="filter-sel-data-value status">
                      -
                    </div>
                  ) : null}
                  {checkColumnDisplay('LCOR Status Date') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.last_lcor_status_updated_on
                        ? getFormattedDate(
                            record.country[0]?.country.last_lcor_status_updated_on * 1000,
                        )
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Analysis ID') ? (
                    <div className="filter-sel-data-value analysis-id">
                      {record?.country[0]?.country.analysis_seq_id
                        ? record?.country[0]?.country.analysis_seq_id
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Priority') ? (
                    <div className="filter-sel-data-value priority">-</div>
                  ) : null}
                  {checkColumnDisplay('Origin') ? (
                    <div className="filter-sel-data-value origin">
                      {record?.country[0]?.country.origin
                        ? record?.country[0]?.country.origin
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Originating Party') ? (
                    <div className="filter-sel-data-value party">
                      {record?.country[0]?.country.originating_party
                        ? record?.country[0]?.country.originating_party
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Version') ? (
                    <div className="filter-sel-data-value priority">-</div>
                  ) : null}
                  {checkColumnDisplay('Alliance Member Name') ? (
                    <div className="filter-sel-data-value status">
                      {record?.country[0]?.country.alliance_member_name
                        ? record?.country[0]?.country.alliance_member_name
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Date Added') ? (
                    <div className="filter-sel-data-value status">-</div>
                  ) : null}
                  {checkColumnDisplay('Alliance Contact') ? (
                    <div className="filter-sel-data-value status">
                      {record?.country[0]?.country.alliance_contact?.full_name
                        ? record?.country[0]?.country.alliance_contact?.full_name
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Parexel Contact') ? (
                    <div className="filter-sel-data-value status">
                      {record?.country[0]?.country.parexel_contact?.full_name
                        ? record?.country[0]?.country.parexel_contact?.full_name
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('LCOR Comment') ? (
                    <div className="filter-sel-data-value status">-</div>
                  ) : null}
                  {checkColumnDisplay('CDA Expiry') ? (
                    <div className="filter-sel-data-value expiry">
                      {record.country[0]?.country.cda_expiry
                        ? record.country[0]?.country.cda_expiry
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Query ID') ? (
                    <div className="filter-sel-data-value query-id">
                      {record.country[0]?.country.query_seq_id
                        ? record.country[0]?.country.query_seq_id
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('# Industry Studies') ? (
                    <div className="filter-sel-data-value industry-studies">
                      {record.country[0]?.industry_studies}
                    </div>
                  ) : null}
                  {checkColumnDisplay('VAT Invoice Present') ? (
                    <div className="filter-sel-data-value industry-studies">-</div>
                  ) : null}
                  {checkColumnDisplay('Competing Studies') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.competing_studies}
                    </div>
                  ) : null}
                  {checkColumnDisplay('# PXL Studies') ? (
                    <div className="filter-sel-data-value pxl-studies">
                      {record.country[0]?.pxl_studies}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Tier (Search Specific)') ? (
                    <div className="filter-sel-data-value pxl-studies">
                      {record.country[0]?.country.tier ? record.country[0]?.country.tier : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Profile Tier(General)') ? (
                    <div className="filter-sel-data-value pxl-studies">{record.p_tier}</div>
                  ) : null}
                  {checkColumnDisplay('Specialities') ? (
                    <div className="filter-sel-data-value pxl-studies">
                      {record?.specialties?.length ? record.specialties.join(', ') : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Enrolled Subjects') ? (
                    <div className="filter-sel-data-value enrolled-subjects">
                      {record.country[0]?.enrolled_count}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Quality') ? (
                    <div className="filter-sel-data-value quality">
                      <Rate count={3} disabled value={record.country[0]?.country.quality_rating} />
                    </div>
                  ) : null}
                  {checkColumnDisplay('Reliability') ? (
                    <div className="filter-sel-data-value enrolled-subjects">
                      {record.country[0]?.country.reliability_class}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Reliability Score') ? (
                    <div className="filter-sel-data-value pxl-studies">
                      {record.country[0]?.country.reliability_score}
                    </div>
                  ) : null}
                  {checkColumnDisplay('KOL Score') ? (
                    <Button
                      className="filter-sel-data-value pxl-studies"
                      onClick={() => onKolScoreClick(record.country[0]?.country)}
                    >
                      {record?.country[0]?.country?.cls_kol_score?.toFixed(3)}
                    </Button>

                  // <div className="filter-sel-data-value center">
                  //   {record.country[0]?.country.cls_kol_score}
                  // </div>
                  ) : null}
                  {checkColumnDisplay('Start Up Months') ? (
                    <div className="filter-sel-data-value center">-</div>
                  ) : null}
                  {checkColumnDisplay('Start Up Index') ? (
                    <div className="filter-sel-data-value center">{record.country[0]?.country.startup_index ? record.country[0]?.country.startup_index.toFixed(2) : '-'}</div>
                  ) : null}
                  {checkColumnDisplay('Recruitment Factor') ? (
                    <div className="filter-sel-data-value center">{record.country[0]?.country.p_recruitment ? record.country[0]?.country.p_recruitment.toFixed(2) : '-'}</div>
                  ) : null}
                  {checkColumnDisplay('Last Trial Start Date') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.recent_trial_start_date_ts
                        ? getFormattedDate(
                            record.country[0]?.country.recent_trial_start_date_ts * 1000,
                        )
                        : '-'}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Center') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.site_name}
                    </div>
                  ) : null}
                  {checkColumnDisplay('City') ? (
                    <div className="filter-sel-data-value center">-</div>
                  ) : null}
                  {checkColumnDisplay('State') ? (
                    <div className="filter-sel-data-value center">-</div>
                  ) : null}
                  {checkColumnDisplay('Country') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.country}
                    </div>
                  ) : null}
                  {checkColumnDisplay('Alliance Type') ? (
                    <div className="filter-sel-data-value center">Not applicable</div>
                  ) : null}
                  {checkColumnDisplay('Networks') ? (
                    <div className="filter-sel-data-value center">
                      {record.country[0]?.country.networks
                      && record.country[0]?.country.networks.length
                        ? record.country[0]?.country.networks.join('; ')
                        : '-'}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="lastside-column" />
            </div>
            {showCountryHierarchy === record.country[0]?.country
            && record.primary_organizations
            && record.primary_organizations.length ? (
              <>
                {record.primary_organizations.map((rcd, indexv) => (
                  <div className="run-query-content-section" key={indexv}>
                    <div className="run-query-content-card card-country-content card">
                      <div className="card-content card-country-content-header">
                        <div className="body-analysis-section level-header-section level-two-header-section">
                          <div className="leftside-column">
                            <div className="fav" />
                            <a
                              className="card-content-title"
                              title={rcd.site_name}
                              target="_blank"
                              href={`/search/deepdives?${stringify({
                                query: rcd.site_name,
                                id: rcd.site_id,
                                type: 'SITE',
                                currentTab: rcd.parent_flag ? 'parent_centers' : 'centers',
                              })}`}
                              rel="noreferrer noopener"
                            >
                              {rcd.site_name}
                            </a>
                            <div className="comments">
                              <Comment
                                projectId={parse(location.search).project_id}
                                analysisId={parse(location.search).analysis_id}
                                siteId={rcd.site_id}
                                type="site"
                                sitePIExtraType="specific"
                                count={JSON.stringify(rcd.comments)}
                              />
                            </div>
                            <div className="population-flag">
                              <Tooltip
                                title={renderTooltipTitleForAmerican(
                                  rcd.american_indian_origin_category,
                                )}
                              >
                                {rcd.american_indian_origin_category === 'High' ? (
                                  <span className="High-population-icon-for-Alaska" />
                                ) : rcd.american_indian_origin_category === 'Low' ? (
                                  <span className="Low-population-icon-for-Alaska" />
                                ) : rcd.american_indian_origin_category === 'Medium' ? (
                                  <span className="Medium-population-icon-for-Alaska" />
                                ) : (
                                  ''
                                )}
                              </Tooltip>
                              <Tooltip
                                title={renderTooltipTitleForHawaiian(
                                  rcd.native_hawaiian_origin_category,
                                )}
                              >
                                {rcd.native_hawaiian_origin_category === 'High' ? (
                                  <span className="High-population-icon-for-Hawaii" />
                                ) : rcd.native_hawaiian_origin_category === 'Low' ? (
                                  <span className="Low-population-icon-for-Hawaii" />
                                ) : rcd.native_hawaiian_origin_category === 'Medium' ? (
                                  <span className="Medium-population-icon-for-Hawaii" />
                                ) : (
                                  ''
                                )}
                              </Tooltip>
                            </div>
                          </div>
                          <div className="table-column">
                            <div
                              className="table-column-sec table-column-body"
                              onScroll={onRowScroll}
                            >
                              {checkColumnDisplay('Feasibility Status') ? (
                                <div className="filter-sel-data-value status">
                                  {rcd.status ? rcd.status : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Feasibility Status Date') ? (
                                <div className="filter-sel-data-value center">
                                  {rcd.last_cfl_status_updated_on
                                    ? getFormattedDate(rcd.last_cfl_status_updated_on * 1000)
                                    : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('LCOR Status') ? (
                                <div className="filter-sel-data-value status">
                                  {rcd.lcor_status ? rcd.lcor_status : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Sponsor Status') ? (
                                <div className="filter-sel-data-value status">
                                  {rcd.sponsor_status ? rcd.sponsor_status : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('LCOR Status Date') ? (
                                <div className="filter-sel-data-value center">
                                  {rcd.last_lcor_status_updated_on
                                    ? getFormattedDate(rcd.last_lcor_status_updated_on * 1000)
                                    : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Analysis ID') ? (
                                <div className="filter-sel-data-value analysis-id">
                                  {rcd.analysis_seq_id ? rcd.analysis_seq_id : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Priority') ? (
                                <div className="filter-sel-data-value priority">-</div>
                              ) : null}
                              {checkColumnDisplay('Origin') ? (
                                <div className="filter-sel-data-value origin">-</div>
                              ) : null}
                              {checkColumnDisplay('Originating Party') ? (
                                <div className="filter-sel-data-value party">-</div>
                              ) : null}
                              {checkColumnDisplay('Version') ? (
                                <div className="filter-sel-data-value priority">-</div>
                              ) : null}
                              {checkColumnDisplay('Alliance Member Name') ? (
                                <div className="filter-sel-data-value status">-</div>
                              ) : null}
                              {checkColumnDisplay('Date Added') ? (
                                <div className="filter-sel-data-value status">-</div>
                              ) : null}
                              {checkColumnDisplay('Alliance Contact') ? (
                                <div className="filter-sel-data-value status">-</div>
                              ) : null}
                              {checkColumnDisplay('Parexel Contact') ? (
                                <div className="filter-sel-data-value status">-</div>
                              ) : null}
                              {checkColumnDisplay('LCOR Comment') ? (
                                <div className="filter-sel-data-value status">-</div>
                              ) : null}
                              {checkColumnDisplay('CDA Expiry') ? (
                                <div className="filter-sel-data-value expiry">-</div>
                              ) : null}
                              {checkColumnDisplay('Query ID') ? (
                                <div className="filter-sel-data-value query-id">
                                  {rcd.query_seq_id ? rcd.query_seq_id : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('# Industry Studies') ? (
                                <div className="filter-sel-data-value industry-studies">
                                  {rcd.industry_studies}
                                </div>
                              ) : null}
                              {checkColumnDisplay('VAT Invoice Present') ? (
                                <div className="filter-sel-data-value industry-studies">
                                  {rcd.vat_invoice}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Competing Studies') ? (
                                <div className="filter-sel-data-value center">
                                  {rcd.competing_studies}
                                </div>
                              ) : null}
                              {checkColumnDisplay('# PXL Studies') ? (
                                <div className="filter-sel-data-value pxl-studies">
                                  {rcd.pxl_studies}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Tier (Search Specific)') ? (
                                <Button
                                  className="filter-sel-data-value pxl-studies"
                                  onClick={() => onTierClick(rcd)}
                                >
                                  {rcd.tier}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('Profile Tier(General)') ? (
                                <Button
                                  className="filter-sel-data-value pxl-studies"
                                  onClick={() => onProfileTierClick(rcd)}
                                >
                                  {rcd.p_tier}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('Specialities') ? (
                                <div className="filter-sel-data-value pxl-studies">
                                  {rcd?.specialties?.length ? rcd.specialties.join(', ') : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Enrolled Subjects') ? (
                                <div className="filter-sel-data-value enrolled-subjects">
                                  {rcd.enrolled_count}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Quality') ? (
                                <div className="filter-sel-data-value quality">
                                  <Rate count={3} disabled value={rcd.quality_rating} />
                                </div>
                              ) : null}
                              {checkColumnDisplay('Reliability') ? (
                                <div className="filter-sel-data-value enrolled-subjects">
                                  {rcd.reliability_class}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Reliability Score') ? (
                                <Button
                                  className="filter-sel-data-value pxl-studies"
                                  onClick={() => onReliabilityClick(rcd)}
                                >
                                  {rcd.reliability_score}
                                </Button>
                              ) : null}
                              {checkColumnDisplay('KOL Score') ? (
                                <div className="filter-sel-data-value center">Not applicable</div>
                              ) : null}
                              {checkColumnDisplay('Start Up Months') ? (
                                <div className="filter-sel-data-value center">
                                  {rcd.p_start_up ? rcd.p_start_up.toFixed(2) : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Start Up Index') ? (
                                <div className="filter-sel-data-value center">
                                  {rcd.startup_index ? rcd.startup_index.toFixed(2) : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Recruitment Factor') ? (
                                <div className="filter-sel-data-value center">
                                  {rcd.p_recruitment ? rcd.p_recruitment.toFixed(2) : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Last Trial Start Date') ? (
                                <div className="filter-sel-data-value center">Not applicable</div>
                              ) : null}
                              {checkColumnDisplay('Center') ? (
                                <div className="filter-sel-data-value center">{rcd.site_name}</div>
                              ) : null}
                              {checkColumnDisplay('City') ? (
                                <div className="filter-sel-data-value center">
                                  {rcd?.city !== '' ? rcd?.city : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('State') ? (
                                <div className="filter-sel-data-value center">
                                  {rcd?.state !== '' ? rcd?.state : '-'}
                                </div>
                              ) : null}
                              {checkColumnDisplay('Country') ? (
                                <div className="filter-sel-data-value center">{rcd.country}</div>
                              ) : null}
                              {checkColumnDisplay('Alliance Type') ? (
                                <div className="filter-sel-data-value center">Not applicable</div>
                              ) : null}
                              {checkColumnDisplay('Networks') ? (
                                <div className="filter-sel-data-value center">
                                  {rcd.networks && rcd.networks.length
                                    ? rcd.networks.join('; ')
                                    : '-'}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="lastside-column">
                            {parse(location.search).compareSiteFlag || IsSiteComparisionClicked ? (
                              <div className="compare-checkbox">
                                <div className="checkbox-content">
                                  <Checkbox
                                    className={
                                      checkDefaultChecked(rcd.site_id) ? 'check' : 'uncheck'
                                    }
                                    onChange={(e) => onCheckboxChange(e, rcd.site_id)}
                                    checked={checkDefaultChecked(rcd.site_id)}
                                  />
                                </div>
                              </div>
                            ) : (
                              <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                                <Button
                                  className="accordion-menu"
                                  role="presentation"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Tooltip title="Kebab menu">
                                    <div className="accordion-menu-icon" />
                                  </Tooltip>
                                </Button>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="pi-name-content">
                        <ReadMore limit={5}>
                          {rcd.pis.map((itm, idx) => {
                            return (
                              <div className="pi-content" key={idx}>
                                <div className="body-analysis-section">
                                  <div className="leftside-column">
                                    {!IsComparisionClicked && (
                                      <div className="checkbox-filter">
                                        <Checkbox
                                          checked={
                                            isSelectAllChecked
                                            || checkedPiList.includes(itm.innoplexus_id)
                                          }
                                          disabled={isSelectAllChecked}
                                          onChange={() => handlePiCheckbox(
                                            itm.innoplexus_id,
                                            itm.status,
                                            itm.pi_id,
                                            itm.originating_party,
                                            itm.compliance_issue_prevention_flag,
                                            itm.is_added,
                                            itm.is_added_by_sponsor,
                                          )}
                                        />
                                      </div>
                                    )}
                                    <div className="meta-link" />
                                    <a
                                      className="pi-content-title"
                                      title={itm.pi_name}
                                      target="_blank"
                                      href={`/search/investigatorprofile?${stringify({
                                        query: itm.pi_name,
                                        id: itm.pi_id,
                                        type: 'PI',
                                      })}`}
                                      rel="noreferrer noopener"
                                    >
                                      {itm.pi_name}
                                    </a>
                                    {itm.is_new ? (
                                      <div className="new-label">
                                        <div
                                          className="new-label-icon"
                                          title="This investigator was not part of the results earlier"
                                        />
                                      </div>
                                    ) : null}
                                    <div className="comments">
                                      <Comment
                                        projectId={parse(location.search).project_id}
                                        analysisId={parse(location.search).analysis_id}
                                        piId={itm.pi_id}
                                        type="pi"
                                        sitePIExtraType="specific"
                                        count={JSON.stringify(itm.comments)}
                                        is_lcor={adminType.includes('lcor_access')}
                                      />
                                    </div>
                                    {itm.compliance && itm.compliance !== 'No Issues' ? (
                                      <div
                                        className={`${
                                          itm.compliance
                                            === 'Compliance preventing participation'
                                          || itm.compliance === 'DNU'
                                            ? 'flag filled red'
                                            : itm.compliance
                                                === 'Compliance not preventing participation'
                                              || itm.compliance === 'Approved with Warning'
                                              ? 'flag filled light-red'
                                              : ''
                                        }`}
                                      >
                                        <Popover
                                          overlayClassName="flag-section-content flag-section-content-compliance"
                                          placement="right"
                                          title={(
                                            <div
                                              className={`${
                                                itm.compliance
                                                  === 'Compliance preventing participation'
                                                || itm.compliance === 'DNU'
                                                  ? 'flag-title filled red'
                                                  : itm.compliance
                                                      === 'Compliance not preventing participation'
                                                    || rcd.compliance === 'Approved with Warning'
                                                    ? 'flag-title filled light-red'
                                                    : ''
                                              }`}
                                            >
                                              GCP Compliance & CDD
                                            </div>
                                          )}
                                          content={(
                                            <div className="flag-data">
                                              <div className="flag-data-title-create">
                                                <div className="flag-data-content-value">
                                                  <>
                                                    {itm?.cdd_data?.length
                                                      ? itm?.cdd_data?.map((item) => (
                                                        <>
                                                          <div
                                                            style={{ 'white-space': 'pre-line' }}
                                                          >
                                                            CDD Status : {item.status}
                                                          </div>
                                                          <br />
                                                          <div
                                                            style={{ 'white-space': 'pre-line' }}
                                                          >
                                                            CDD Date:{' '}
                                                            {item.date ? item.date : '-'}
                                                          </div>
                                                          <br />
                                                          <div
                                                            style={{ 'white-space': 'pre-line' }}
                                                          >
                                                            CDD Comment:{' '}
                                                            {item.comment ? item.comment : '-'}
                                                          </div>
                                                          <br />
                                                          <hr />
                                                        </>
                                                      ))
                                                      : undefined}
                                                    {itm?.compliances?.length
                                                      && itm?.compliances.map((itm1) => {
                                                        return (
                                                          <>
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Compliance Preventing Participation :{' '}
                                                              {itm1.no_trial_participation_flag
                                                              === 'N'
                                                                ? 'No'
                                                                : itm1.no_trial_participation_flag
                                                                  === 'Y'
                                                                  ? 'Yes'
                                                                  : itm1.no_trial_participation_flag
                                                                  === 'NO'
                                                                    ? 'No'
                                                                    : itm1.no_trial_participation_flag
                                                                  === 'YES'
                                                                      ? 'Yes'
                                                                      : itm1.no_trial_participation_flag}
                                                            </div>
                                                            <br />

                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Compliance Issue Comment:{' '}
                                                              {itm1.compliance_issue_comment
                                                                ? itm1.compliance_issue_comment
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Compliance Issue Description:{' '}
                                                              {itm1.compliance_issue_desc
                                                                ? itm1.compliance_issue_desc
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Compliance Issue Raised:{' '}
                                                              {itm1.compliance_issue_raised_dt
                                                              && itm1.compliance_issue_raised_dt.length
                                                                ? itm1.compliance_issue_raised_dt.split(
                                                                  ' ',
                                                                )[0]
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Reviewed Status:{' '}
                                                              {itm1.review_status
                                                                ? itm1.review_status
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Review Date:{' '}
                                                              {itm1.review_date
                                                              && itm1.review_date.length
                                                                ? itm1.review_date.split(' ')[0]
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Reviewer Name:{' '}
                                                              {itm1.reviewer_name
                                                                ? itm1.reviewer_name
                                                                : '-'}
                                                            </div>
                                                            <br />
                                                            <div
                                                              style={{ 'white-space': 'pre-line' }}
                                                            >
                                                              Re-evaluation Comment:{' '}
                                                              {itm1.reevaluation_comment
                                                                ? itm1.reevaluation_comment
                                                                : '-'}
                                                            </div>
                                                            <hr />
                                                          </>
                                                        );
                                                      })}
                                                  </>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          trigger="click"
                                        >
                                          <div className="flag-img" />
                                        </Popover>
                                      </div>
                                    ) : null}
                                    {itm.emerging_kol.length ? (
                                      <Tooltip
                                        title={(
                                          <div className="tooltip-title">
                                            <div>Emerging Category : {itm.emerging_data.flag === 'Medium' ? 'Moderate'
                                              : itm.emerging_data.flag === 'High' ? 'High'
                                                : itm.emerging_data.flag === 'Low' ? 'Low' : ''}
                                            </div>
                                            <div className="tooltip-theraprutic-area">
                                              Therapy Area : {itm.emerging_data.therapeutic_area}
                                            </div>
                                            <div className="tooltip-score">
                                              Score : {itm.emerging_data.score}
                                            </div>
                                          </div>
                                        )}
                                      >
                                        <div
                                          className={`${
                                            itm.emerging_data.flag === 'Medium'
                                              ? 'project-result Medium-flag'
                                              : itm.emerging_data.flag === 'Low'
                                                ? 'project-result Low-flag'
                                                : itm.emerging_data.flag === 'High'
                                                  ? 'project-result High-flag'
                                                  : ''
                                          }`}
                                        >
                                          <Popover
                                            overlayClassName="flag-section-content flag-section-content-project-result"
                                            placement="right"
                                            title={(
                                              <div
                                                className={`${
                                                  itm.emerging_data.flag === 'Medium'
                                                    ? 'project-result Medium-flag'
                                                    : itm.emerging_data.flag === 'Low'
                                                      ? 'project-result Low-flag'
                                                      : itm.emerging_data.flag === 'High'
                                                        ? 'project-result High-flag'
                                                        : ''
                                                }`}
                                              >
                                                TA wise Emerging KOL Score
                                              </div>
                                            )}
                                            content={(
                                              <>
                                                <div className="emerging-investigator-title">
                                                  <div className="emerging-investigator-ta">Primary TA : &nbsp;</div>
                                                  {Object.keys(itm.emerging_data).length ? (<div className="emerging-investigator-"> {itm.emerging_data.therapeutic_area}</div>) : ''}
                                                </div>
                                                <div className="emerging-investigator-right">
                                                  <div
                                                    className="emerging-investigator-header"
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent: 'space-around',
                                                    }}
                                                  >

                                                    <div className="emerging-investigator-header-th theraprutic-area">
                                                      {' '}
                                                      Therapy Area{' '}
                                                    </div>
                                                    <div className="emerging-investigator-header-th">
                                                      {' '}
                                                      Score{' '}
                                                    </div>
                                                    <div className="emerging-investigator-header-th">
                                                      {' '}
                                                      Emerging KOL Class{' '}
                                                    </div>
                                                  </div>
                                                  <div className="emerging-investigator-body">
                                                    {itm.emerging_kol?.length
                                                      ? itm.emerging_kol?.map((itm1) => (
                                                        <>
                                                          <div
                                                            className="emerging-investigator-header-tr"
                                                            style={{
                                                              display: 'flex',
                                                              justifyContent: 'space-around',
                                                            }}
                                                          >
                                                            <div
                                                              className="emerging-investigator-header-td therapeutic-area"
                                                              style={{ display: 'flex' }}
                                                            >
                                                              <div className="emerging-investigator-header-td ta-value">
                                                                {itm1.therapeutic_area}
                                                              </div>
                                                            </div>
                                                            <div className="emerging-investigator-header-td score">
                                                              {itm1.score}
                                                            </div>
                                                            <div className="emerging-investigator-header-td">
                                                              <div className="emerging-investigator-flag">
                                                                {itm1.flag === 'Medium' ? (
                                                                  <span
                                                                    className="Medium-flag"
                                                                    title="Moderate"
                                                                  />
                                                                ) : itm1.flag === 'Low' ? (
                                                                  <span
                                                                    className="Low-flag"
                                                                    title="Low"
                                                                  />
                                                                ) : itm1.flag === 'High' ? (
                                                                  <span
                                                                    className="High-flag"
                                                                    title="High"
                                                                  />
                                                                ) : (
                                                                  ''
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ))
                                                      : ''}
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                            trigger="click"
                                          >
                                            <div className="flag-img" />
                                          </Popover>
                                        </div>
                                      </Tooltip>
                                    ) : null}
                                    <div
                                      className={
                                        itm.alliancePresent
                                          ? 'primary-org-change-alliance'
                                          : 'primary-org-change'
                                      }
                                    >
                                      <Button
                                        onClick={() => setOrgChangeModalOpen({
                                          isOpen: true,
                                          piId: itm.pi_id,
                                          siteId: itm.site_id,
                                          isAlliance: itm.alliancePresent,
                                        })}
                                      />
                                    </div>
                                  </div>
                                  <div className="table-column">
                                    <div
                                      className="table-column-sec table-column-body"
                                      onScroll={onRowScroll}
                                    >
                                      {checkColumnDisplay('Feasibility Status') ? (
                                        <div className="filter-sel-data-value status">
                                          {getCflStatus(itm, 'cfl')}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Feasibility Status Date') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm.last_cfl_status_updated_on
                                            ? getFormattedDate(
                                              itm.last_cfl_status_updated_on * 1000,
                                            )
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('LCOR Status') ? (
                                        <div className="filter-sel-data-value status">
                                          {getCflStatus(itm, 'lcor')}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Sponsor Status') ? (
                                        <div className="filter-sel-data-value status">
                                          {getCflStatus(itm, 'sponsor')}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('LCOR Status Date') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm.last_lcor_status_updated_on
                                            ? getFormattedDate(
                                              itm.last_lcor_status_updated_on * 1000,
                                            )
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Analysis ID') ? (
                                        <div className="filter-sel-data-value analysis-id">
                                          {itm.analysis_seq_id ? itm.analysis_seq_id : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Priority') ? (
                                        <div className="filter-sel-data-value priority">
                                          <Select
                                            onChange={(e) => handlePriorityForSingle(
                                              itm.innoplexus_id,
                                              e,
                                                itm?.originating_party,
                                                itm.pi_id,
                                            )}
                                            // defaultvalue={getPriority(itm)}
                                            value={getPriority(itm)}
                                            options={priorityList}
                                            disabled={
                                              isCheckedboxEnabled
                                              || !(
                                                adminType.includes('lcor_access')
                                                || adminType.includes('cfl_access')
                                              )
                                              || isSelectAllChecked
                                            }
                                          />
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Origin') ? (
                                        <div className="filter-sel-data-value origin">
                                          {getOrigin(itm)}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Originating Party') ? (
                                        <div className="filter-sel-data-value party">
                                          <div className="filter-sel-data-value priority">
                                            <Select
                                              onChange={(e) => handleOriginForSingle(
                                                itm.innoplexus_id,
                                                e,
                                                itm.pi_id,
                                              )}
                                              value={getParty(itm)}
                                              options={originatingParty}
                                              disabled={
                                                isCheckedboxEnabled
                                                || !(
                                                  adminType.includes('lcor_access')
                                                  || adminType.includes('cfl_access')
                                                )
                                                || isSelectAllChecked
                                              }
                                              style={{ width: '120px' }}
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Version') ? (
                                        <div className="filter-sel-data-value priority">
                                          <Select
                                            onChange={(e) => handleVersionForSingle(
                                              itm.innoplexus_id,
                                              e,
                                              itm.pi_id,
                                            )}
                                            // defaultValue={itm.originating_party ? itm.originating_party : null}
                                            value={getVersion(itm)}
                                            options={versionList}
                                            disabled={
                                              isCheckedboxEnabled
                                              || !(
                                                adminType.includes('lcor_access')
                                                || adminType.includes('cfl_access')
                                              )
                                              || isSelectAllChecked
                                            }
                                          />
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Alliance Member Name') ? (
                                        <div className="filter-sel-data-value status">
                                          {itm.alliance_member_name
                                            ? itm.alliance_member_name
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Date Added') ? (
                                        <div className="filter-sel-data-value status">
                                          {itm.date_added
                                            ? getFormattedDate(itm.date_added * 1000)
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Alliance Contact') ? (
                                        <div className="filter-sel-data-value status">
                                          {itm?.alliance_contact?.full_name
                                            ? itm.alliance_contact?.full_name
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Parexel Contact') ? (
                                        <div className="filter-sel-data-value status">
                                          {itm.parexel_contact?.full_name
                                            ? itm.parexel_contact?.full_name
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('LCOR Comment') ? (
                                        <div className="filter-sel-data-value status">
                                          {itm.lcor_comment ? itm.lcor_comment : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('CDA Expiry') ? (
                                        <div className="filter-sel-data-value expiry">
                                          {itm.cda_expiry ? itm.cda_expiry : '-'}
                                        </div>
                                      ) : null}

                                      {checkColumnDisplay('Query ID') ? (
                                        <div className="filter-sel-data-value query-id">
                                          {itm.query_seq_id ? itm.query_seq_id : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('# Industry Studies') ? (
                                        <Button
                                          className="filter-sel-data-value industry-studies"
                                          onClick={(e) => onIndustryClick(
                                            e,
                                            itm.pi_id,
                                            itm.industry_studies_source,
                                            itm.analysis_id,
                                          )}
                                        >
                                          {itm.industry_studies}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('VAT Invoice Present') ? (
                                        <div className="filter-sel-data-value industry-studies">
                                          {itm.vat_invoice}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Competing Studies') ? (
                                        <Button
                                          className="filter-sel-data-value center"
                                          onClick={() => onCompetingClick(itm.pi_id, itm.analysis_id)}
                                        >
                                          {itm.competing_studies}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('# PXL Studies') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onPxlClick(itm.pi_id, itm.analysis_id)}
                                        >
                                          {itm.pxl_studies}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('Tier (Search Specific)') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onTierClick(itm)}
                                        >
                                          {itm.tier}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('Profile Tier(General)') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onProfileTierClick(itm)}
                                        >
                                          {itm.p_tier}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('Specialities') ? (
                                        <div className="filter-sel-data-value pxl-studies">
                                          {itm?.specialties?.length
                                            ? itm.specialties.join(', ')
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Enrolled Subjects') ? (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {itm.enrolled_count}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Quality') ? (
                                        <div className="filter-sel-data-value quality">
                                          <Rate count={3} disabled value={itm.quality_rating} />
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Reliability') ? (
                                        <div className="filter-sel-data-value enrolled-subjects">
                                          {itm.reliability_class}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Reliability Score') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onReliabilityClick(itm)}
                                        >
                                          {itm.reliability_score}
                                        </Button>
                                      ) : null}
                                      {checkColumnDisplay('KOL Score') ? (
                                        <Button
                                          className="filter-sel-data-value pxl-studies"
                                          onClick={() => onKolScoreClick(itm)}
                                        >
                                          {itm?.cls_kol_score?.toFixed(3)}
                                        </Button>
                                      // <div className="filter-sel-data-value center">
                                      //   {itm.cls_kol_score}
                                      // </div>
                                      ) : null}
                                      {checkColumnDisplay('Start Up Months') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm.p_start_up ? itm.p_start_up.toFixed(2) : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Start Up Index') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm.startup_index ? itm.startup_index.toFixed(2) : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Recruitment Factor') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm.p_recruitment ? itm.p_recruitment.toFixed(2) : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Last Trial Start Date') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm.recent_trial_start_date_ts
                                            ? getFormattedDate(
                                              itm.recent_trial_start_date_ts * 1000,
                                            )
                                            : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Center') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm.site_name}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('City') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm?.city !== '' ? itm?.city : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('State') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm?.state !== '' ? itm?.state : '-'}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Country') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm.country}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Alliance Type') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm.alliance_type}
                                        </div>
                                      ) : null}
                                      {checkColumnDisplay('Networks') ? (
                                        <div className="filter-sel-data-value center">
                                          {itm.networks && itm.networks.length
                                            ? itm.networks.join('; ')
                                            : '-'}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="lastside-column">
                                    {parse(location.search).compareFlag || IsComparisionClicked ? (
                                      <div className="compare-checkbox">
                                        <div className="checkbox-content">
                                          <Checkbox
                                            className={
                                              checkDefaultChecked(itm.pi_id) ? 'check' : 'uncheck'
                                            }
                                            key={idx}
                                            onChange={(e) => onCheckboxChange(e, itm.pi_id)}
                                            checked={checkDefaultChecked(itm.pi_id)}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <Dropdown overlay={() => dataMenu(itm)} trigger={['click']}>
                                        <Button
                                          className="accordion-menu"
                                          role="presentation"
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <Tooltip title="Kebab menu">
                                            <div className="accordion-menu-icon" />
                                          </Tooltip>
                                        </Button>
                                      </Dropdown>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </ReadMore>
                      </div>
                    </div>
                  </div>
                ))}
              </>
              ) : null}
          </div>
        );
      }
      default: {
        return <div>run analysis</div>;
      }
    }
  };

  console.log('isCommentNeeded.isNeeded--->', isCommentNeeded.isNeeded)
  const OnAddInvestigatorClick = () => {
    setSearchPi(true);
  };

  const createNewVersionClicked = () => {
    setVersionModalOpen(false);
    dispatch(createNewVersionAction({ project_id: parse(location.search).project_id }));
  };

  const scrollLeft = () => {
    const element = document.getElementById('scrollDiv');
    element.scrollLeft -= 100;
  };

  const scrollRight = () => {
    const element = document.getElementById('scrollDiv');
    element.scrollLeft += 100;
  };

  const isScrollBtnEnabled = () => {
    const newelement = document.getElementById('scrollDiv');
    if (newelement !== null) {
      if (newelement.clientWidth > 0) {
        if (newelement.scrollWidth > newelement.clientWidth) {
          if (!isScrollEnabled) {
            setIsScrollEnabled(true);
          }
        }
      }
    }
    return null;
  };

  const getCountDetails = () => {
    const orderedHierarchy = reOrderHierarchy(hierarchy);
    switch (orderedHierarchy) {
      case 'country,primary_organizations,pis':
      case 'primary_organizations,pis': {
        return (
          <div className="show-doc-data">
            Showing
            {` ${savedProjectResultsData.data.totalPiCount} Investigators`}
            {savedProjectResultsData.data.totalCenterCount > 0 ? `, ${savedProjectResultsData.data.totalCenterCount} Centers` : null}
          </div>
        );
      }
      case 'country,pis':
      case 'pis': {
        return (
          <div className="show-doc-data">
            Showing
            {` ${savedProjectResultsData.data.totalPiCount} Investigators`}
          </div>
        );
      }
      case 'country,parent_institutes,primary_organizations,pis':
      case 'parent_institutes,primary_organizations,pis': {
        return (
          <div className="show-doc-data">
            Showing
            {` ${savedProjectResultsData.data.totalPiCount} Investigators`}
            {savedProjectResultsData.data.totalCenterCount > 0 ? `, ${savedProjectResultsData.data.totalCenterCount} Centers` : null}
            {savedProjectResultsData.data.totalParentCount > 0 ? `, ${savedProjectResultsData.data.totalParentCount} Parent Institutes ` : null}
          </div>
        );
      }
      default: return undefined
    }
  }

  const handleSelectAllClick = () => {
    if (savedProjectResultsData.data.totalPiCount > SELECT_ALL_LIMIT) {
      setSelectAllLimitModal(true);
    } else if (cflStatusArray.length) {
      setSaveWarningModal({ isOpen: true, type: 'selectAll' });
    } else {
      setIsSelectAllChecked(!isSelectAllChecked);
    }
  };

  const onCountryChange = () => {
    setQuestionModal(true);
  };

  // const onPieChartValue = () => {
  //   // if (Object.keys(pieChartData).length) {
  //   //   const data1 = Object.keys(pieChartData)
  //   //     .filter((key) => key === tabName)
  //   //     .reduce((obj, key) => {
  //   //       obj[key] = pieChartData[key];
  //   //       return obj;
  //   //     }, {});
  //   //   return (
  //   //     <PieChart
  //   //       data={data1[tabName]}
  //   //       outerRadius={70}
  //   //       innerRadius={35}
  //   //       type="SITE"
  //   //       idName={`pi${itm.iid}`}
  //   //       flag={false}
  //   //     />
  //   //   );
  //   // }
  //   return '';
  // };

  return (
    <div
      className={`run-query-page ${
        mapFullScreen ? 'map-full-screen-view' : 'map-nofull-screen-view'
      }`}
    >
      {!mapFullScreen && (
        <div className="run-query-page-header">
          <div className="logo-backbtn-section ">
            <div className="landscape-logo">
              <div className="landscape-logo-img" />
            </div>
            <div className="back-button">
              <Button
                onClick={handleBackButton}
                tabIndex={0}
                role="button"
                onKeyDown={null}
                className="header-back-btn"
              >
                Back
              </Button>
            </div>
          </div>
          <div className="card-scroll-section">
            <div className="card-scroll-section-left">
              {isScrollEnabled && <Button onClick={scrollLeft} className="scroll-leftarrow" />}
            </div>
            <div className="header-card-scroll card-scroll-section-content" id="scrollDiv">
              {renderAnalysis()}
              {isScrollBtnEnabled()}
            </div>
            <div className="card-scroll-section-right">
              {isScrollEnabled && <Button onClick={scrollRight} className="scroll-rightarrow" />}
            </div>
          </div>
        </div>
      )}
      <div className="page-container run-query-page-container">
        {showAlertNotification ? (
          <div className="info-alert-box">
            <div className="info-alert-box-content">
              <div className="info-alert-box-text">
                For this project, LandSCAPE encountered an error in saving results. Please try the
                &quot;Refresh Project Results&quot; option to start the process of saving results
                again. Till then, LandSCAPE will show the previously saved results (if any).
              </div>
              <div
                className="alert-close-btn"
                role="presentation"
                onClick={() => setShowAlertNotification(false)}
              />
            </div>
          </div>
        ) : null}
        <div className="card-details">
          {/* {!mapFullScreen && ( */}
          <div className="card-details-header use-flex project-details-main">
            <div className="save-btn-section save-btn-section-head">
              <Button
                type="primary"
                onClick={() => onSaveClick()}
                disabled={
                  (cflStatusArray.length === 0 && !isSelectAllChecked)
                  || (actionSelectedForStatus === ''
                    && priorityForBulk === null
                    && versionForBulk === null
                    && originatingPartyForBulk === null
                    && isSelectAllChecked)
                }
              >
                Save
              </Button>
            </div>
            <div className="card-details-header-title">
              <span className="title-name">Project:</span>{' '}
              <span className="project-name" title={parse(location.search).project_name}>
                {parse(location.search).project_name}
              </span>
            </div>
            <div className="project-data-meta-section analysis-meta-section-header">
              {savedProjectResultsData.flag ? (
                <>
                  <Tooltip title="Country Questions">
                    <div className="select-count-dropdown">
                      <Button className="country-question-btn" onClick={() => onCountryChange()} disabled={isSponsor()} />
                      {/* <Select
                          className=""
                          style={{
                            width: 120,
                          }}
                          onChange={(val) => onCountryChange(val)}
                          value={selectedCountry}
                          // popupClassName="export-to-csv-btn-popover"
                        >

                          {
                                    country?.map((itm) => <Option key={itm}>{itm}</Option>)
                                  }

                        </Select> */}
                    </div>
                  </Tooltip>
                  <Tooltip title={`current version ${currentVersion}`}>
                    <div className="version-sec">
                      <div
                        className={`version-icon ${isSponsor() ? 'disabled' : null}`}
                        role="presentation"
                        onClick={() => {
                          if (!isSponsor()) {
                            setVersionModalOpen(true);
                          }
                        }}
                        title="View saved project version"
                      />
                    </div>
                  </Tooltip>
                  <Tooltip title="View saved project summary">
                    <div className="summary-sec">
                      <div
                        className="summary-icon"
                        role="presentation"
                        onClick={() => {
                          setSummaryModalOpen(true);
                        }}
                        title="View saved project summary"
                      />
                    </div>
                  </Tooltip>
                  <div className="toggle-switch-wrap toggle-switch-wrap-relative">
                    <Tooltip title="List View">
                      <div
                        className={`toggle-switch-itm ${!showRunAnalysisMap ? 'active' : ''}`}
                        role="presentation"
                        onClick={() => setShowRunAnalysisMap(false)}
                      >
                        List
                      </div>
                    </Tooltip>
                    <Tooltip title="Map View">
                      <div
                        className={`toggle-switch-itm ${showRunAnalysisMap ? 'active' : ''}`}
                        role="presentation"
                        onClick={() => {
                          setShowRunAnalysisMap(true);
                        }}
                      >
                        Map
                      </div>
                    </Tooltip>
                  </div>
                  <Tooltip title="Export">
                    <div className="export-to-csv-btn">
                      <Select
                        className="export-to-csv-btn-select"
                        style={{
                          width: 120,
                        }}
                        disabled={downloading || isSponsor()}
                        onChange={(val) => exportData(val)}
                        value={exportVal}
                        popupClassName="export-to-csv-btn-popover"
                      >
                        <Option value="filtered">Export filtered results</Option>
                        <Option value="complete">Export complete results</Option>
                        <Option value="qualtrics">Export Qualtrics results</Option>
                        <Option value="cat">Export CAT results</Option>
                        <Option value="sponsor">Export for Sponsor Review</Option>
                      </Select>
                    </div>
                  </Tooltip>
                </>
              ) : null}
              <div className="sortby-icon">
                {hierarchyOptions.filter(
                  (itm) => itm.value === reOrderHierarchy(hierarchy).split(',')[0],
                )[0].title === 'Country' ? (
                  <div className="sort-icon-img disabled" />
                  ) : (
                    <Popover
                      overlayClassName="sortby-runanalysis-popover"
                      placement="left"
                      title="Sort by:"
                      content={(
                        <SortBy
                          pageName="savedAnalysisResults"
                          onApplySort={applySort}
                          topHierarchyItm={
                          hierarchyOptions.filter(
                            (itm) => itm.value === reOrderHierarchy(hierarchy).split(',')[0],
                          )[0].title
                        }
                        />
                    )}
                      trigger="click"
                      open={isSortByPopoverVisible}
                      onOpenChange={(visibled) => {
                        setIsSortByPopoverVisible(visibled);
                      }}
                    >
                      <Tooltip title="Sort">
                        <div className="sort-icon-img" />
                      </Tooltip>
                    </Popover>
                  )}
              </div>
              <div className="filter-icon">
                <Popover
                  overlayClassName="filter-runanalysis-popover"
                  placement="left"
                  title="Filter by:"
                  content={(
                    <ProjectFilters
                      pageName="savedAnalysisResults"
                      analysisId={parse(location.search).project_id}
                      appliedFilters={appliedFilters}
                      onApplyFilters={applyFilters}
                      onReset={resetFilters}
                      enrolled_count_default={[
                        savedProjectResultsData.data.min_enrolled_count,
                        savedProjectResultsData.data.max_enrolled_count,
                      ]}
                      industry_studies_default={[
                        savedProjectResultsData.data.min_industry_studies,
                        savedProjectResultsData.data.max_industry_studies,
                      ]}
                      competing_studies_default={[
                        savedProjectResultsData.data.min_competing_studies,
                        savedProjectResultsData.data.max_competing_studies,
                      ]}
                      pxl_studies_default={[
                        savedProjectResultsData.data.min_PXL_studies,
                        savedProjectResultsData.data.max_PXL_studies,
                      ]}
                      start_up_factor_default={[
                        savedProjectResultsData.data.min_start_up_factor,
                        savedProjectResultsData.data.max_start_up_factor,
                      ]}
                      recruitment_factor_default={[
                        savedProjectResultsData.data.min_recruitment_factor,
                        savedProjectResultsData.data.max_recruitment_factor,
                      ]}
                      start_up_months_default={[
                        savedProjectResultsData.data.min_start_up_months,
                        savedProjectResultsData.data.max_start_up_months,
                      ]}
                    />
                  )}
                  trigger="click"
                  open={isFilterPopoverVisible}
                  onOpenChange={(visibled) => {
                    setIsFilterPopoverVisible(visibled);
                  }}
                >
                  <Tooltip title="Filters">
                    <div className="filter-icon-img" />
                    { appliedFiltersCount > 0 ? <div className="filters-count">{ appliedFiltersCount } </div> : null}
                  </Tooltip>
                </Popover>
              </div>
              {savedProjectResultsData.flag ? <div className="fav" /> : null}
              <div className="comments">
                <Comment
                  type="projects"
                  projectId={projectID}
                  countType="analysis"
                  count={(savedProjectResultsData.data && savedProjectResultsData.data.comments && JSON.stringify(savedProjectResultsData.data.comments.length)) || 0}
                />
              </div>
              <div className="fullscreen-sec">
                <Button
                  className={`fullscreen-sec-btn ${
                    mapFullScreen ? 'fullscreen-sec-btn-out' : 'fullscreen-sec-btn-in'
                  }`}
                  onClick={() => setMapFullScreen(!mapFullScreen)}
                />
              </div>
              <div className="show-hide-wrap">
                <Popover
                  overlayClassName="filter-show-hide-wrap"
                  content={(
                    <div>
                      <ColumnFilters
                        applyColumns={applyColumns}
                        displayColumns={displayColumns}
                        pageName="savedProjectResults"
                      />
                    </div>
                  )}
                  title="Show/Hide Columns"
                  trigger="click"
                  open={visible}
                  onOpenChange={handleVisibleChange}
                  placement="left"
                >
                  <div className="show-hide-col-sec">
                    <Button
                      className="show-hide-col-sec show-hide-col-sec-btn"
                    />
                  </div>
                </Popover>
              </div>
            </div>
          </div>
          {/* )} */}
          {!showRunAnalysisMap ? (
            <Loader
              loading={
                savedProjectResultsData.loading
                || updatePiStatusResponse.loading
                || downloading
                || createNewVersionResponse.loading
              }
              error={savedProjectResultsData.error}
            >
              <div className="project-data-wrap">
                <div className="project-data-wrap-content-header">
                  {!isCheckedboxEnabled && !isSelectAllChecked ? (
                    <div className="project-data-wrap-content-header-filter">
                      <div className="project-data-wrap-content-header-filter-select">
                        <div className="pi-status-dropdown-title">Feasibility Status</div>
                        <div className="pi-status-dropdown">
                          <Select
                            mode="multiple"
                            style={{ width: '145px' }}
                            value={cflStatusSelected}
                            onChange={(e) => handleStatusChange(e, 'cfl')}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <div className="fe-status-filter-btn-meta">
                                  <div className="searchbyfields-apply-btn">
                                    <Button onClick={handleApplyFieldFilterClicked} title="Apply" />
                                  </div>
                                  <div className="searchbyfields-clear-btn">
                                    <Button
                                      onClick={() => {
                                        setCflStatusSelected([]);
                                        handleClearFieldFilterClicked('Cfl');
                                      }}
                                      title="Clear"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          >
                            {statusList?.map((itm) => (
                              <Option key={itm.key}>{itm.value}</Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="project-data-wrap-content-header-filter-select">
                        <div className="pi-status-dropdown-title">LCOR Status</div>
                        <div className="pi-status-dropdown">
                          <Select
                            mode="multiple"
                            style={{ width: '145px' }}
                            value={lcorStatusSelected}
                            onChange={(e) => handleStatusChange(e, 'lcor')}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <div className="fe-status-filter-btn-meta">
                                  <div className="searchbyfields-apply-btn">
                                    <Button onClick={handleApplyFieldFilterClicked} title="Apply" />
                                  </div>
                                  <div className="searchbyfields-clear-btn">
                                    <Button
                                      onClick={() => {
                                        setLcorStatusSelected([]);
                                        handleClearFieldFilterClicked('Lcor');
                                      }}
                                      title="Clear"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          >
                            {lcorStatusList?.map((itm) => (
                              <Option key={itm.key}>{itm.value}</Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="project-data-wrap-content-header-filter-select">
                        <div className="pi-status-dropdown-title">Sponsor Status</div>
                        <div className="pi-status-dropdown">
                          <Select
                            mode="multiple"
                            style={{ width: '145px' }}
                            value={sponsorStatusSelected}
                            onChange={(e) => handleStatusChange(e, 'sponsor')}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <div className="fe-status-filter-btn-meta">
                                  <div className="searchbyfields-apply-btn">
                                    <Button onClick={handleApplyFieldFilterClicked} title="Apply" />
                                  </div>
                                  <div className="searchbyfields-clear-btn">
                                    <Button
                                      onClick={() => {
                                        setSponsorStatusSelected([]);
                                        handleClearFieldFilterClicked('sponsor');
                                      }}
                                      title="Clear"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          >
                            {sponsorStatusList?.map((itm) => (
                              <Option key={itm.key}>{itm.value}</Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="middle-section">
                        <div className="search-bar-inner">
                          <div className="search-bar-input-sec">
                            <input
                              className="search-bar-input"
                              type="text"
                              placeholder="Search PI"
                              value={searchPIInput}
                              onChange={onSearchPIInputChange}
                            />
                            {(displayingSearchCenterResults || displayingSearchPIResults)
                            && searchPIInput.length ? (
                              <span
                                className="close-icon-btn"
                                role="presentation"
                                onClick={() => onClearSearchPIInput()}
                              >
                                <span className="close-icon" />
                              </span>
                              ) : null}
                            <span
                              className={`search-bar-icon-btn ${!searchPIInput ? 'disabled' : ''}`}
                              role="presentation"
                              onClick={() => onPISearch()}
                            >
                              <span className="search-bar-icon" />
                            </span>
                          </div>
                        </div>
                        <div className="search-bar-inner">
                          <div className="search-bar-input-sec">
                            <input
                              className="search-bar-input"
                              type="text"
                              placeholder="Search Center"
                              value={searchCenterInput}
                              onChange={onSearchCenterInputChange}
                            />
                            {(displayingSearchCenterResults || displayingSearchPIResults)
                            && searchCenterInput.length ? (
                              <span
                                className="close-icon-btn"
                                role="presentation"
                                onClick={() => onClearSearchCenterInput()}
                              >
                                <span className="close-icon" />
                              </span>
                              ) : null}
                            <span
                              className={`search-bar-icon-btn ${
                                !searchCenterInput ? 'disabled' : ''
                              }`}
                              role="presentation"
                              onClick={() => onCenterSearch()}
                            >
                              <span className="search-bar-icon" />
                            </span>
                          </div>
                        </div>
                        <div className="hierarchy-multiselect-wrap">
                          <div className="hierarchy-multiselect-text">
                            Hierarchy:&nbsp;
                          </div>
                          <div className="hierarchy-multiselect">
                            <Select
                              popupClassName="hierarchy-multiselect-dropdowm"
                              mode="multiple"
                              value={hierarchy.split(',')}
                              maxTagCount={1}
                              style={{ width: 160 }}
                              onChange={handleHierarchyChange}
                            >
                              {hierarchyOptions.map((hierarchyLevel, index) => (
                                <Option
                                  value={hierarchyLevel.value}
                                  key={index}
                                  disabled={
                                    index === 3
                                    || (index === 1
                                      && hierarchy.split(',').indexOf('primary_organizations') === -1)
                                  }
                                >
                                  {hierarchyLevel.title}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        {savedProjectResultsData.flag && savedProjectResults.length ? (
                          <div className="show-doc-outof show-doc-outof-w-relative">
                            {getCountDetails()}
                          </div>
                        ) : null}
                      </div>
                      {/* <div className="last-section">

                      </div> */}
                    </div>
                  ) : (
                    <div className="project-action-items-main">
                      {savedProjectResultsData.flag && savedProjectResults.length ? (
                        <div className="show-doc-outof show-doc-outof-w-relative">
                          <div className="show-doc-data">
                            Selected
                            <span className="show-count-number">
                              {isSelectAllChecked
                                ? savedProjectResultsData.data.totalPiCount
                                : selectedStatusList?.length}
                            </span>{' '}
                            out of{' '}
                            <span className="show-count-number">
                              {savedProjectResultsData.data.totalPiCount}
                            </span>{' '}
                            investigators
                          </div>
                        </div>
                      ) : null}
                      <div className="project-action-items-main-data">
                        <div className="project-action-items-main-data-title">Actions:</div>
                        <div className="project-action-items-main-data-content">
                          {getActionItems()}
                          <div className="project-action-item-priority">
                            <div className="project-action-items-main-data-title">
                              Priority Changed to:
                            </div>
                            <div className="project-action-item-priority-select">
                              <Select
                                width={{ width: '50px' }}
                                options={priorityList}
                                onChange={(e) => {
                                  setWhatIsChanged({ ...whatIsChanged, priority: 1 });
                                  setPriorityForBulk(e);
                                }}
                                disabled={
                                  !(
                                    adminType.includes('lcor_access')
                                    || adminType.includes('cfl_access')
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="project-action-item-priority">
                            <div className="project-action-items-main-data-title">
                              Originating party:
                            </div>
                            <div className="project-action-item-priority-select">
                              <Select
                                style={{ width: '120px' }}
                                options={originatingParty}
                                onChange={(e) => {
                                  setWhatIsChanged({ ...whatIsChanged, party: 1 });
                                  setOriginatingPartyForBulk(e);
                                }}
                                disabled={
                                  !(
                                    adminType.includes('lcor_access')
                                    || adminType.includes('cfl_access')
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="project-action-item-priority">
                            <div className="project-action-items-main-data-title">Version:</div>
                            <div className="project-action-item-priority-select">
                              <Select
                                style={{ width: '70px' }}
                                options={versionList}
                                onChange={(e) => {
                                  setWhatIsChanged({ ...whatIsChanged, version: 1 });
                                  setVersionForBulk(e);
                                }}
                                disabled={
                                  !(
                                    adminType.includes('lcor_access')
                                    || adminType.includes('cfl_access')
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="comments">
                            <Comment
                              projectId={parse(location.search).project_id}
                              piIdList={piIdforMail}
                              isCommentNeeded={isCommentNeeded}
                              setIsCommentNeeded={setIsCommentNeeded}
                              showPopover={isCommentNeeded.isNeeded}
                              type="pi"
                              from="allPi"
                              sitePIExtraType="specific"
                              is_lcor={adminType.includes('lcor_access')}
                            />
                          </div>
                          <div className="project-action-items-submit-btn">
                            <Button
                              type="primary"
                              disabled={
                                (actionSelectedForStatus === ''
                                  && (priorityForBulk === null || priorityForBulk === undefined)
                                  && originatingPartyForBulk === null
                                  && versionForBulk === null)
                                || (isCommentNeeded.isNeeded && !isCommentNeeded.isProvied)
                                || isSelectAllChecked
                              }
                              onClick={() => handleStatusUpdateClick()}
                            >
                              Done
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="project-data-wrap-content-header-filter-value">
                    <div className="project select-all-checkbox">
                      <Checkbox
                        checked={isSelectAllChecked}
                        onClick={() => handleSelectAllClick()}
                        disabled={checkedPiList.length > 0 || resultsTotal === 0}
                      />{' '}
                      <span className="checkbox-title">Select All</span>
                    </div>
                    <div className="header-analysis-section">
                      <div className="leftside-column" />
                      <div className="table-column">
                        <div
                          id="table-column-header"
                          className="table-column-sec table-column-header"
                          onScroll={onHeaderScroll}
                        >
                          {checkColumnDisplay('Feasibility Status') ? (
                            <div className="filter-sel-data-value status">Feasibility Status</div>
                          ) : null}
                          {checkColumnDisplay('Feasibility Status Date') ? (
                            <div className="filter-sel-data-value center">
                              {' '}
                              Feasibility Status Date
                            </div>
                          ) : null}
                          {checkColumnDisplay('LCOR Status') ? (
                            <div className="filter-sel-data-value status">LCOR Status</div>
                          ) : null}
                          {checkColumnDisplay('Sponsor Status') ? (
                            <div className="filter-sel-data-value status">Sponsor Status</div>
                          ) : null}
                          {checkColumnDisplay('LCOR Status Date') ? (
                            <div className="filter-sel-data-value center">LCOR Status Date</div>
                          ) : null}
                          {checkColumnDisplay('Analysis ID') ? (
                            <div className="filter-sel-data-value analysis-id">Analysis ID</div>
                          ) : null}
                          {checkColumnDisplay('Priority') ? (
                            <div className="filter-sel-data-value priority">Priority</div>
                          ) : null}
                          {checkColumnDisplay('Origin') ? (
                            <div className="filter-sel-data-value origin">Origin</div>
                          ) : null}
                          {checkColumnDisplay('Originating Party') ? (
                            <div className="filter-sel-data-value party">Originating Party</div>
                          ) : null}
                          {checkColumnDisplay('Version') ? (
                            <div className="filter-sel-data-value priority">Version</div>
                          ) : null}
                          {checkColumnDisplay('Alliance Member Name') ? (
                            <div className="filter-sel-data-value party">Alliance Member Name</div>
                          ) : null}
                          {checkColumnDisplay('Date Added') ? (
                            <div className="filter-sel-data-value party">Date Added</div>
                          ) : null}
                          {checkColumnDisplay('Alliance Contact') ? (
                            <div className="filter-sel-data-value party">Alliance Contact</div>
                          ) : null}
                          {checkColumnDisplay('Parexel Contact') ? (
                            <div className="filter-sel-data-value party">Parexel Contact</div>
                          ) : null}
                          {checkColumnDisplay('LCOR Comment') ? (
                            <div className="filter-sel-data-value status">LCOR Comment</div>
                          ) : null}
                          {checkColumnDisplay('CDA Expiry') ? (
                            <div className="filter-sel-data-value expiry">CDA Expiry</div>
                          ) : null}
                          {checkColumnDisplay('Query ID') ? (
                            <div className="filter-sel-data-value query-id">Query ID</div>
                          ) : null}
                          {checkColumnDisplay('# Industry Studies') ? (
                            <div className="filter-sel-data-value industry-studies">
                              # Industry Studies
                            </div>
                          ) : null}
                          {checkColumnDisplay('VAT Invoice Present') ? (
                            <div className="filter-sel-data-value industry-studies">
                              VAT Invoice Present
                            </div>
                          ) : null}
                          {checkColumnDisplay('Competing Studies') ? (
                            <div className="filter-sel-data-value center">Competing Studies</div>
                          ) : null}
                          {checkColumnDisplay('# PXL Studies') ? (
                            <div className="filter-sel-data-value pxl-studies"># PXL Studies</div>
                          ) : null}
                          {checkColumnDisplay('Tier (Search Specific)') ? (
                            <div className="filter-sel-data-value reliability">
                              Tier (Search Specific)
                            </div>
                          ) : null}
                          {checkColumnDisplay('Profile Tier(General)') ? (
                            <div className="filter-sel-data-value reliability">
                              Profile Tier(General)
                            </div>
                          ) : null}
                          {checkColumnDisplay('Specialities') ? (
                            <div className="filter-sel-data-value reliability">Specialities</div>
                          ) : null}
                          {checkColumnDisplay('Enrolled Subjects') ? (
                            <div className="filter-sel-data-value enrolled-subjects">
                              Enrolled Subjects
                            </div>
                          ) : null}
                          {checkColumnDisplay('Quality') ? (
                            <div className="filter-sel-data-value quality">Quality</div>
                          ) : null}
                          {checkColumnDisplay('Reliability') ? (
                            <div className="filter-sel-data-value reliability">Reliability</div>
                          ) : null}
                          {checkColumnDisplay('Reliability Score') ? (
                            <div className="filter-sel-data-value reliability">
                              Reliability Score
                            </div>
                          ) : null}
                          {checkColumnDisplay('KOL Score') ? (
                            <div className="filter-sel-data-value center">KOL Score</div>
                          ) : null}
                          {checkColumnDisplay('Start Up Months') ? (
                            <div className="filter-sel-data-value center">Start Up Months</div>
                          ) : null}
                          {checkColumnDisplay('Start Up Index') ? (
                            <div className="filter-sel-data-value center">Start Up Index</div>
                          ) : null}
                          {checkColumnDisplay('Recruitment Factor') ? (
                            <div className="filter-sel-data-value center">Recruitment Factor</div>
                          ) : null}
                          {checkColumnDisplay('Last Trial Start Date') ? (
                            <div className="filter-sel-data-value center">
                              Last Trial Start Date
                            </div>
                          ) : null}
                          {checkColumnDisplay('Center') ? (
                            <div className="filter-sel-data-value center">Center</div>
                          ) : null}
                          {checkColumnDisplay('City') ? (
                            <div className="filter-sel-data-value center">City</div>
                          ) : null}
                          {checkColumnDisplay('State') ? (
                            <div className="filter-sel-data-value center">State</div>
                          ) : null}
                          {checkColumnDisplay('Country') ? (
                            <div className="filter-sel-data-value center">Country</div>
                          ) : null}
                          {checkColumnDisplay('Alliance Type') ? (
                            <div className="filter-sel-data-value center">Alliance Type</div>
                          ) : null}
                          {checkColumnDisplay('Networks') ? (
                            <div className="filter-sel-data-value center">Networks</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="lastside-column" />
                    </div>
                  </div>
                </div>
                <LazyLoadDiv
                  className="card-list scrollbar"
                  id="centers-ra-list"
                  total={resultsTotal}
                  currentTotal={(savedProjectResults || []).length}
                  loadMoreRows={() => loadMoreRows()}
                  height="calc(100vh - 491px)"
                >
                  <div className="slide-scroll-section">
                    <Button
                      className="slide-scroll-section-left"
                      onClick={() => onTableScrollLeft()}
                    />
                  </div>
                  <div className="slide-scroll-section">
                    <Button
                      className="slide-scroll-section-right"
                      onClick={() => onTableScrollRight()}
                    />
                  </div>
                  <div className="project-data-section">
                    {savedProjectResultsData.flag
                      && pages.length
                      && pages[currentPageNumber].map((record) => {
                        return <>{renderProjectRecords(record)}</>;
                      })}
                    {savedProjectResultsData.flag && savedProjectResults.length === 0 ? (
                      <div className="no-data-found-msg no-data-project-view">
                        No relevant data found
                      </div>
                    ) : null}
                  </div>
                </LazyLoadDiv>
                {(parse(location.search).compareFlag
                  || parse(location.search).compareSiteFlag
                  || IsComparisionClicked
                  || IsSiteComparisionClicked) && (
                  <div className="compare-card-footer">
                    <Button
                      disabled={!compareList.length}
                      className="compare-card-btn"
                      onClick={ResetCompare}
                    >
                      Reset
                    </Button>
                    <Button
                      disabled={checkCompareEnable()}
                      className="compare-card-btn primary-btn"
                      onClick={
                        IsSiteComparisionClicked || parse(location.search).compareSiteFlag
                          ? handleOnSiteCompareClick
                          : handleOnCompareClick
                      }
                    >
                      Compare
                    </Button>
                  </div>
                )}
              </div>
            </Loader>
          ) : (
            <div className="project-data-wrap">
              <MapViewSavedProjectResults
                data={
                  savedProjectResultsData.data
                  && savedProjectResultsData?.data?.data
                  && savedProjectResultsData?.data?.data.length
                  && savedProjectResultsData?.data?.data[0].map
                }
                project_id={projectID}
                mapFullScreen={mapFullScreen}
                setMapFullScreen={setMapFullScreen}
                projectFilters={appliedFilters}
              />
            </div>
          )}
          <Loader
            loading={
              savedProjectResultsData.loading
              || updatePiStatusResponse.loading
              || downloading
              || createNewVersionResponse.loading
            }
            error={savedProjectResultsData.error}
          >
            {displayAddBtnsOverlay ? (
              <div className="footer-button-wrapper footer-button-wrapper-pd save-analysis add-overlay-to-me">
                <div className="plus-add-btn plus-add-btn-content">
                  <button
                    onClick={() => OnAddInvestigatorClick()}
                    type="button"
                    className="plus-btn"
                  >
                    <span className="title">Add Investigator&nbsp;</span>
                  </button>
                  <button
                    onClick={() => {
                      setDisplaySponsorsModal(true);
                    }}
                    type="button"
                    className="plus-btn"
                  >
                    <span className="title">Upload sponsors list&nbsp;</span>
                  </button>
                </div>
                {!showRunAnalysisMap && (
                  <div className="plus-add-btn close-add-btn">
                    <button
                      onClick={() => setDisplayAddBtnsOverlay(false)}
                      type="button"
                      className="plus-btn"
                    >
                      <span className="title-plus cross-btn">X</span>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              !showRunAnalysisMap && (
                <div className="footer-button-wrapper footer-button-wrapper-pd save-analysis add-overlay-to-me add-no-overlay-to-me">
                  <div className="plus-add-btn">
                    <button
                      onClick={() => setDisplayAddBtnsOverlay(true)}
                      type="button"
                      className="plus-btn"
                      disabled={isSponsor()}
                    >
                      <Tooltip title="Add investigator / upload sponsor's list of investigator">
                        <span className="title-plus">+</span>
                      </Tooltip>
                    </button>
                  </div>
                </div>
              )
            )}
            {/* <Pagination showQuickJumper pageSize={500} defaultCurrent={1} total={totalPiCount} onChange={(page, size) => handlePaginationChange(page, size)} /> */}
            {!showRunAnalysisMap && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: 0,
                  boxSizing: 'border-box',
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  bottom: '10px',
                }}
              >
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePaginationChange}
                  pageRangeDisplayed={5}
                  pageCount={totalPageCount}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                />
              </div>
            )}
            {searchPi && (
              <div className="investigator-wrap">
                <SearchBar
                  handleModal={{
                    flagName: 'savedAnalysis',
                    analysisId: parse(location.search).project_id,
                  }}
                  searchPi={searchPi}
                  setsearchPi={setSearchPi}
                  fieldToSearch="investigators"
                  setValueToCheck={setValueToCheck}
                  valueToCheck={valueToCheck}
                />
              </div>
            )}
          </Loader>
          {piHasComplianceIssue.isOpen && (
            <Modal
              title="Compliance issue"
              visible={piHasComplianceIssue}
              okText="Yes"
              className="del-modal-wrapper conf-modal-wrapper"
              onCancel={() => {
                setPiHasComplianceIssue({
                  isOpen: false,
                  id: '',
                  piId: '',
                  status: '',
                });
              }}
              onOk={() => {
                handlePiCheckbox(
                  piHasComplianceIssue.id,
                  piHasComplianceIssue.status,
                  piHasComplianceIssue.piId,
                  piHasComplianceIssue.originating_party,
                  'N',
                );
              }}
              okType="primary"
              destroyOnClose
            >
              <div className="modal-content">
                <div className="conf-desc">
                  This investigator has a compliance issue preventing study participation.
                </div>
                <div className="process-text">
                  Do you still want to change the status for this investigator?
                </div>
              </div>
            </Modal>
          )}
          {onItmClicked && (
            <div>
              <StudyModal
                onItmClicked={onItmClicked}
                setOnItmClicked={setOnItmClicked}
                clickedPId={clickedPId}
                flag="competing"
                analysisId={selectedAnalysisId}
              />
            </div>
          )}
          {onIndustryClicked && (
            <div>
              <StudyModal
                onItmClicked={onIndustryClicked}
                setOnItmClicked={setOnIndustryClicked}
                clickedPId={clickedIId}
                flag="industry"
                industryStudiesSource={industryStudiesSource}
                analysisId={selectedAnalysisId}
              />
            </div>
          )}
          {onPxlClicked && (
            <div>
              <StudyModal
                onItmClicked={onPxlClicked}
                setOnItmClicked={setOnPxlClicked}
                clickedPId={clickedPxId}
                flag="pxl"
                analysisId={selectedAnalysisId}
              />
            </div>
          )}
          {onTierClicked && (
            <div>
              <ScoreModal
                onItmClicked={onTierClicked}
                setOnItmClicked={setOnTierClicked}
                clickedPId={clickedTierId}
                flag="tier"
              />
            </div>
          )}
          {onProfleTierClicked && (
            <div>
              <ScoreModal
                onItmClicked={onProfleTierClicked}
                setOnItmClicked={setOnProfileTierClicked}
                clickedPId={clickedProfileTierId}
                flag="profile-tier"
              />
            </div>
          )}
          {onReliabilityClicked && (
            <div>
              <ScoreModal
                onItmClicked={onReliabilityClicked}
                setOnItmClicked={setOnReliabilityClicked}
                clickedPId={clickedReliabilityId}
                flag="Reliability"
              />
            </div>
          )}
          {orgChangeModalOpen.isOpen && (
            <div>
              <OrgChangeModal
                orgChangeModalData={orgChangeModalOpen}
                setOrgChangeModal={setOrgChangeModalOpen}
              />
            </div>
          )}
          {displaySponsorsModal ? (
            <UploadSponsorsList
              project_id={parse(location.search).project_id}
              displayModal={displaySponsorsModal}
              onModalClose={() => setDisplaySponsorsModal(false)}
            />
          ) : null}
          {saveWarningModal.isOpen && (
            <>
              <Modal
                visible={saveWarningModal.isOpen}
                title="Unsaved changes"
                onCancel={() => setSaveWarningModal({ isOpen: false, type: '' })}
                footer={null}
                className="unsaved-changes-modal"
              >
                <div className="unsave-changes-modal-main">
                  <div className="unsave-changes-modal-text">
                    You have unsaved changes. What would you like to do?
                  </div>
                  <div className="footer-modal-content">
                    <Button
                      className="unsave-changes-modal-discard-btn"
                      type="primary"
                      onClick={() => handleDiscardBtn()}
                    >
                      Discard changes
                    </Button>
                    <Button
                      type="primary"
                      className="save-changes-modal-discard-btn"
                      onClick={() => onSaveClick()}
                    >
                      Save changes
                    </Button>
                  </div>
                </div>
              </Modal>
            </>
          )}
          {summaryModalOpen && (
            <Modal
              className="project-summary-modal"
              visible={summaryModalOpen}
              onOk={() => setSummaryModalOpen(false)}
              onCancel={() => setSummaryModalOpen(false)}
            >
              <ProjectSummaryView
                data={{ project_id: parse(location.search).project_id, view: true }}
                closeModal={setSummaryModalOpen}
              />
            </Modal>
          )}
          {versionModalOpen && (
            <Modal
              style={{ marginTop: '60px', width: '100%' }}
              title="Project Version"
              visible={versionModalOpen}
              onOk={() => setVersionModalOpen(false)}
              onCancel={() => setVersionModalOpen(false)}
              className="del-modal-wrapper conf-modal-wrapper"
              maskClosable={false}
              destroyOnClose
              footer={null}
            >
              <div className="modal-content">
                <div className="process-text">
                  Current version for the project is {currentVersion}. Do you want to create new
                  version -{' '}
                  {currentVersion.split('v').length
                    ? `v${+currentVersion.split('v')[1] + 1}`
                    : 'new version'}
                  ?
                </div>
                <div className="process-text">
                  New investigators added to the project will be assigned the new version
                  automatically.
                </div>
              </div>
              <div className="footer-btn" style={{ borderTop: '0px' }}>
                <Button className="delete-button" onClick={() => setVersionModalOpen(false)}>
                  {' '}
                  No
                </Button>
                <Button
                  className="delete-button"
                  type="primary"
                  onClick={() => createNewVersionClicked()}
                >
                  Create
                </Button>
              </div>
            </Modal>
          )}

          {selectAllLimitModal && (
            <Modal
              style={{ marginTop: '60px', width: '100%' }}
              title="Selection Limit Exceeded"
              visible={selectAllLimitModal}
              onOk={() => setSelectAllLimitModal(false)}
              onCancel={() => setSelectAllLimitModal(false)}
              className="del-modal-wrapper conf-modal-wrapper"
              maskClosable={false}
              destroyOnClose
              footer={null}
            >
              <div className="modal-content">
                <div className="process-text">
                  Bulk change is allowed for upto {SELECT_ALL_LIMIT} investigators. Please use
                  filters to reduce the number of investigators and try again.
                </div>
              </div>
              <div className="footer-btn" style={{ borderTop: '0px' }}>
                <Button
                  className="delete-button"
                  type="primary"
                  onClick={() => setSelectAllLimitModal(false)}
                >
                  {' '}
                  Ok
                </Button>
              </div>
            </Modal>
          )}
          {questionModal && (
            <CountryQuestionModal
              questionModal={questionModal}
              setQuestionModal={setQuestionModal}
              selectedCountry={selectedCountry}
              projectID={projectID}
              setViewQuestionScreen={setViewQuestionScreen}
              viewQuestionScreen={viewQuestionScreen}
              setSelectedCountry={setSelectedCountry}
              flag="project"
            />
          )}

          {openKOLModal && (
          <Modal
            className="kol-content-modal"
            title={(
              <div>
                {/* <div> check itm {kolData} </div> */}
                <div className="kol-head-title">{kolData.pi_name}</div>
                <div className="kol-head-subtitle-heading">
                  <div className="kol-head-subtitle">
                    {kolData?.primary_organization?.name ? kolData?.primary_organization?.name : ''}
                  </div>
                  <div className="kol-head-overall-score">
                    Overall Score:&nbsp;
                    {kolData.kol_score.length
                      ? kolData?.kol_score.map(
                        (itm2) => itm2.therapeutic_area === tabName && (
                        <div className="kol-head-overall-value">
                          {' '}
                          {itm2.overall_score.toFixed(3)}
                        </div>
                        ),
                      )
                      : '-'}
                  </div>
                </div>
              </div>
                                  )}
            visible={openKOLModal}
            onCancel={() => setKOLModal(false)}
            destroyOnClose
            footer={null}
            style={{ minHeight: '500px' }}
          >
            {kolData?.kol_score.length ? (
              <>
                <div className="tab-header">
                  {kolData?.kol_score.length ? (
                    kolData?.kol_score.map((itm1) => {
                      return (
                        <Button
                          onClick={() => setTabName(itm1.therapeutic_area)}
                          className={
                                                    itm1.therapeutic_area === tabName
                                                      ? 'active'
                                                      : ''
                                                  }
                        >
                          {itm1.therapeutic_area}
                        </Button>
                      );
                    })
                   ) : ''}
                </div>
                <div className="kol-content">
                  <div className="kol-content-left">
                    <div className="kol-content-chart">
                      {onPieChartValue()}
                    </div>
                  </div>
                  <div className="kol-content-right">
                    <div className="kol-content-header">
                      <div className="kol-content-header-th"> Resource </div>
                      <div className="kol-content-header-th"> Count </div>
                      <div className="kol-content-header-th"> Score </div>
                    </div>
                    <div className="kol-content-body">
                      {kolData.kol_score.length
                        ? kolData.kol_score.map(
                          (itm1) => itm1.therapeutic_area === tabName && (
                          <>
                            <div className="kol-content-header-tr">
                              <div className="kol-content-header-td">
                                <div>Publications</div>
                              </div>
                              <div className="kol-content-header-td">
                                <div>{itm1.pub_count}</div>
                              </div>
                              <div className="kol-content-header-td">
                                <div>{itm1.pub_score.toFixed(3)}</div>
                              </div>
                            </div>
                            <div className="kol-content-header-tr">
                              <div className="kol-content-header-td">
                                <div>Clinical Trials</div>
                              </div>
                              <div className="kol-content-header-td">
                                <div>{itm1.ct_count}</div>
                              </div>
                              <div className="kol-content-header-td">
                                <div>{itm1.ct_score.toFixed(3)}</div>
                              </div>
                            </div>
                            <div className="kol-content-header-tr">
                              <div className="kol-content-header-td">
                                <div>Congress</div>
                              </div>
                              <div className="kol-content-header-td">
                                <div>{itm1.cong_count}</div>
                              </div>
                              <div className="kol-content-header-td">
                                <div>{itm1.cong_score.toFixed(3)}</div>
                              </div>
                            </div>
                            <div className="kol-content-header-tr">
                              <div className="kol-content-header-td">
                                <div>Grants</div>
                              </div>
                              <div className="kol-content-header-td">
                                <div>{itm1.gl_count}</div>
                              </div>
                              <div className="kol-content-header-td">
                                <div>{itm1.gl_score.toFixed(3)}</div>
                              </div>
                            </div>
                            <div className="kol-content-header-tr">
                              <div className="kol-content-header-td">
                                <div>Patents</div>
                              </div>
                              <div className="kol-content-header-td">
                                <div>{itm1.pat_count}</div>
                              </div>
                              <div className="kol-content-header-td">
                                <div>{itm1.pat_score.toFixed(3)}</div>
                              </div>
                            </div>
                          </>
                          ),
                        )
                        : ''}
                    </div>
                  </div>
                </div>
              </>
                                  ) : (
                                    <div className="no-data-msg"> No data available</div>
                                  )}
          </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default SavedProjectResults;
